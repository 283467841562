import { UiSpreadSheetCell } from '@aksia/infrastructure';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getByRowCol',
  standalone: true,
})
export class GetByRowColPipe implements PipeTransform {
  transform(
    data: Array<UiSpreadSheetCell>,
    col: number,
    row: number
  ): UiSpreadSheetCell | undefined {
    return data?.find(
      (item) => item.columnIndex === col && item.rowIndex === row
    );
  }
}
