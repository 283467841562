import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  signal,
  ViewChild,
  WritableSignal,
} from '@angular/core';
import {
  ISchemaControl,
  Location,
  IUiPopupSettings,
  LocationSettings,
  ILocationSettings,
  UTILS,
  IUiLocationTag,
  PropExtend,
} from '@aksia/infrastructure';
import { PropsJoinPipe } from '../../../pipes/props-join.pipe';
import { CommonModule } from '@angular/common';
import { LabelComponent } from '../../presentation/label/label.component';
import { IconComponent } from '../../presentation/icon/icon.component';
import { UiPopupDirective } from '../../../directives/ui/ui-popup.directive';
import { GoogleMapsModule } from '@angular/google-maps';
import { LocationService } from './location.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UiInputDirective } from '../../../directives/ui/ui-input.directive';

const defaultFieldMap = [
  {
    field: 'address1',
    tags: [
      { tag: 'street_number', writeMode: 'prepend' },
      { tag: 'route', writeMode: 'append' },
      { tag: 'premise', writeMode: 'append' },
    ],
  },
  {
    field: 'zip',
    tags: [{ tag: 'postal_code', writeMode: 'replace' }],
  },
  {
    field: 'city',
    tags: [
      { tag: 'locality', writeMode: 'replace' },
      { tag: 'postal_town', writeMode: 'replace' },
    ],
  },
  {
    field: 'state',
    tags: [{ tag: 'administrative_area_level_1', writeMode: 'replace' }],
  },
  {
    field: 'country',
    tags: [{ tag: 'country', writeMode: 'replace' }],
  },
];

@Component({
  selector: 'location',
  imports: [
    CommonModule,
    GoogleMapsModule,
    LabelComponent,
    IconComponent,
    UiPopupDirective,
  ],
  templateUrl: './location.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationComponent
  extends UiInputDirective
  implements AfterViewInit
{
  //#region Injections

  private locationService = inject(LocationService);

  //#region Inputs/Outputs

  override settings = input<LocationSettings, ILocationSettings>(
    new LocationSettings(),
    {
      transform: (settings: ILocationSettings) => this.initSettings(settings),
    },
  );

  //#endregion

  //#region View Children

  @ViewChild('uiPopup', { read: UiPopupDirective })
  uiPopupDir!: UiPopupDirective;

  //#endregion

  //#region Properties

  protected popupSettings: WritableSignal<IUiPopupSettings> = signal(
    this.settings().popup!,
  );
  protected override formattedValue = computed(() =>
    UTILS.OBJECT.isNil(this.value())
      ? ''
      : this.propsJoinPipe.transform(
          this.value() as Location,
          this.settings().valueFormat?.split(',')!,
        ),
  );
  protected valueFormat: WritableSignal<string> = signal(
    this.settings().valueFormat!,
  );

  protected override labelUp = computed<true | undefined>(() => {
    if (this.isFocused()) {
      return true;
    } else {
      let currentLocation = this.value() as PropExtend<Location>;
      if (!currentLocation) return undefined;
      let fieldHasValue = this.valueFormat()
        ?.split(',')
        ?.some((field) => !UTILS.OBJECT.isNil(currentLocation[field]));
      return fieldHasValue ? true : undefined;
    }
  });

  private readonly propsJoinPipe = new PropsJoinPipe();
  private autocomplete!: google.maps.places.Autocomplete;
  protected mapIsShown: boolean = false;
  protected fieldMap: WritableSignal<
    Array<{ field: string; tags: Array<IUiLocationTag> }>
  > = signal(this.settings().fieldMap! ?? defaultFieldMap);

  protected mapOptions = computed<google.maps.MapOptions | undefined>(() => {
    let mapTypeId =
      this.settings().map?.mapTypeId ?? LocationSettings!.defaultMap!.mapTypeId;
    let controlSize =
      this.settings().map?.controlSize ??
      LocationSettings!.defaultMap!.controlSize;
    let center = {
      lat: (this.value() as Location)?.latitude ?? 0,
      lng: (this.value() as Location)?.longitude ?? 0,
    };
    return {
      mapTypeId: mapTypeId,
      controlSize: controlSize,
      center: center,
    };
  });

  popupCssClass = input<string | undefined>();
  protected location?: Location;

  //#endregion

  //#region Functions

  ngAfterViewInit(): void {
    this.locationService.apiLoaded$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => {
        if (value) {
          this.initMap();
        }
      });
  }

  protected override initSettings(settings: ILocationSettings) {
    let locationSettings = UTILS.OBJECT.assign(
      new LocationSettings(),
      settings,
    );
    super.initSettings(locationSettings);
    this.valueFormat.set(locationSettings.valueFormat);
    this.fieldMap.set(locationSettings.fieldMap ?? defaultFieldMap);
    return locationSettings;
  }

  protected override focusout(): void {
    (this.uiValueRef.nativeElement as HTMLInputElement).value =
      this.formattedValue() as string;
    super.focusout();
  }

  private initMap() {
    if (this.uiValueRef?.nativeElement) {
      this.autocomplete = new google.maps.places.Autocomplete(
        this.uiValueRef.nativeElement,
      );

      this.autocomplete.addListener('place_changed', () => {
        let location: Location = {};
        let place: google.maps.places.PlaceResult =
          this.autocomplete.getPlace();
        console.log(place);

        if (UTILS.OBJECT.isNil(place.geometry)) {
          return;
        }

        this.mapTagsToFields(place, location);
        this.focusout();
      });
    }
  }

  private mapTagsToFields(
    place: google.maps.places.PlaceResult,
    location: Location,
  ) {
    location.latitude = place!.geometry!.location?.lat();
    location.longitude = place!.geometry!.location?.lng();

    this.fieldMap()?.forEach((fieldMap) => {
      let locationField = fieldMap.field as keyof Location;

      fieldMap.tags.forEach((tag) => {
        if (
          place.address_components?.some((component) =>
            component.types.includes(tag.tag),
          )
        ) {
          switch (tag.writeMode) {
            case 'append': {
              (location[locationField] as string) =
                `${location[locationField] ? location[locationField] + ' ' : ''}${place.address_components?.find((component) => component.types.includes(tag.tag))?.long_name ?? ''}`;
              break;
            }
            case 'prepend': {
              (location[locationField] as string) =
                `${place.address_components?.find((component) => component.types.includes(tag.tag))?.long_name ?? ''}${location[locationField] ?? ''}`;
              break;
            }
            default: {
              (location[locationField] as string) =
                place.address_components?.find((component) =>
                  component.types.includes(tag.tag),
                )?.long_name ?? '';
              break;
            }
          }
        }
      });
    });

    this.updateValue(location);
  }

  protected popupChanged(isVisible: boolean) {
    this.mapIsShown = isVisible;
  }

  //#endregion
}
