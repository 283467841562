import { StoreModel } from '@aksia/infrastructure';
import {
  CarriedInterestRateTypeEnum,
  CarriedInterestTieredBaseEnum,
  PrefReturnEnum,
  WaterfallEnum,
  ClawbackGuaranteeEnum,
} from '../../enums/closedEnd.enum';
import {
  CurrencyEnum,
  SimpleAnswerEnum,
  MgmtFeeFrequencyEnum,
  DataSourceEnum,
} from '../../enums/entity.enum';
import {
  MgmtFeePeriod,
  ClosedMgmtFeeDiscount,
  CarriedInterestTieredRate,
  CarriedInterestDiscount,
} from '../entities/entities.model';

export class ClosedShareClass extends StoreModel {
  classId?: number = undefined;
  fundId?: number = undefined;
  className?: string = undefined;
  isDefault?: boolean = undefined;
  isDeleted?: boolean = undefined;

  //Delete those 2?
  source?: DataSourceEnum = undefined;
  asOfDate?: Date = undefined;

  //#region <<< Section - Minimum Investment >>>

  minimumLPCommitment?: number = undefined;
  minimumLPCommitmentCurrency?: CurrencyEnum = undefined;

  //#endregion

  //#region <<< Section - Management Fee >>>

  mgmtFee?: SimpleAnswerEnum = undefined;
  mgmtFeeCeasedOn?: Date = undefined;
  mgmtFeePeriods?: Array<MgmtFeePeriod> = undefined;
  paymentFrequency?: MgmtFeeFrequencyEnum = undefined;
  paymentFrequencyDescription?: string;
  mgmtFeeOffsetRate?: number = undefined;
  hasMgmtFeeDiscounts?: SimpleAnswerEnum = undefined;
  mgmtFeeDiscounts?: Array<ClosedMgmtFeeDiscount> = undefined;

  //#endregion

  //#region <<< Section - Carried Interest >>>

  carriedInterest?: SimpleAnswerEnum = undefined;
  carriedInterestRateType?: CarriedInterestRateTypeEnum = undefined;
  carriedInterestTieredBaseType?: CarriedInterestTieredBaseEnum = undefined;
  carriedInterestTieredRates?: Array<CarriedInterestTieredRate> = undefined;
  carriedInterestRate?: number = undefined;
  carriedInterestPrefReturnType?: PrefReturnEnum = undefined;
  carriedInterestPrefReturnRate?: number = undefined;
  carriedInterestCatchUpRate?: number = undefined;
  carriedInterestWaterfall?: WaterfallEnum = undefined;
  carriedInterestWaterfallDesc?: string = undefined;
  carriedInterestWaterfallFairValueTest?: number = undefined;
  gpClawback?: SimpleAnswerEnum = undefined;
  interimCalculations?: SimpleAnswerEnum = undefined;
  escrowAccount?: SimpleAnswerEnum = undefined;
  escrowAccountRate?: number = undefined;
  clawbackGuaranteeType?: ClawbackGuaranteeEnum = undefined;
  hasCarriedInterestDiscounts?: SimpleAnswerEnum = undefined;
  carriedInterestDiscounts?: Array<CarriedInterestDiscount> = undefined;

  //#endregion

  //#region <<< Section - Discounts >>>

  hasFirstCloseDiscount?: SimpleAnswerEnum = undefined;
  hasSizeThresholdDiscount?: SimpleAnswerEnum = undefined;
  hasOtherDiscount?: SimpleAnswerEnum = undefined;

  //#endregion
}
