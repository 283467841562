import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { FormsModule } from '@angular/forms';
import { ClassicEditor } from 'ckeditor5';

@Component({
    selector: 'rich-text',
    imports: [CommonModule, CKEditorModule, FormsModule],
    templateUrl: './rich-text.component.html'
})
export class RichTextComponent {
  //#region Injections

  private elementRef = inject(ElementRef);

  //#endregion

  //#region Inputs/Outputs

  @Input() model!: string;
  @Input() toolbarSpecifications: any;
  @Output() modelChange = new EventEmitter<string>();

  //#endregion

  //#region Properties

  protected Editor = ClassicEditor;
  protected isChanged = false;

  //#endregion

  //#region Functions

  protected focusout() {
    if (this.isChanged) {
      this.modelChange.emit(this.model);
    }
    this.isChanged = false;
  }

  //#endregion
}
