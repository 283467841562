export enum CustomFieldDataTypes {
  Text = 1,
  Number = 2,
  TextArea = 3,
  Boolean = 4,
  Dropdown = 5,
  MultiSelect = 6,
  Date = 7,
  Time = 8,
  Editor = 9,
  File = 10,
  Hyperlink = 11,
  Workflow = 12,
}

export enum Application {
  Projects = 'Projects',
  ClientDeliverables = 'ClientDeliverables',
}

export enum ItemModeEnum {
  adding,
  editing,
}
