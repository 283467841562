<div uipagenavheader>
  <div uipagenavitem>
    <span>Contents</span>
  </div>
</div>
<div uipagenavcontent>
  <div uipagenavitem title="Top" (click)="layout.jumpToAnchor()">
    <span>Top</span>
  </div>
  @for (anchorTag of layout.anchorTags(); track $index) {
    <div
      uipagenavitem
      [attr.title]="anchorTag"
      (click)="layout.jumpToAnchor(anchorTag)"
    >
      <span>{{ anchorTag }}</span>
    </div>
  }
</div>
