import {
  MinGPCommitmentEnum,
  CoInvestPolicyEnum,
} from '../../enums/closedEnd.enum';

export class ClosedEndStructure {
  closedEndDataFieldsId?: number = undefined;
  minGPCommitmentType?: MinGPCommitmentEnum = undefined;
  minGPCommitment?: number = undefined;
  minGPCommitmentAmount?: number = undefined;
  minGPCommitmentCashless?: boolean = undefined;
  coInvestPolicy?: CoInvestPolicyEnum = undefined;
}
