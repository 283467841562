import { SimpleAnswerEnum } from '../../enums/entity.enum';
import {
  AcquisitionTypeEnum,
  AksiaCoverageGPEnum,
  AksiaStatusEnum,
  CoInvFeeStructureEnum,
  DeptSourcingEnum,
  FixedFloatingDebtEnum,
  InstrumentEnum,
  PropertyTypeEnum,
  SingleAssetPortfolioEnum,
} from '../../enums/coInvestment.enum';

export class CoInvestmentDetails {
  id?: number = undefined;
  fundId?: number = undefined;
  projectName?: string = undefined;
  companyHeadquarters?: string = undefined;

  //#region Staff Involved

  sourcingAnalystName?: string = undefined;
  intermediaryBroker?: string = undefined;
  gpAksiaCoverage?: AksiaCoverageGPEnum = undefined;

  //#endregion

  //#region Decision Dates

  status?: AksiaStatusEnum = undefined;
  initialLogDate?: Date = undefined;
  dateApprovedFurtherReview?: Date = undefined;
  dateApprovedDD?: Date = undefined;
  dateApproved?: Date = undefined;
  dateNotApproved?: Date = undefined;
  dateHalted?: Date = undefined;

  //#endregion

  //#region Key Terms

  instrument?: InstrumentEnum = undefined;
  stapledDeal?: SimpleAnswerEnum = undefined;
  overview?: string = undefined;
  hardCommitmentDate?: Date = undefined;
  otherDatesColor?: string = undefined;
  transactionSummary?: string = undefined;
  merits?: string = undefined;
  issues?: string = undefined;
  operatingExpenseCap?: number = undefined;
  feeStructure?: CoInvFeeStructureEnum = undefined;
  interestMargin?: number = undefined;
  originalIssueDiscount?: number = undefined;

  //#endregion

  //#region Syndication & Capacity Notes

  totalInvestmentAmount?: number = undefined;
  totalCoInvestSyndicated?: number = undefined;
  indicativeCapacityAksiaClients?: number = undefined;
  syndicationCapacityNotes?: string = undefined;

  //#endregion

  //#region Financial Statistics

  financialsReportDate?: Date = undefined;
  valuationMetric?: string;
  enterpriseValue?: number = undefined;
  equityValue?: number = undefined;
  netDebt?: number = undefined;
  revenue?: number = undefined;
  ebitdanoi?: number = undefined;
  evSales?: number = undefined;
  evebitda?: number = undefined;
  revenueCAGR?: number = undefined;
  revenueCAGRTimePeriod?: string = undefined;
  ebitdacagr?: number = undefined;
  ebitdacagrTimePeriod?: string = undefined;
  loanValue?: number = undefined;
  attachmentPoint?: number = undefined;
  detachmentPoint?: number = undefined;

  //#endregion

  //#region Real Estate Details

  /* @meta({
    alias: 'Single Asset/Portfolio',
    source: SingleAssetPortfolioEnum.toKeyValue(),
  }) */
  singleAssetPortfolio?: SingleAssetPortfolioEnum = undefined;

  /* @meta({ alias: 'Acquisition Type', source: AcquisitionTypeEnum.toKeyValue() }) */
  acquisitionType?: AcquisitionTypeEnum = undefined;

  /* @meta({
    alias: 'Purchase Price',
  }) */
  purchasePrice?: number = undefined;

  /* @meta({
    alias: 'Entry Cap Rate',
  }) */
  entryCapRate?: number = undefined;

  /* @meta({
    alias: 'Exit Cap Rate',
  }) */
  exitCapRate?: number = undefined;

  /* @meta({
    alias: 'Fixed or Floating Debt',
    source: FixedFloatingDebtEnum.toKeyValue(),
    updates: (self: Meta, value: FixedFloatingDebtEnum) => {
      if (self instanceof CoInvestmentDetails) {
        if (!value) {
          self.floatingRate = null;
          self.fixedRate = null;
        } else if (value === FixedFloatingDebtEnum.Fixed) {
          self.floatingRate = null;
        } else if (value === FixedFloatingDebtEnum.Floating) {
          self.fixedRate = null;
        }
      }
    },
  }) */
  fixedFloatingDebt?: FixedFloatingDebtEnum = undefined;

  /* @meta({
    alias: 'Floating Rate',
  }) */
  floatingRate?: number = undefined;

  /* @meta({
    alias: 'Fixed Rate',
  }) */
  fixedRate?: number = undefined;

  /* @meta({
    alias: 'Debt Sourcing',
    source: DeptSourcingEnum.toKeyValue(),
  }) */
  debtSourcing?: DeptSourcingEnum = undefined;

  /* @meta({
    alias: 'Stabilized',
    source: SimpleAnswerEnum.toKeyValue().filter(
      (sa) => sa.key !== SimpleAnswerEnum.NotSpecified
    ),
  }) */
  stabilized?: SimpleAnswerEnum = undefined;

  /* @meta({
    alias: 'Stabilized YOC',
  }) */
  stabilizedYOC?: number = undefined;

  /* @meta({
    alias: 'Development/Re-Development',
    source: SimpleAnswerEnum.toKeyValue().filter(
      (sa) => sa.key !== SimpleAnswerEnum.NotSpecified
    ),
  }) */
  developReDevelop?: SimpleAnswerEnum = undefined;

  /* @meta({
    alias: 'Size (sq. ft)',
  }) */
  sizeSF?: number = undefined;

  /* @meta({
    alias: 'Size (units)',
  }) */
  sizeUn?: number = undefined;

  //#endregion
}
