export enum ProjectOriginType {
  Project = 1,
  Deliverable = 2,
  Workflow = 3,
}

export enum PublicActivityMails {
  IDD = 'IDD - Global',
  ODD = 'ODD - Global',
  Advisory = 'Advisory - Global',
  Compliance = 'Compliance',
}
