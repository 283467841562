import { KeyValue } from '@angular/common';
import { currencyExp } from '../types/regular.expressions';
import {
  PropExtend,
  DeepSignal,
  WritableDeepSignal,
} from '../types/common.types';
import {
  Signal,
  WritableSignal,
  computed,
  isSignal,
  signal,
} from '@angular/core';

export namespace UTILS {
  export namespace CONSTANTS {
    export const EMPTY_ARRAY = [] as const;
    export const DAYS_TO_DDD = [
      'Sun',
      'Mon',
      'Tue',
      'Wed',
      'Thu',
      'Fri',
      'Sat',
    ];
    export const MONTHS_TO_MMM = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    export const NUMBERS = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    export const DECIMALS = [...NUMBERS, '.'];
    export const SIGNED_NUMBERS = [...NUMBERS, '-'];
    export const SIGNED_DECIMALS = [...DECIMALS, '-'];
    export const NAVIGATION_KEYS = [
      'ArrowLeft',
      'ArrowRight',
      'ArrowUp',
      'ArrowDown',
      'Home',
      'End',
      'Tab',
      'Enter',
    ];
    export const EDIT_KEYS = ['Backspace', 'Delete', 'F2', 'Esc'];
    export const SUBMIT_KEYS = ['Enter', 'Esc'];
    export const DEFAULT_ENUM_REPLACE_RULES: Array<KeyValue<RegExp, string>> = [
      { key: /([a-z])([A-Z])/g, value: '$1 $2' },
    ];
    export const VALID_MAP_TAGS = [
      'street_number',
      'premise',
      'route',
      'postal_code',
      'postal_town',
      'locality',
      'country',
      'administrative_area_level_1',
    ];
  }

  export namespace FORMAT {
    export function toAbbr(
      value: number,
      abbrSmall: boolean = false,
      decimalAmount: number = 3,
      thousandsSeparator: string = ',',
    ): string {
      let suffix = '';
      let abbr: number;

      if (Math.abs(value) < 1000000) {
        abbr = value;
      } else if (Math.abs(value) < 1000000000) {
        abbr = value / 1000000;
        suffix = abbrSmall ? 'm' : ' million';
      } else if (Math.abs(value) < 1000000000000) {
        abbr = value / 1000000000;
        suffix = abbrSmall ? 'b' : ' billion';
      } else {
        abbr = value / 1000000000000;
        suffix = abbrSmall ? 't' : ' trillion';
      }

      const final = Number.isInteger(abbr)
        ? parseInt(abbr.toString()).toString()
        : Number(abbr.toFixed(decimalAmount)).toString();
      return `${final.replace(currencyExp, thousandsSeparator)}${suffix}`;
    }

    export function toMonthMMM(value?: Date): string | undefined {
      if (value) {
        return CONSTANTS.MONTHS_TO_MMM[value.getMonth()];
      }
      return undefined;
    }

    export function toDateFormat(
      value: Date | string | undefined,
      format: string = 'mm/dd/yyyy',
    ) {
      if (!value) return undefined;
      let date = typeof value === 'string' ? new Date(value) : value;
      return date instanceof Date
        ? format
            .replace('dd', date.getDate().toString())
            .replace('mm', (date.getMonth() + 1).toString())
            .replace('MMM', FORMAT.toMonthMMM(date)!)
            .replace('yyyy', date.getFullYear().toString())
        : undefined;
    }
  }

  export namespace DATE {
    export function isDate(value: unknown): boolean {
      return value instanceof Date && isFinite(value.getTime());
    }

    export function toLocalISOString(value: Date) {
      if (!isDate(value)) {
        return undefined;
      }

      return `${value.getFullYear()}-${(value.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${value
        .getDate()
        .toString()
        .padStart(2, '0')}T00:00:00.000Z`;
    }

    export function toDate(value: Date | string | undefined) {
      return !value
        ? undefined
        : typeof value === 'string'
          ? new Date(value)
          : value;
    }

    export function toLocalDate(value: Date | string | undefined) {
      if (!value) return undefined;
      let stringValue =
        typeof value === 'string' ? value : UTILS.DATE.toLocalISOString(value);
      const newDate = new Date(stringValue!.replace('Z', ''));
      return isDate(newDate) ? newDate : undefined;
    }

    export function toLocalDateString(value: Date) {
      return value
        ? `${(value.getMonth() + 1).toString().padStart(2, '0')}/${value.getDate().toString().padStart(2, '0')}/${value.getFullYear()}`
        : '';
    }

    export function toEndOfMonth(value: Date) {
      if (!isDate(value)) {
        return undefined;
      }

      return new Date(value!.getFullYear(), value!.getMonth() + 1, 0);
    }

    export function addYear(
      value: Date,
      years: number = 1,
      mutate: boolean = false,
    ) {
      if (!isDate(value)) {
        return undefined;
      }
      let newValue = mutate ? value : new Date(value);
      newValue.setFullYear(newValue.getFullYear() + years);
      return newValue;
    }

    export function addMonth(
      value: Date,
      months: number = 1,
      mutate: boolean = false,
    ) {
      if (!isDate(value)) {
        return undefined;
      }
      let newValue = mutate ? value : new Date(value);
      newValue.setMonth(newValue.getMonth() + months);
      return newValue;
    }

    export function addDay(
      value: Date,
      days: number = 1,
      mutate: boolean = false,
    ) {
      if (!isDate(value)) {
        return undefined;
      }
      let newValue = mutate ? value : new Date(value);
      newValue.setDate(newValue.getDate() + days);
      return newValue;
    }

    export function getMonthDays(value: Date) {
      if (!isDate(value)) {
        return undefined;
      }
      return new Date(value.getFullYear(), value.getMonth() + 1, 0).getDate();
    }
  }

  export namespace STRING {
    export function trimEnd(value: string, char: string) {
      while (value.at(-1) === char) {
        value = value.slice(0, -1);
      }
      return value;
    }

    export function removeTrailingUnderscores(name: string) {
      const nameArr = name.split('.');
      const ext = nameArr.pop();
      let nameWithoutExt = nameArr.join('.');
      let hasUnderscores = true;
      while (hasUnderscores) {
        if (nameWithoutExt.charAt(nameWithoutExt.length - 1) === '_') {
          nameWithoutExt = nameWithoutExt.slice(0, -1);
        } else hasUnderscores = false;
      }
      return `${nameWithoutExt}.${ext}`;
    }

    export function sanitizeName(name: string) {
      return name.replace(/[//?<>\\:*|,.&'"+@%#/{} /]+/g, '_');
    }

    export function isNullOrEmpty(text: string) {
      return (
        text === undefined ||
        text === null ||
        !text ||
        text.length === 0 ||
        /^\s*$/.test(text)
      );
    }

    export function isValidLink(text: string) {
      // Regular expression to check if the input text is a valid URL
      const urlRegex =
        /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
      return urlRegex.test(text);
    }

    export function isValidEmail(email: string) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        );
    }

    export function multiReplace(
      key: string,
      replaceRules: Array<KeyValue<RegExp | string, string>>,
    ) {
      let keyAfterReplace: string = key;
      replaceRules.forEach((item) => {
        keyAfterReplace = keyAfterReplace.replace(item.key, item.value);
      });
      return keyAfterReplace;
    }

    export function findSubstringDifferences(
      str1: string,
      str2: string,
    ): Array<string> {
      const diff: string[] = [];
      const str1Parts = str1.split(' ');
      const str2Parts = str2.split(' ');
      str1Parts.forEach((part, index) => {
        if (part !== str2Parts[index]) {
          diff.push(`'${part}' vs '${str2Parts[index] || ''}'`);
        }
      });
      return diff;
    }
  }

  export namespace UI {
    export function enumToOptions(
      enumSource: PropExtend<unknown>,
      replaceRules: Array<
        KeyValue<RegExp, string>
      > = CONSTANTS.DEFAULT_ENUM_REPLACE_RULES,
      omit: Array<string | number> = [],
    ): Array<{ label: string; value: number | string }> {
      const entries = Object.entries(enumSource).filter(
        (entry) => typeof entry.at(-1) !== 'function',
      );

      let keys = entries.map((entry) => entry.at(0));
      let values = entries.map((entry) => entry.at(-1));

      if (typeof entries.at(-1)?.at(-1) === 'number') {
        const halfEntries = entries.slice(entries.length / 2);
        values = halfEntries.map((entry) => entry.at(-1));
        keys = halfEntries.map((entry) => entry.at(0));
      }

      const valueLabels = keys.map((key, index) => ({
        label:
          typeof values[index] === 'number'
            ? replaceRules?.length! > 0
              ? STRING.multiReplace(key as string, replaceRules!)
              : (key as string)
            : (values[index] as string),
        value: values[index] as number | string,
      }));

      if (omit.length > 0) {
        return valueLabels.filter((item) => !omit.includes(item.value));
      }

      return valueLabels;
    }

    export function dataToOptions(
      dataSource:
        | Array<PropExtend<unknown>>
        | Signal<Array<PropExtend<unknown>>>,
      labelField: string = 'label',
      valueField: string = 'value',
      deleteField: string = 'isDeleted',
    ): Array<{
      label: string;
      value: number | string;
      isDeleted: boolean;
      isSelectable: boolean;
      isSelected: boolean;
    }> {
      return (
        typeof dataSource === 'function' ? dataSource() : dataSource
      )?.map((item) => {
        item = typeof item === 'function' ? item() : item;
        let label =
          typeof item[labelField] === 'function'
            ? item[labelField]()
            : item[labelField];
        let value =
          typeof item[valueField] === 'function'
            ? item[valueField]()
            : item[valueField];
        let isDeleted =
          typeof item[deleteField] === 'function'
            ? item[deleteField]()
            : item[deleteField];
        let data = item;
        let isSelectable = true;
        let isSelected = false;
        return { label, value, isDeleted, data, isSelectable, isSelected };
      });
    }
  }

  export namespace CLASS {
    export function applyMixins(baseClass: any, extendedClasses: any[]) {
      extendedClasses.forEach((extendedClass) => {
        Object.getOwnPropertyNames(extendedClass.prototype).forEach((name) => {
          Object.defineProperty(
            baseClass.prototype,
            name,
            Object.getOwnPropertyDescriptor(extendedClass.prototype, name) ||
              Object.create(null),
          );
        });
      });
    }
  }

  export namespace OBJECT {
    export function isNil(value: unknown): boolean {
      return value === null || value === undefined;
    }

    export function isObject(value: unknown): boolean {
      return (
        typeof value === 'object' &&
        value !== null &&
        !Array.isArray(value) &&
        !(value instanceof RegExp) &&
        !(value instanceof Date) &&
        !(value instanceof Set) &&
        !(value instanceof Map)
      );
    }

    export function isEmpty(value: unknown): boolean {
      return isNil(value) || value === '';
    }

    export function areEqual(a: unknown, b: unknown) {
      return (
        (isNil(a) && isNil(b)) ||
        (a instanceof Date && b instanceof Date
          ? DATE.toLocalISOString(a! as Date) ===
            DATE.toLocalISOString(b! as Date)
          : a === b)
      );
    }

    export function areDeepEqual(
      a: PropExtend<unknown>,
      b: PropExtend<unknown>,
      log: boolean = false,
    ) {
      let falseWithLog = (a: any, b: any, prop?: string) => {
        if (log) {
          console.warn(prop);
          console.warn('a: ', a);
          console.warn('b: ', b);
        }
        return false;
      };
      if (a === b) return true;
      if (a instanceof Date && b instanceof Date) {
        return DATE.toLocalISOString(a) === DATE.toLocalISOString(b);
      }
      if (typeof a === 'function' || typeof b === 'function') return true;
      if (typeof a !== 'object' || typeof b !== 'object')
        return falseWithLog(a, b, 'No Object');
      if (Array.isArray(a) && Array.isArray(b)) {
        if (a.length !== b.length)
          return falseWithLog(a, b, 'No Equal Array Length');
        for (let i = 0; i < a.length; i++) {
          let hasEqual = b.find((item) => areDeepEqual(a[i], item));
          if (!hasEqual) return falseWithLog(a, b, 'No Equal Array Item');
        }
        return true;
      }
      if (UTILS.OBJECT.isNil(a) || UTILS.OBJECT.isNil(b))
        return falseWithLog(a, b, 'At least one Object Is Nill');
      if (Object.keys(a).length !== Object.keys(b).length)
        return falseWithLog(a, b, 'No Equal Object Length');

      for (let prop in a) {
        if (!Object.hasOwn(b, prop))
          return falseWithLog(a, b, `Object does not own property: ${prop}`);
        if (!areDeepEqual(a[prop], b[prop]))
          return falseWithLog(
            a[prop],
            b[prop],
            `No Equal Object property: ${prop}`,
          );
      }

      return true;
    }

    export function assign(
      target: PropExtend<unknown>,
      source: PropExtend<unknown>,
    ) {
      for (let prop in source) {
        if (Object.hasOwn(source, prop)) {
          if (Array.isArray(source[prop])) {
            target[prop] = [...source[prop]];
          } else if (
            typeof source[prop] === 'object' &&
            source[prop] !== null
          ) {
            target[prop] = Object.assign(target[prop] || {}, source[prop]);
          } else {
            target[prop] = source[prop];
          }
        }
      }
      return target;
    }

    export function assignByJson(source: unknown, omit?: Array<string>) {
      return JSON.parse(
        JSON.stringify(source, function (key, value) {
          return !omit?.includes(key) ? value : undefined;
        }),
      );
    }

    export function assignToPath(
      target: PropExtend<unknown>,
      fieldPath: string,
      value: unknown,
    ) {
      if (!fieldPath.includes('.')) {
        target[fieldPath] = value;
      } else {
        const childField = fieldPath.split('.')[0];
        const remainingFields = fieldPath.split('.').slice(1).join('.');
        if (!Object.hasOwn(target, childField)) {
          target[childField] = {};
        }
        assignToPath(target[childField], remainingFields, value);
      }
      return target;
    }
  }

  export namespace ARRAY {
    export function compareArrayByJSON(a: Array<unknown>, b: Array<unknown>) {
      return (!a && !b) || JSON.stringify(a) === JSON.stringify(b);
    }

    export function customSort(collection: any[], sorts: any[]) {
      sorts.map(
        (sort: { uniques: any[]; key: string | number; inverse: any }) => {
          sort.uniques = Array.from(
            new Set(
              collection.map((obj: { [x: string]: any }) => obj[sort.key]),
            ),
          );

          sort.uniques = sort.uniques.sort((a, b) => {
            if (typeof a == 'string') {
              return sort.inverse ? b.localeCompare(a) : a.localeCompare(b);
            } else if (typeof a == 'number') {
              return sort.inverse ? b - a : a - b;
            } else if (typeof a == 'boolean') {
              let x = sort.inverse
                ? a === b
                  ? 0
                  : a
                    ? -1
                    : 1
                : a === b
                  ? 0
                  : a
                    ? 1
                    : -1;
              return x;
            }
            return 0;
          });
        },
      );

      const weightOfObject = (obj: { [x: string]: any }) => {
        let weight = '';
        sorts.map(
          (sort: {
            uniques: {
              length: any;
              indexOf: (arg0: any) => {
                (): any;
                new (): any;
                toString: { (): string; new (): any };
              };
            };
            key: string | number;
          }) => {
            let zeropad = `${sort.uniques.length}`.length;
            weight += sort.uniques
              .indexOf(obj[sort.key])
              .toString()
              .padStart(zeropad, '0');
          },
        );
        //obj.weight = weight; // if you need to see weights
        return weight;
      };

      collection.sort((a: { [x: string]: any }, b: { [x: string]: any }) => {
        return weightOfObject(a).localeCompare(weightOfObject(b));
      });

      return collection;
    }

    export function distinct<T>(collection: Array<T>) {
      return collection?.length > 0 ? [...new Set(collection)] : [];
    }
  }

  export class GENERATE {
    static uid: number = 1;
    static get nextuid() {
      return this.uid++;
    }
  }

  export namespace GENERATE {
    export function random(
      min: number = 0,
      max: number = Number.POSITIVE_INFINITY,
    ) {
      return Math.round(Math.random() * (max - min) + min);
    }

    export function generateResults(
      template: any,
      numberOfResults: number = 5,
    ): Array<unknown> {
      const possible = 'abcdefghijklmnopqrstuvwxyz';
      const results = [...Array(numberOfResults).keys()].map((i) => {
        const item = {} as any;
        for (let prop in template) {
          if (template[prop] === '#') {
            item[prop] = random(1, 1000);
          }
          if (template[prop].includes('#')) {
            item[prop] = template[prop]?.replaceAll('#', random(1, 1000));
          } else if (template[prop].includes('$')) {
            const $$ = [...Array(random(0, 10)).keys()]
              .map((i) => (Math.random() > 0.5 ? '$' : ' '))
              .join('');
            template[prop] = template[prop].replaceAll('$', $$);
            item[prop] = template[prop].replaceAll(
              '$',
              possible.at(random(0, possible.length)),
            );
          } else {
            item[prop] = template[prop];
          }
        }
        return item;
      });
      return results;
    }
  }

  export namespace EVENTS {
    export function copyToClipboard(value: string): boolean {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(value);
        return true;
      } else {
        const textArea = document.createElement('textarea');
        textArea.value = value;

        // Avoid scrolling to bottom
        textArea.style.top = '0';
        textArea.style.left = '0';
        textArea.style.position = 'fixed';

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
          const successful = document.execCommand('copy');
          if (successful) {
            document.body.removeChild(textArea);
            return true;
          }
          return false;
        } catch (err) {
          document.body.removeChild(textArea);
          console.error('Fallback: Oops, unable to copy', err);
          return false;
        }
      }
    }
  }

  export namespace KEYVALUE {
    export function enumToKeyValue(
      enumProp: any,
      replaceRules?: Array<KeyValue<RegExp, string>>,
    ): Array<KeyValue<number | string, string>> {
      const entries = Object.entries(enumProp).filter(
        (entry) => typeof entry.at(-1) !== 'function',
      );

      let keys = entries.map((entry) => entry.at(0));
      let values = entries.map((entry) => entry.at(-1));

      if (typeof entries.at(-1)?.at(-1) === 'number') {
        const halfEntries = entries.slice(entries.length / 2);
        keys = halfEntries.map((entry) => entry.at(-1));
        values = halfEntries.map((entry) => entry.at(0));
      }

      const keyValues: Array<KeyValue<number | string, string>> = keys.map(
        (key, index) => ({
          value:
            replaceRules?.length! > 0
              ? STRING.multiReplace(values[index] as string, replaceRules!)
              : (values[index] as string),
          key: key as number | string,
        }),
      );

      return keyValues;
    }

    export function toKeyValue<
      T extends { [s: string]: unknown } | ArrayLike<unknown>,
    >(this: T) {
      return Object.entries(this)
        .filter(
          (prop) =>
            !['toKeyValue', 'getKeyFromValue'].includes(prop.at(0) as string),
        )
        .map((view) => ({
          key: view.at(0) as string,
          value: view.at(1) as string,
        }));
    }

    export function getKeyFromValue<
      T extends { [s: string]: unknown } | ArrayLike<unknown>,
    >(this: T, value: string) {
      return Object.entries(this)
        .filter(
          (prop) =>
            !['toKeyValue', 'getKeyFromValue'].includes(prop.at(0) as string),
        )
        ?.map((view) => ({ key: view.at(0), value: view.at(1) }))
        ?.find((item) => item.value === value)?.key;
    }

    export function splitCamelCase(value: string) {
      return value.replace(/([a-z])([A-Z])/g, '$1 $2');
    }

    export function isNullOrEmpty(text: any) {
      return (
        text === undefined ||
        text === null ||
        !text ||
        text.length === 0 ||
        /^\s*$/.test(text)
      );
    }
  }

  export namespace SIGNALS {
    export function isDeepSignal(
      value: PropExtend<unknown>,
    ): value is DeepSignal<unknown> {
      if (isSignal(value)) {
        return isDeepSignal(value());
      } else if (Array.isArray(value)) {
        return isSignal(value?.at(0));
      } else if (OBJECT.isObject(value)) {
        for (let prop in value) {
          if (isSignal(value[prop])) {
            return true;
          }
        }
        return false;
      }
      return false;
    }
    export function toDeepSignalArray<T>(
      plain: Array<T>,
      instance: { new (): PropExtend<T> } = class {} as any,
    ): WritableDeepSignal<Array<T>> {
      return signal(
        plain.map((item: T) =>
          typeof item === 'object'
            ? toDeepSignal<T>(item, instance)
            : (signal(item) as WritableSignal<DeepSignal<T> | undefined>),
        ) as DeepSignal<Array<T>> | undefined,
      );
    }
    export function toDeepSignal<T>(
      plain: T,
      instance: { new (): PropExtend<T> } = class {} as any,
    ): WritableDeepSignal<T> {
      if (!plain) return signal(undefined);
      else if (Array.isArray(plain)) {
        return toDeepSignalArray(plain, instance) as WritableSignal<
          DeepSignal<T> | undefined
        >;
      } else {
        let unassignedInstance = new instance();
        const newInstance = Object.assign(new instance(), plain);
        const signaled: PropExtend<T> = {};
        signaled._plain = computed(() => UTILS.SIGNALS.toPlain(signaled));
        signaled._deepThis = signal(signaled);
        for (let prop in newInstance) {
          if (typeof unassignedInstance[prop] === 'function') {
            const unassignedFn = unassignedInstance[prop] as Function;
            signaled[prop] = (value: unknown) =>
              unassignedFn.call(undefined, value, signaled._deepThis);
          } else if (Array.isArray(newInstance[prop])) {
            signaled[prop] = signal(
              (newInstance[prop] as Array<unknown>)?.map(
                (item: PropExtend<unknown>) => {
                  let itemArray: typeof item & { new (): PropExtend<unknown> } =
                    unassignedInstance[prop]?.at?.(0)?.constructor ??
                    (class {} as any);
                  return typeof item === 'object'
                    ? toDeepSignal<typeof itemArray>(item, itemArray)
                    : signal(item);
                },
              ),
            );
          } else if (UTILS.DATE.isDate(newInstance[prop])) {
            signaled[prop] = signal(newInstance[prop]);
          } else if (OBJECT.isObject(newInstance[prop])) {
            let itemInstance: (typeof newInstance)[typeof prop] & {
              new (): PropExtend<unknown>;
            } = unassignedInstance[prop]?.constructor ?? (class {} as any);
            signaled[prop] = toDeepSignal(newInstance[prop], itemInstance);
          } else {
            signaled[prop] = signal(newInstance[prop]);
          }
        }
        return signal(signaled);
      }
    }

    export function toPlainArray<T>(
      deepSignal: DeepSignal<T> | undefined,
    ): T | undefined {
      return Object.entries(toPlain(deepSignal)!).map(([_, val]) => val) as T;
    }

    export function toPlain<T>(
      deepSignal: DeepSignal<T> | undefined,
    ): T | undefined {
      if (!deepSignal) return undefined;
      else if (Array.isArray(deepSignal)) {
        const plain = deepSignal.map((item) => toPlain(item())) as T;
        return plain;
      } else {
        const plain = {} as PropExtend<T>;
        for (let prop in deepSignal) {
          if (prop.startsWith('_')) continue;
          else if (Array.isArray(deepSignal![prop]())) {
            plain[prop] = (
              deepSignal![prop]() as Array<WritableSignal<DeepSignal<unknown>>>
            )?.map((item) => {
              return typeof item() === 'object' ? toPlain(item()) : item();
            });
          } else if (UTILS.DATE.isDate(deepSignal![prop])) {
            plain[prop] = UTILS.DATE.toLocalISOString(
              deepSignal![prop]() as Date,
            );
          } else if (typeof deepSignal![prop]() === 'object') {
            plain[prop] = toPlain(deepSignal![prop]());
          } else {
            plain[prop] = deepSignal![prop]();
          }
        }
        return plain;
      }
    }
  }

  export namespace LOGS {
    export function log(
      arg: any,
      color: string = 'orange',
      background: string = 'black',
    ) {
      console.log(`%c ${arg}`, `background: ${background}; color: ${color};`);
    }
  }
}
