<div class="center" select="[center]">
  <router-outlet></router-outlet>
</div>

@if([layouts.All,layouts.Left,layouts.LeftRight,layouts.TopLeft,layouts.BottomLeft,layouts.BottomLeftRight,layouts.TopLeftRight].includes(layoutType))
{ @if(settings().leftArea?.hasHandle){
<div
  class="leftHandle handle"
  [class.expanded]="expandedAreas.includes('left')"
  select="[leftHandle]"
  draggable="true"
  (dragstart)="startDrag($event,'--left-width')"
  (drag)="drag($event)"
  (dragend)="endDrag($event)"
  (dblclick)="toggle('left')"
></div>
}
<div class="left">
  <ng-content select="[left]"></ng-content>
  <router-outlet name="left"></router-outlet>
</div>
}
@if([layouts.All,layouts.Right,layouts.LeftRight,layouts.TopRight,layouts.BottomRight,layouts.BottomLeftRight,layouts.TopLeftRight].includes(layoutType))
{ @if(settings().rightArea?.hasHandle){
<div
  class="rightHandle handle"
  [class.expanded]="expandedAreas.includes('right')"
  select="[rightHandle]"
  draggable="true"
  (dragstart)="startDrag($event,'--right-width')"
  (drag)="drag($event)"
  (dragend)="endDrag($event)"
  (dblclick)="toggle('right')"
></div>
}
<div class="right">
  <ng-content select="[right]"></ng-content>
  <router-outlet name="right"></router-outlet>
</div>
}
@if([layouts.All,layouts.Top,layouts.TopBottom,layouts.TopLeft,layouts.TopRight,layouts.TopLeftRight].includes(layoutType)){
<div class="top" [class.frozen]="freeze.includes('top')">
  <ng-content select="[top]"></ng-content>
  <router-outlet name="top"></router-outlet>
</div>
@if(settings().topArea?.hasHandle){
<div
  class="topHandle handle"
  [class.expanded]="expandedAreas.includes('top')"
  select="[topHandle]"
  draggable="true"
  (dragstart)="startDrag($event,'--top-height')"
  (drag)="drag($event)"
  (dragend)="endDrag($event)"
  (dblclick)="toggle('top')"
></div>
} }
@if([layouts.All,layouts.Bottom,layouts.TopBottom,layouts.BottomLeft,layouts.BottomRight,layouts.BottomLeftRight].includes(layoutType))
{ @if(settings().bottomArea?.hasHandle){
<div
  class="bottomHandle handle"
  [class.expanded]="expandedAreas.includes('bottom')"
  select="[bottomHandle]"
  draggable="true"
  (dragstart)="startDrag($event,'--bottom-height')"
  (drag)="drag($event)"
  (dragend)="endDrag($event)"
  (dblclick)="toggle('bottom')"
></div>
}
<div class="bottom">
  <ng-content select="[bottom]"></ng-content>
  <router-outlet name="bottom"></router-outlet>
</div>
}
