import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  localStorage: Storage = window.localStorage;

  get(key: string): any {
    if (this.isLocalStorageSupported) {
      let value = this.localStorage.getItem(key);
      if (value) {
        return JSON.parse(value);
      }
    }
    return null;
  }

  set(key: string, value: any): boolean {
    if (this.isLocalStorageSupported) {
      const objValue = value instanceof Map ? Object.fromEntries(value) : value;
      if (objValue !== undefined) {
        this.localStorage.setItem(key, JSON.stringify(objValue));
        return true;
      }
    }
    return false;
  }

  remove(key: string): boolean {
    if (this.isLocalStorageSupported) {
      this.localStorage.removeItem(key);
      return true;
    }
    return false;
  }

  get isLocalStorageSupported(): boolean {
    return !!this.localStorage;
  }
}
