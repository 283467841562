import { UTILS } from '@aksia/infrastructure';

export enum CloseDateTypeEnum {
  Estimate = 1,
  Actual,
}
export namespace CloseDateTypeEnum {
  export const toOptions = () => UTILS.UI.enumToOptions(CloseDateTypeEnum);
}

export enum CarriedInterestRateTypeEnum {
  SingleRate = 1,
  TieredRate,
}
export namespace CarriedInterestRateTypeEnum {
  const replace = [{ key: /([a-z])([A-Z])/g, value: '$1 $2' }];
  export const toOptions = () =>
    UTILS.UI.enumToOptions(CarriedInterestRateTypeEnum, replace);
}

export enum CarriedInterestTieredBaseEnum {
  PreferredReturn = 1,
  Multiple,
  PreferredReturn_and_Multiple,
}
export namespace CarriedInterestTieredBaseEnum {
  const replace = [
    { key: /_and_/g, value: ' & ' },
    { key: /([a-z])([A-Z])/g, value: '$1 $2' },
  ];
  export const toOptions = () =>
    UTILS.UI.enumToOptions(CarriedInterestTieredBaseEnum, replace);
}

export enum PrefReturnEnum {
  No = 1,
  YesWithCatch_Up,
  YesWithoutCatch_Up,
}
export namespace PrefReturnEnum {
  const replace = [
    { key: /_/g, value: '-' },
    { key: /([a-z])([A-Z])/g, value: '$1 $2' },
  ];
  export const toOptions = () =>
    UTILS.UI.enumToOptions(PrefReturnEnum, replace);
}

export enum WaterfallEnum {
  European___Fund_Level__ = 2,
  American___Deal_by_Deal__ = 1,
  American___Fair_Value_Test__ = 4,
  Other = 3,
}
export namespace WaterfallEnum {
  const replace = [
    { key: /___/g, value: ' (' },
    { key: /__/g, value: ')' },
    { key: /_/g, value: ' ' },
    { key: /([a-z])([A-Z])/g, value: '$1 $2' },
  ];
  export const toOptions = () => UTILS.UI.enumToOptions(WaterfallEnum, replace);
}

export enum ClawbackGuaranteeEnum {
  No = 1,
  Yes__by_carry_recipients_severally,
  Yes__by_carry_recipients_jointly,
  Yes__by_management,
  Yes__by_management_and_carry_recipients_severally,
  Yes__by_management_or_carry_recipients_severally,
}
export namespace ClawbackGuaranteeEnum {
  const replace = [{ key: /_/g, value: ' ' }];
  export const toOptions = () =>
    UTILS.UI.enumToOptions(ClawbackGuaranteeEnum, replace);
}

export enum MgmtFeeFromEnum {
  First_Close_Date = 1,
  Final_Close_Date = 10,
  Fee_Accrual = 11,
  First_DrawDown_Due_Date = 2,
  Effective_Date = 3,
  End_of_Investment_Period = 4,
  Earlier_of_Investment_Period_End_and_Date_Successor_Fund_is_Raised = 5,
  End_of_Term___without_extensions__ = 6,
  First_Term_Extension = 7,
  Second_Term_Extension = 8,
  Other = 9,
  Fund____s_Dissolution = 12,
}
export namespace MgmtFeeFromEnum {
  const replace = [
    { key: /_d_/g, value: '' },
    { key: /____/g, value: `'` },
    { key: /___/g, value: ' (' },
    { key: /__/g, value: ') ' },
    { key: /_/g, value: ' ' },
  ];
  export const toOptions = () =>
    UTILS.UI.enumToOptions(MgmtFeeFromEnum, replace);
}

export enum MgmtFeeToEnum {
  End_of_Investment_Period = 1,
  Earlier_of_Investment_Period_End_and_Date_Successor_Fund_is_Raised,
  End_of_Term___without_extensions__,
  First_Term_Extension,
  Second_Term_Extension,
  Fund____s_Dissolution,
  Other,
}
export namespace MgmtFeeToEnum {
  const replace = [
    { key: /_d_/g, value: '' },
    { key: /____/g, value: `'` },
    { key: /___/g, value: ' (' },
    { key: /__/g, value: ') ' },
    { key: /_/g, value: ' ' },
  ];
  export const toOptions = () => UTILS.UI.enumToOptions(MgmtFeeToEnum, replace);
}

export enum RecyclingTimeLimitEnum {
  Only_for_amounts_received_during_the_investment_period = 1,
  Only_for_investments_realized_within_a_specific_timeframe,
}
export namespace RecyclingTimeLimitEnum {
  const replace = [{ key: /_/g, value: ' ' }];
  export const toOptions = () =>
    UTILS.UI.enumToOptions(RecyclingTimeLimitEnum, replace);
}

export enum RecyclingProceedsLimitEnum {
  Only_principal_amount_of_investments = 1,
  Only_amounts_drawn_down_for_expenses,
  Other,
}
export namespace RecyclingProceedsLimitEnum {
  const replace = [{ key: /_/g, value: ' ' }];
  export const toOptions = () =>
    UTILS.UI.enumToOptions(RecyclingProceedsLimitEnum, replace);
}

export enum LpClawbackCalculatedOnEnum {
  Commitments = 1,
  DistributionsReceived,
}
export namespace LpClawbackCalculatedOnEnum {
  const replace = [{ key: /([a-z])([A-Z])/g, value: '$1 $2' }];
  export const toOptions = () =>
    UTILS.UI.enumToOptions(LpClawbackCalculatedOnEnum, replace);
}

export enum LpClawbackTimeLimitFromEnum {
  Date_of_distribution = 1,
  Date_of_fund__s_dissolution,
  Date_LPs_are_notified,
}
export namespace LpClawbackTimeLimitFromEnum {
  const replace = [
    { key: /__/g, value: `'` },
    { key: /_/g, value: ' ' },
  ];
  export const toOptions = () =>
    UTILS.UI.enumToOptions(LpClawbackTimeLimitFromEnum, replace);
}

export enum MgmtFeeCalcOnEnum {
  committed_capital = 1,
  actively_invested_capital = 2,
  actively_invested_capital_plus_unfunded_commitments = 5,
  total_invested_capital = 6,
  total_invested_capital_plus_unfunded_commitments = 14,
  NAV = 3,
  GAV = 7,
  GAV_plus_unfunded_commitments = 9,
  unfunded_commitments = 15,
  lesser_of_NAV_and_committed_capital = 12,
  lesser_of_NAV_and_total_invested_capital = 13,
  lesser_of_NAV_and_actively_invested_capital = 4,
  lesser_of_actively_invested_capital_and_committed_capital = 10,
  lesser_of_total_invested_capital_and_committed_capital = 11,
  other_amount = 8,
}
export namespace MgmtFeeCalcOnEnum {
  const replace = [{ key: /_/g, value: ' ' }];
  export const toOptions = () =>
    UTILS.UI.enumToOptions(MgmtFeeCalcOnEnum, replace);
}

export enum LeverageUseEnum {
  Yes___all_leverage_facilities__ = 1,
  Yes___sub_lines_only__,
  No,
}

export namespace LeverageUseEnum {
  const replace = [
    { key: /___/g, value: ' (' },
    { key: /__/g, value: ')' },
    { key: /_/g, value: ' ' },
  ];
  export const toOptions = () =>
    UTILS.UI.enumToOptions(LeverageUseEnum, replace);
}

export enum MgmtFeePeriodTypeEnum {
  calculated_on_committed_capital = 1,
  calculated_on_invested_capital,
  calculated_on_NAV,
  calculated_on_lesser_of_NAV_and_invested_capital,
  calculated_on_invested_capital_plus_unfunded_commitments,
  calculated_on_other_amount,
}

export namespace MgmtFeePeriodTypeEnum {
  const replace = [{ key: /_/g, value: ' ' }];
  export const toOptions = () =>
    UTILS.UI.enumToOptions(MgmtFeePeriodTypeEnum, replace);
}

export enum ExtensionTypeEnum {
  in_GPs_Discretion = 1,
  with_LPAC_consent,
  with_LP_consent,
  with_LP__LPAC_consent,
}
export namespace ExtensionTypeEnum {
  const replace = [
    { key: /__/g, value: '/' },
    { key: /_/g, value: ' ' },
  ];
  export const toOptions = () =>
    UTILS.UI.enumToOptions(ExtensionTypeEnum, replace);
}

export enum RateIndexEnum {
  Index = 1,
  FixedRate,
  IndexPlusFixedRate,
}
export namespace RateIndexEnum {
  const replace = [{ key: /([a-z])([A-Z])/g, value: '$1 $2' }];
  export const toOptions = () => UTILS.UI.enumToOptions(RateIndexEnum, replace);
}

export enum LeverageTypeEnum {
  Fund = 1,
  Asset,
}

export enum LeverageBasisEnum {
  calculated_on_committed_capital = 1,
  calculated_on_NAV,
  calculated_on_other_amount,
  debt_to_equity__D___E____,
  net_debt___EBITDA,
  leverage_to_value__LTV____,
  leverage_to_cost__LTC____,
}
export namespace LeverageBasisEnum {
  const replace = [
    { key: /____/g, value: ')' },
    { key: /___/g, value: '/' },
    { key: /__/g, value: ' (' },
    { key: /_/g, value: ' ' },
  ];
  export const toOptions = () =>
    UTILS.UI.enumToOptions(LeverageBasisEnum, replace);
}

export enum ClosedEndCountryEnum {
  Generalist = 1,
  RegionSpecialist,
}
export namespace ClosedEndCountryEnum {
  const replace = [{ key: /([a-z])([A-Z])/g, value: '$1 $2' }];
  export const toOptions = () =>
    UTILS.UI.enumToOptions(ClosedEndCountryEnum, replace);
}

export enum ClosedEndIndustryEnum {
  Generalist = 1,
  IndustrySpecialist,
}
export namespace ClosedEndIndustryEnum {
  const replace = [{ key: /([a-z])([A-Z])/g, value: '$1 $2' }];
  export const toOptions = () =>
    UTILS.UI.enumToOptions(ClosedEndIndustryEnum, replace);
}

export enum ClosedEndStageEnum {
  SeedStage = 1,
  EarlyStage,
  LateStage,
}
export namespace ClosedEndStageEnum {
  const replace = [{ key: /([a-z])([A-Z])/g, value: '$1 $2' }];
  export const toOptions = () =>
    UTILS.UI.enumToOptions(ClosedEndStageEnum, replace);
}

export enum ClosedEndTagsEnum {
  SpecialSituationBalanceSheet = 1,
  SpecialSituationOperations,
  LongDated,
  Impact,
  PledgeFunds,
}
export namespace ClosedEndTagsEnum {
  const replace = [{ key: /([a-z])([A-Z])/g, value: '$1 $2' }];
  export const toOptions = () =>
    UTILS.UI.enumToOptions(ClosedEndTagsEnum, replace);
}

export enum ContractualDateTypeEnum {
  Relative_to_First_Close_Date = 1,
  Relative_to_Effective_Date = 4,
  Fixed_date = 2,
  Relative_to_other_date = 3,
}
export namespace ContractualDateTypeEnum {
  const replace = [
    { key: /_/g, value: ' ' },
    { key: /([a-z])([A-Z])/g, value: '$1 $2' },
  ];
  export const toOptions = () =>
    UTILS.UI.enumToOptions(ContractualDateTypeEnum, replace);
}

export enum MinGPCommitmentEnum {
  Percentage = 1,
  Amount,
  Greater_of,
  Lesser_of,
}
export namespace MinGPCommitmentEnum {
  const replace = [{ key: /_/g, value: ' ' }];
  export const toOptions = () =>
    UTILS.UI.enumToOptions(MinGPCommitmentEnum, replace);
}

export enum RankEnum {
  _1st = 1,
  _2nd,
  _3rd,
  _4th,
  N__A,
}
export namespace RankEnum {
  const replace = [
    { key: /__/g, value: '/' },
    { key: /_/g, value: '' },
  ];
  export const toOptions = (omit?: Array<string | number>) =>
    UTILS.UI.enumToOptions(RankEnum, replace, omit);
}

export enum CommitmentsTypeEnum {
  Interim = 1,
  Final,
}
export namespace CommitmentsTypeEnum {
  export const toOptions = () => UTILS.UI.enumToOptions(CommitmentsTypeEnum);
}

export enum PeriodTypeEnum {
  FollowingInvestmentPeriodEnd = 1,
  FollowingFirstClose = 2,
  FollowingFinalClose = 3,
  FollowingEffectiveDate = 5,
  FollowingOtherDate = 4,
  FollowingLiquidationOfInvestments = 9,
  FollowingFirstDrawdown = 7,
  FollowingFirstInvestment = 8,
  FollowingFeeAccrual = 6,
}
export namespace PeriodTypeEnum {
  const replace = [
    { key: /_/g, value: ' ' },
    { key: /([a-z])([A-Z])/g, value: '$1 $2' },
  ];
  export const toOptions = () =>
    UTILS.UI.enumToOptions(PeriodTypeEnum, replace);
}

export enum SubClInterestIndexEnum {
  Libor = 2,
  Euribor,
  WSJ_prime_rate,
  JP_Morgan_prime_rate,
  Canadian_bank_prime_rate,
  Citibank_prime_rate,
  Bank_of_America_prime_rate,
  Other,
}
export namespace SubClInterestIndexEnum {
  const replace = [
    { key: /_and_/g, value: '&' },
    { key: /Three/g, value: '3' },
    { key: /__/g, value: '-' },
    { key: /_/g, value: ' ' },
  ];
  export const toOptions = () =>
    UTILS.UI.enumToOptions(SubClInterestIndexEnum, replace);
}

export enum MonthEnum {
  January = 1,
  February,
  March,
  April,
  May,
  June,
  July,
  August,
  September,
  October,
  November,
  December,
}
export namespace MonthEnum {
  export const toOptions = () => UTILS.UI.enumToOptions(MonthEnum);
}

export enum AksiaRoleEnum {
  Lead = 1,
  Participant,
}
export namespace AksiaRoleEnum {
  export const toOptions = () => UTILS.UI.enumToOptions(AksiaRoleEnum);
}

export enum IPSizeGroupEnum {
  Micro = 1,
  Small,
  Mid,
  Large,
  Mega,
}
export namespace IPSizeGroupEnum {
  export const toOptions = () => UTILS.UI.enumToOptions(IPSizeGroupEnum);
}

export enum SourceEnum {
  ExistingPCRelationship = 1,
  ExistingHFRelationship,
  PlacementAgent,
  ExternalDatabase,
  InboundGPContact,
  IndustryResearch,
  ConferenceIndustryEvent,
  Other,
  FollowOnFund,
  ExistingGPRelationship,
}

export enum SourceDealEnum {
  Broker = 1,
  GeneralPartner,
  SellingLP,
  Other,
  OtherIntermediary,
}
export namespace SourceDealEnum {
  const replace = [{ key: /([a-z])([A-Z])/g, value: '$1 $2' }];
  export const toOptions = () =>
    UTILS.UI.enumToOptions(SourceDealEnum, replace);
}

export enum RecyclingEnum {
  No = 1,
  Yes_Principal,
  Yes_FullRecycling,
}
export namespace RecyclingEnum {
  const replace = [
    { key: /_/g, value: ' - ' },
    { key: /([a-z])([A-Z])/g, value: '$1 $2' },
  ];
  export const toOptions = () => UTILS.UI.enumToOptions(RecyclingEnum, replace);
}

export enum FeeBaseEnum {
  Committed = 1,
  Invested,
  GrossAssets = 7,
}
export namespace FeeBaseEnum {
  const replace = [{ key: /([a-z])([A-Z])/g, value: '$1 $2' }];
  export const toOptions = () => UTILS.UI.enumToOptions(FeeBaseEnum, replace);
}

export enum PlacementAgentEnum {
  None = 'None',
  AresManagementCapitalMarketsLLC = 'Ares Management Capital Markets LLC',
  AsanteCapital = 'Asante Capital',
  AtlanticPacificCapital = 'Atlantic-Pacific Capital',
  CampbellLutyensAndCo = 'Campbell Lutyens & Co',
  CreditSuissePrivateFundGroup = 'Credit Suisse Private Fund Group',
  EatonPartnersLLC = 'Eaton Partners LLC',
  Evercore = 'Evercore',
  FirstAvenuePartnersLLC = 'First Avenue Partners LLC',
  GallatinCapitalLLC = 'Gallatin Capital LLC',
  GreenhillAndCo = 'Greenhill & Co',
  HodesWeill = 'Hodes Weill',
  LazardPrivateCapitalAdvisory = 'Lazard (Private Capital Advisory)',
  MercuryCapitalAdvisorsLLC = 'Mercury Capital Advisors LLC',
  OakpointAdvisors = 'Oakpoint Advisors',
  ParkHillGroup = 'Park Hill Group',
  ProbitasPartners = 'Probitas Partners',
  RedePartners = 'Rede Partners',
  UBSPrivateFundsGroup = 'UBS Private Funds Group',
  Other = 'Other',
}
export namespace PlacementAgentEnum {
  export const toOptions = () => UTILS.UI.enumToOptions(PlacementAgentEnum);
}

export enum LiquidityTierEnum {
  Tier_1__Liquid___ = 1,
  Tier_2__Borrowing_Capacity___,
  Tier_3__Semi____Liquid___,
  Tier_4__Limitied_Liquidity_but_Some_Secondary_Activity___,
  Tier_5__Illiquid___,
}

export namespace LiquidityTierEnum {
  const replace = [
    { key: /____/g, value: '-' },
    { key: /___/g, value: ')' },
    { key: /__/g, value: ' (' },
    { key: /_/g, value: ' ' },
  ];
  export const toOptions = () =>
    UTILS.UI.enumToOptions(LiquidityTierEnum, replace);
}

export enum EligibilityEnum {
  Onshore = 1,
  Offshore,
  Onshore_and_Offshore,
}
export namespace EligibilityEnum {
  const replace = [{ key: /_and_/g, value: ' & ' }];
  export const toOptions = () =>
    UTILS.UI.enumToOptions(EligibilityEnum, replace);
}

export enum CoInvestPolicyEnum {
  Yes_Fee = 1,
  Yes_NoFee,
  No,
}
export namespace CoInvestPolicyEnum {
  const replace = [
    { key: /_/g, value: ' - ' },
    { key: /([a-z])([A-Z])/g, value: '$1 $2' },
  ];
  export const toOptions = () =>
    UTILS.UI.enumToOptions(CoInvestPolicyEnum, replace);
}
