import { EntityTypeEnum, StreamPeriodicityEnum } from '@aksia/enums';

export type PeriodicStreamRequestOptions = {
  entityId: number;
  entityTypeId: EntityTypeEnum;
  streamTypes: Array<PeriodicStreamType>;
  streamRequest: PeriodicStreamRequest;
  uriPrefix?: string;
  periodicity?: string;
  asOf?: string;
  responseType?: 'set' | 'update';
  fillMissing?: boolean;
};

export const PERIODIC_STREAMS = {
  GROSSIRR: 'grossIRR',
  NETIRR: 'netIRR',
  GROSSMOIC: 'grossMOIC',
  NETMOIC: 'netMOIC',
  NETDPI: 'netDPI',
  NETRVPI: 'netRVPI',
  INVESTED_CAPITAL: 'investedCapital',
  PRIVATE_RETURNS_EXPAND:
    'grossIRR,netIRR,grossMOIC,netMOIC,netDPI,netRVPI,investedCapital',
  PRIVATE_RETURNS: 'privateReturns',
  PUBLIC_RETURNS: 'publicReturns',
  AUM_EXPAND: 'companyAum,programAum,fundAum',
  COMPANY_AUM: 'companyAum',
  PROGRAM_AUM: 'programAum',
  FUND_AUM: 'fundAum',
} as const;

export type PeriodicStreamType =
  (typeof PERIODIC_STREAMS)[keyof typeof PERIODIC_STREAMS];

export const PERIODIC_STREAM_REQUESTS = {
  MINMAX: 'MINMAX',
  HISTORICAL: 'HISTORICAL',
  ASOF: 'ASOF',
  UPDATE: 'UPDATE',
} as const;

export type PeriodicStreamRequest =
  (typeof PERIODIC_STREAM_REQUESTS)[keyof typeof PERIODIC_STREAM_REQUESTS];

export const PERIODIC_STREAM_URIS = {
  COMPANY_AUM: {
    MINMAX: 'aum/{uriPrefix}/{entityId}/1',
    HISTORICAL: 'aum/mergeStream/{entityId}/1',
    ASOF: 'aum/mergeStream/{entityId}/1',
    UPDATE: '',
  },
  PROGRAM_AUM: {
    MINMAX: 'aum/{uriPrefix}/{entityId}/2',
    HISTORICAL: 'aum/mergeStream/{entityId}/2',
    ASOF: 'aum/mergeStream/{entityId}/2',
    UPDATE: '',
  },
  FUND_AUM: {
    MINMAX: 'aum/{uriPrefix}/{entityId}/3',
    HISTORICAL: 'aum/mergeStream/{entityId}/3',
    ASOF: 'aum/mergeStream/{entityId}/3',
    UPDATE: '',
  },
  PUBLIC_RETURNS: {
    MINMAX:
      'periodicdata/publicreturns/actual/2@{entityId}_{entityTypeId}/stream/rawwithytd',
    HISTORICAL:
      'periodicdata/publicreturns/actual/2@{entityId}_{entityTypeId}/stream/rawwithytd',
    ASOF: 'periodicdata/publicreturns/actual/2@{entityId}_{entityTypeId}/stream/rawwithytd',
    UPDATE: '',
  },
  PRIVATE_RETURNS: {
    MINMAX: 'periodicdata/allreturnprofilestreams/{entityId}/stream/asofrange',
    HISTORICAL: 'periodicdata/returnprofile/{streamType}/{entityId}',
    ASOF: 'periodicdata/allreturnprofilestreams/{entityId}/stream/datapoint?asOf={asOf}',
    UPDATE:
      'periodicdata/{streamType}/{entityId}/stream/edit?periodicity={periodicity}',
  },
  getURI: (options: {
    entityId: number;
    entityTypeId: EntityTypeEnum;
    streamType: PeriodicStreamType;
    request: PeriodicStreamRequest;
    uriPrefix?: string;
    periodiciy?: string;
    asOf?: string;
  }) => {
    let uri;
    switch (options.streamType) {
      case PERIODIC_STREAMS.COMPANY_AUM:
        uri = PERIODIC_STREAM_URIS.COMPANY_AUM[options.request];
        break;
      case PERIODIC_STREAMS.PROGRAM_AUM:
        uri = PERIODIC_STREAM_URIS.PROGRAM_AUM[options.request];
        break;
      case PERIODIC_STREAMS.FUND_AUM:
        uri = PERIODIC_STREAM_URIS.FUND_AUM[options.request];
        break;
      case PERIODIC_STREAMS.PUBLIC_RETURNS:
        uri = PERIODIC_STREAM_URIS.PUBLIC_RETURNS[options.request];
        break;
      default:
        uri = PERIODIC_STREAM_URIS.PRIVATE_RETURNS[options.request];
        break;
    }
    uri = uri
      ?.replace('{entityId}', options.entityId.toString())
      ?.replace('{entityTypeId}', options.entityTypeId.toString())
      ?.replace('{streamType}', options.streamType)
      ?.replace('{uriPrefix}', options.uriPrefix ?? '')
      ?.replace('{periodicity}', options.periodiciy ?? '')
      ?.replace('{asOf}', options.asOf ?? '');
    return uri;
  },
};
