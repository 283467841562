import { SimpleAnswerEnum } from '../../enums/entity.enum';
import {
  DealDynamicEnum,
  DealTypeSecondaryEnum,
} from '../../enums/secondary.enum';
import { AksiaRoleEnum, SourceDealEnum } from '../../enums/closedEnd.enum';
import { SecondaryAsset } from '../entities/entities.model';

export class SecondaryDetails {
  id?: number = undefined;
  fundId?: number = undefined;

  //#region Historical Info

  fundNameAsset?: string = undefined;

  //#endregion

  //#region Decision Dates

  bidsOutstanding?: SimpleAnswerEnum;
  targetICDate?: Date;

  //#endregion

  //#region Categorization Factors

  dealType?: DealTypeSecondaryEnum = undefined;
  //carryReset?: SimpleAnswerEnum = undefined;
  sourceDeal?: SourceDealEnum = undefined;
  sourceContact?: string;
  aksiaRole?: AksiaRoleEnum = undefined;
  dealDynamic?: DealDynamicEnum = undefined;

  //#endregion

  //#region Key Terms

  firstRoundBidDate?: Date;
  finalBidDate?: Date;
  expectedDealClose?: Date;
  finalTransactionPrice?: number;
  aksiaProposedPriceRange?: string;
  managementCompanyName?: string;
  fundsCompany?: string;
  discountGuidance?: string;
  actionItems?: string;

  //#endregion

  //#region Assets

  assets?: Array<SecondaryAsset>;

  //#endregion

  //#region GP Led Details

  subsequentFundsInvesting?: SimpleAnswerEnum;

  //#endregion
}
