import { DragStateType, DRAGSTATES } from '@aksia/infrastructure';
import {
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  inject,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';

@Directive({
  selector: '[draggable]',
  exportAs: 'draggable',
  standalone: true,
})
export class DraggableDirective {
  el = inject(ElementRef);

  @HostBinding('draggable')
  @Input()
  draggable: boolean = true;
  @Input() drag!: string;

  @Output() DragStarted: EventEmitter<{ e: DragEvent; drag: string }> =
    new EventEmitter();
  @Output() DragEnded: EventEmitter<{ e: DragEvent; drag: string }> =
    new EventEmitter();

  draggableState: DragStateType = DRAGSTATES.IDLE;

  //#region Events

  @HostListener('dragstart', ['$event'])
  dragstart(event: DragEvent) {
    this.draggableState = DRAGSTATES.DRAGGING;
    this.DragStarted.emit({ e: event, drag: this.drag });
  }

  @HostListener('dragend', ['$event'])
  dragend(event: DragEvent) {
    this.draggableState = DRAGSTATES.DROPPED;
    this.DragEnded.emit({ e: event, drag: this.drag });
    this.draggableState = DRAGSTATES.IDLE;
  }

  //#endregion
}
