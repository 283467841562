import { ENVIRONMENT } from '@aksia/infrastructure';
import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, catchError, of, take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  //#region Injections

  private environment = inject(ENVIRONMENT);
  private httpClient = inject(HttpClient);

  //#endregion

  //#region Properties

  apiLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  //#endregion

  constructor() {
    this.httpClient
      .jsonp(
        `${this.environment.google_api_url}&key=${this.environment.google_api_key}`,
        'callback',
      )
      .pipe(
        take(1),
        catchError(() => of(false)),
      )
      .subscribe((result) => {
        this.apiLoaded$.next(true);
      });
  }
}
