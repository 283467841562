import { Injectable, inject } from "@angular/core";
import { ApiService } from "../../infrastructure/api/api.service";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class EntityService {
    private api = inject(ApiService);

    private _apiURL: string = '';
    public get apiURL() {
        return this._apiURL.endsWith('/') ? this._apiURL : `${this._apiURL}/`;
    }
    public set apiURL(value: string) {
        this._apiURL = value;
    }

    public createEntity<T>(entity: unknown, apiURI: string): Observable<T> {
        return this.api.post(`${apiURI ?? this.apiURL}`, entity) as Observable<T>;
    }

    public createEntities<T>(entities: Array<unknown>, apiURI: string): Observable<T> {
        throw new Error("Method not implemented.");
    }

    public getEntityById<T>(id: number, apiURI: string): Observable<T> {
        return this.api.get(`${apiURI ?? this.apiURL}${id}`) as Observable<T>;
    }

    public getEntitiesByParentId<T>(parentId: number, apiURI: string): Observable<T> {
        return this.api.get(`${apiURI ?? this.apiURL}${parentId}`) as Observable<T>;
    }

    public updateEntity<T>(entity: unknown, apiURI: string): Observable<T> {
        return this.api.put(`${apiURI ?? this.apiURL}`, entity) as Observable<T>;
    }

    public updateEntities<T>(entities: Array<unknown>, apiURI: string): Observable<T> {
        throw new Error("Method not implemented.");
    }

    public deleteEntityById<T>(deleteId: number, apiURI: string): Observable<T> {
        return this.api.delete(`${apiURI ?? this.apiURL}${deleteId}`) as Observable<T>
    }

    public deleteEntities<T>(deleteIds: Array<number>): Observable<T> {
        throw new Error("Method not implemented.");
    }

}