import { StateEnum } from '../enums';
import { UTILS } from '../functions/utils';

export class StoreModel {
  $uid?: number;
  $tags?: Set<string> = new Set();
  $attributes?: Map<string, unknown> = new Map();
  $state?: StateEnum = StateEnum.Pristine;
  $isDeleted?: boolean = false;

  constructor() {
    this.$uid = UTILS.GENERATE.nextuid;
  }
}
