import {
  ChangeDetectorRef,
  EnvironmentInjector,
  Injectable,
  ViewContainerRef,
  createComponent,
  inject,
} from '@angular/core';
import { ModalComponent } from './modal.component';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  public viewRef?: ViewContainerRef;
  public injector?: EnvironmentInjector;

  public async openModal(
    label: string,
    text: string,
    hasBackrop: boolean = true,
  ) {
    let modal = this.viewRef?.createComponent(ModalComponent);

    if (modal) {
      modal.instance.ModalIsClosed.subscribe(() => {
        modal?.destroy();
      });
      modal.instance.open(label, text, hasBackrop);
    }
  }
}
