import { UTILS } from "../functions/utils";

export const DESIGNER_MODES = {
  DESIGN: 'Design',
  PREVIEW: 'Preview',
  SCHEMA: 'Schema',
  toKeyValue: UTILS.KEYVALUE.toKeyValue,
} as const;

export type DesignerModeType =
  (typeof DESIGNER_MODES)[keyof typeof DESIGNER_MODES];

export const SCHEMA_MODES = {
  WITH_DROPZONES: 'With Dropzones',
  WITHOUT_DROPZONES: 'Without Dropzones',
  toKeyValue: UTILS.KEYVALUE.toKeyValue,
} as const;

export type SchemaModeType = (typeof SCHEMA_MODES)[keyof typeof SCHEMA_MODES];

export const CREATOR_MODES = {
  DESIGN: 'Design',
  VIEW: 'View',
} as const;

export type CreatorModeType =
  (typeof CREATOR_MODES)[keyof typeof CREATOR_MODES];
