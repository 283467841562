import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  input,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChipSettings, IChipSettings } from '@aksia/infrastructure';

@Component({
    selector: 'chip',
    imports: [CommonModule],
    templateUrl: './chip.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChipComponent {
  //#region Inputs/Outputs

  settings = input(new ChipSettings(), {
    transform: (value: IChipSettings) =>
      Object.assign(new ChipSettings(), value),
  });

  //#endregion
}
