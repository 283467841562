import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
  WritableSignal,
  input,
  signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from '../../presentation/icon/icon.component';
import { LabelComponent } from '../../presentation/label/label.component';
import { UiInputDirective } from '../../../directives/ui/ui-input.directive';
import {
  FILE_UPLOAD_UI_TYPE,
  FileUploadSettings,
  FileUploadUiType,
  IFileUploadSettings,
  UTILS,
} from '@aksia/infrastructure';

@Component({
    selector: 'file-upload',
    imports: [CommonModule, IconComponent, LabelComponent],
    templateUrl: './file-upload.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileUploadComponent extends UiInputDirective {
  //#region Inputs/Outputs

  override settings = input<FileUploadSettings, IFileUploadSettings>(
    new FileUploadSettings(),
    {
      transform: (settings: IFileUploadSettings) => this.initSettings(settings),
    },
  );

  @Output() OnFileUpload: EventEmitter<File | FileList> = new EventEmitter();

  //#endregion

  //#region Properties

  uiType: WritableSignal<FileUploadUiType> = signal(FILE_UPLOAD_UI_TYPE.Button);
  allowMultipleFiles: WritableSignal<boolean> = signal(false);
  allowedFileTypes: WritableSignal<string> = signal(
    FileUploadSettings.defaultAllowedFileTypes,
  );

  //#endregion

  //#region Functions

  protected override initSettings(settings: IFileUploadSettings) {
    let fileUploadSettings = UTILS.OBJECT.assign(
      new FileUploadSettings(),
      settings,
    );
    super.initSettings(fileUploadSettings);
    this.uiType.set(fileUploadSettings.uiType!);
    this.allowMultipleFiles.set(fileUploadSettings.allowMultipleFiles!);
    this.allowedFileTypes.set(fileUploadSettings.allowedFileTypes!);
    this.defaultIcon.set(fileUploadSettings.defaultIcon);
    return fileUploadSettings;
  }

  protected onFileSelected(event: any) {
    const files: FileList = event.target!.files;

    if (files?.length > 0) {
      this.value.set(
        Array.from(files)
          .map((file) => file.name)
          .join(','),
      );
      if (this.allowMultipleFiles()) {
        this.OnFileUpload.emit(files);
      } else {
        this.OnFileUpload.emit(files.item(0)!);
      }
    }
  }

  //#endregion
}
