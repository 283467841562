import { UTILS } from '@aksia/infrastructure';

export enum LockUpEnum {
  Hard = 1,
  Soft,
}
export namespace LockUpEnum {
  export const toOptions = () => UTILS.UI.enumToOptions(LockUpEnum);
}

export enum CalendarUnitEnum {
  Years = 1,
  Semesters = 8,
  Quarters = 2,
  Months = 3,
  Weeks = 4,
  Days = 5,
  Custom = 6,
}
export namespace CalendarUnitEnum {
  export const toOptions = () => UTILS.UI.enumToOptions(CalendarUnitEnum);
}

export enum ActiveTimePeriodEnum {
  CalendarDays = 1,
  BusinessDays,
}
export namespace ActiveTimePeriodEnum {
  const replace = [{ key: /([a-z])([A-Z])/g, value: '$1 $2' }];
  export const toOptions = () =>
    UTILS.UI.enumToOptions(ActiveTimePeriodEnum, replace);
}

export enum MgmtFeeRateTypeEnum {
  SingleRate = 1,
  BlendedRate,
}
export namespace MgmtFeeRateTypeEnum {
  const replace = [{ key: /([a-z])([A-Z])/g, value: '$1 $2' }];
  export const toOptions = () =>
    UTILS.UI.enumToOptions(MgmtFeeRateTypeEnum, replace);
}

export enum IncFeeRateTypeEnum {
  SingleRate = 1,
  TieredRate,
  SlidingScaleRate,
}
export namespace IncFeeRateTypeEnum {
  const replace = [{ key: /([a-z])([A-Z])/g, value: '$1 $2' }];
  export const toOptions = () =>
    UTILS.UI.enumToOptions(IncFeeRateTypeEnum, replace);
}

export enum HighWaterMarkEnum {
  StandardHWM = 1,
  Non_StandardHWM,
  NoHWM,
}
export namespace HighWaterMarkEnum {
  const replace = [{ key: /([a-z])([A-Z])/g, value: '$1 $2' }];
  export const toOptions = () =>
    UTILS.UI.enumToOptions(HighWaterMarkEnum, replace);
}

export enum RedemptionDayEnum {
  FirstDay = 'First Day',
  LastDay = 'Last Day',
  FirstDayFollowingAnniversary = 'First Day Following Anniversary',
  LastDayFollowingAnniversary = 'Last Day Following Anniversary',
  SubscriptionDay = 'Subscription Day',
}
export namespace RedemptionDayEnum {
  const replace = [{ key: /([a-z])([A-Z])/g, value: '$1 $2' }];
  export const toOptions = () =>
    UTILS.UI.enumToOptions(RedemptionDayEnum, replace);
}

export enum HurdleIndexEnum {
  MSCI = 1,
  Libor,
  Euribor,
  WSJ_prime_rate,
  JP_Morgan_prime_rate,
  Canadian_bank_prime_rate,
  S_and_P_500,
  Three__month_T__bill_rate,
  Russell_2000,
  FDTR,
  Other,
}
export namespace HurdleIndexEnum {
  const replace = [
    { key: /_and_/g, value: '&' },
    { key: /Three/g, value: '3' },
    { key: /__/g, value: '-' },
    { key: /_/g, value: ' ' },
  ];
  export const toOptions = () =>
    UTILS.UI.enumToOptions(HurdleIndexEnum, replace);
}
