import {
  computed,
  Directive,
  effect,
  ElementRef,
  EventEmitter,
  HostBinding,
  inject,
  input,
  model,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { UiControlDirective } from './ui-control.directive';
import {
  IUiInputSettings,
  PERMISSIONS,
  UiInputSettings,
  UTILS,
} from '@aksia/infrastructure';
import { UiValidationDirective } from './ui-validation.directive';

@Directive({
  selector: 'uiinput',
  standalone: true,
  hostDirectives: [
    {
      directive: UiValidationDirective,
      inputs: [
        'validators',
        'validation.id',
        'validation.tag',
        'validation.chain',
      ],
    },
  ],
})
export class UiInputDirective<T = unknown> extends UiControlDirective {
  //#region Injections

  private readonly uiValidationDir = inject(UiValidationDirective);

  //#endregion

  //#region Inputs/Ouputs

  override settings = input<UiInputSettings, IUiInputSettings>(
    new UiInputSettings(),
    {
      transform: (settings: IUiInputSettings) => {
        return this.initSettings(settings);
      },
    },
  );

  value = model<T | undefined>();

  initialValue = input(undefined, {
    transform: (value: T | undefined) => {
      if (Array.isArray(value) && value?.length === 0) {
        value = undefined;
      }
      if (
        Array.isArray(value) &&
        !UTILS.ARRAY.compareArrayByJSON(this.value() as Array<unknown>, value)
      ) {
        this.value.set(value);
      } else if (this.value() !== value) {
        this.value.set(value);
      }
      return value;
    },
  });

  inputCssClass = input<string | undefined>();

  /**
   * @deprecated Use valueChange instead
   */
  @Output() ValueChanged: EventEmitter<unknown> = new EventEmitter<unknown>();

  //#endregion

  //#region Host Bindings

  @HostBinding('attr.uiinput')
  uiInput = '';

  @HostBinding('attr.uistate') override get uiState() {
    return this.uiValidationDir.calculatedState();
  }

  //#endregion

  //#region View Children

  @ViewChild('uiValueRef') uiValueRef!: ElementRef;
  @ViewChild('uiToolsMarkupRef') uiToolRef!: ElementRef;
  @ViewChild('uiToolsCodeRef', { read: ViewContainerRef })
  uiToolCodeRef!: ViewContainerRef;
  @ViewChild('uiModalsCodeRef', { read: ViewContainerRef })
  uiModalsCodeRef!: ViewContainerRef;

  //#endregion

  //#region Properties

  protected formattedValue = computed(() => {
    return this.valueFormatter(this.value());
  });

  protected labelFormatted = computed(() =>
    (!UTILS.OBJECT.isNil(this.value()) || this.isFocused()) &&
    !UTILS.OBJECT.isNil(this.labelFocused())
      ? this.labelFocused()
      : this.label(),
  );

  protected labelUp = computed<true | undefined>(() =>
    !UTILS.OBJECT.isNil(this.value()) || this.isFocused() ? true : undefined,
  );

  protected placeholder = computed(() =>
    (this.isFocused() && !this.value()) || this.settings().placeholderPersists
      ? this.settings()?.placeholder
      : '',
  );

  errors = computed(() => this.uiValidationDir.errors());
  warnings = computed(() => this.uiValidationDir.warnings());

  //#endregion

  //#region Functions

  constructor() {
    super();
    effect(() => this.uiValidationDir.updateValidationValue(this.value()));
    effect(() =>
      this.uiValidationDir.updateValidationTag(
        this.settings().tag ?? this.settings().label,
      ),
    );
  }

  public reset() {
    this.updateValue(undefined);
  }

  public updateValue(newValue: T | undefined, ...args: unknown[]) {
    if (this.permDir.uipermission() !== PERMISSIONS.VIEW) {
      if (this.value() !== newValue) {
        this.value.update(() => newValue);
        if (
          !this.settings().stateless &&
          this.uiValidationDir.validators()?.length > 0
        ) {
          let store = this.uiValidationDir.stateManager as unknown as any;
          if (store) {
            store.updateById(this.uiValidationDir.modelValidationId());
            this.uiValidationDir.validate(this.value());
          }
        }

        if (this.ValueChanged.observed) {
          this.ValueChanged.emit(this.value());
        }
      }
    }
  }

  protected valueFormatter(value: unknown) {
    return UTILS.OBJECT.isNil(this.value()) ? '' : this.value();
  }

  protected override focusin() {
    super.focusin();
    this.uiValueRef?.nativeElement?.focus();
  }

  protected override focusout() {
    super.focusout();
    this.uiValueRef?.nativeElement?.blur();
  }

  //#endregion
}
