import { AuthenticationStepEnum } from '../enums/security.enums';
import { ICedricUser, ISecurityGroup } from '../interfaces/security.interfaces';

export class Credentials {
  maxusername!: string;
  maxpassword!: string;
  IPVerificationCode?: number;
  step: AuthenticationStepEnum = AuthenticationStepEnum.NotAuthenticated;
}

export class CedricUser implements ICedricUser {
  userId: number;
  email?: string | undefined;
  securityGroups?: ISecurityGroup[] | undefined;
  userName: string;
  fullName: string;

  constructor(user: ICedricUser) {
    this.userId = user.userId;
    this.email = user.email;
    this.securityGroups = user.securityGroups;
    this.userName = user.userName;
    this.fullName = user.fullName;
  }
}
