import {
  AUTHENTICATION_SERVICE_TOKEN,
  PermissionType,
  LAYOUT_SERVICE_TOKEN,
  ILayoutService,
  TViewItem,
  TViewMap,
} from '@aksia/infrastructure';
import {
  AfterViewInit,
  Directive,
  effect,
  ElementRef,
  HostBinding,
  inject,
  model,
  signal,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Directive({
  selector: '[uipermission]',
  standalone: true,
})
export class UiPermissionDirective implements AfterViewInit {
  //#region Injections

  private route = inject(ActivatedRoute);
  public auth = inject(AUTHENTICATION_SERVICE_TOKEN);
  public hostElRef = inject(ElementRef);

  //#endregion

  //#region Host Bindings

  @HostBinding('attr.uipermission') get uiPermission() {
    return this.uipermission();
  }

  //#endregion

  //#region Inputs/Outputs

  uipermission = model<PermissionType>();

  //#endregion

  //#region Properties

  tag = signal<string | undefined>(undefined);
  layout?: ILayoutService<
    unknown,
    unknown,
    TViewItem<unknown>,
    TViewMap<unknown, unknown>
  >;

  //#endregion

  constructor() {
    this.layout = inject(
      this.route.snapshot.data['layoutService'] ?? LAYOUT_SERVICE_TOKEN,
    );
    if (this.layout) {
      effect(() => {
        if (
          this.tag() &&
          this.layout?.selectedView() &&
          this.layout?.permissions()?.length! > 0 &&
          this.layout?.selectedViewItems()?.length! > 0
        ) {
          let control = this.hostElRef.nativeElement?.tagName?.toLowerCase();
          let permission = this.layout?.getPermissionFor(
            control,
            this.tag()!,
          ) as PermissionType;

          this.uipermission.set(permission);
        }
      });
    }
  }

  ngAfterViewInit(): void {
    this.tag.set(this.hostElRef.nativeElement?.getAttribute('uitag'));
  }
}
