import {
  computed,
  Directive,
  effect,
  ElementRef,
  HostBinding,
  inject,
  viewChildren,
} from '@angular/core';
import { UiControlDirective } from './ui-control.directive';
import { LayoutService } from '@aksia/services';

@Directive({
  selector: '[uianchor]',
  standalone: true,
})
export class UiAnchorDirective extends UiControlDirective {
  //#region Injections

  private readonly host = inject(ElementRef);
  protected readonly layout = inject(LayoutService);

  //protected readonly specificationSvc = inject(SpecificationService);

  //#endregion

  //#region Host Bindings

  @HostBinding('attr.uianchor')
  uianchor = '';

  //#endregion

  //#region View Sections

  anchorHost = computed(() => this.host);
  anchorElements = viewChildren('anchor', { read: ElementRef });

  //#endregion

  //#region Constructor

  constructor() {
    super();
    effect(() => {
      if (this.anchorHost() && this.anchorElements()?.length > 0) {
        this.layout.setupAnchors({
          host: this.anchorHost,
          elements: this.anchorElements,
        });
      }
    });
  }

  //#endregion
}
