import { WritableSignal, signal } from '@angular/core';

export interface IDebugData {
  simpleString?: string;
  multiString?: string;
  simpleNumber?: number;
  simpleDate?: Date | string;
  simpleMonth?: Date | string;
  simpleBoolean?: boolean;
  simpleDropdownValue?: string | number;
  simpleDropdownObject?: IDebugDataOption;
  multiDropdownValues?: Array<number>;
  multiDropdownObjects?: Array<IDebugDataOption>;
}

export class DebugData implements IDebugData {
  simpleString?: string = undefined;
  multiString?: string = undefined;
  simpleNumber?: number = undefined;
  simpleDate?: Date | string = undefined;
  simpleMonth?: Date | string = undefined;
  simpleBoolean?: boolean = undefined;
  simpleDropdownValue?: string | number = undefined;
  simpleDropdownObject?: DebugDataOption = undefined;
  multiDropdownValues?: Array<number> = undefined;
  multiDropdownObjects?: Array<DebugDataOption> = undefined;
}

export interface IDebugDataOption {
  label?: string;
  value?: string | number;
}

export class DebugDataOption implements IDebugDataOption {
  label?: string = undefined;
  value?: string | number = undefined;
}
