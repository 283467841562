import { Pipe, PipeTransform } from '@angular/core';
import { UTILS, currencyExp } from '@aksia/infrastructure';

@Pipe({
  name: 'numberFormat',
  standalone: true,
})
export class NumberFormatPipe implements PipeTransform {
  transform(
    value: unknown,
    inline: boolean = false,
    prefix: string = '',
    suffix: string = '',
    abbr: boolean = false,
    abbrSmall: boolean = false,
    decimalAmount: number = 3,
    thousandsSeparator: string = ',',
    percentAsIs: boolean = false,
  ): string {
    if (
      value === null ||
      value === undefined ||
      value === '' ||
      value === '-'
    ) {
      return '';
    }

    let svalue = percentAsIs ? (+value * 100).toString() : value.toString();

    if (!isNaN(Number(svalue))) {
      let sign = '';
      let formatted = '';

      if (svalue.startsWith('-')) {
        sign = '-';
      }

      if (inline) {
        formatted = svalue;
      } else if (abbr) {
        formatted = UTILS.FORMAT.toAbbr(
          Number(svalue),
          abbrSmall,
          decimalAmount,
          thousandsSeparator,
        );
      } else {
        const decimals = svalue.indexOf('.') == -1 ? 0 : decimalAmount;
        if (decimals) {
          formatted = Number(svalue)
            .toFixed(decimals)
            .toString()
            .replace(/\.?0+$/, '')
            .replace(currencyExp, thousandsSeparator); // Format: #,###,###.####
        } else {
          formatted = Number(svalue)
            .toFixed(decimals)
            .toString()
            .replace(currencyExp, thousandsSeparator); // Format: #,###,###.####
        }
      }
      return inline ? `${formatted}` : `${prefix}${formatted}${suffix}`;
    } else return '';
  }
}
