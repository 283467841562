import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, of } from 'rxjs';
import { ApiService } from '../../infrastructure/api/api.service';
import { DropdownSettings, IDropdownSettings } from '@aksia/infrastructure';
import { ISearchEntityResponse } from '@aksia/models';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  public api = inject(ApiService);

  public lastQuery?: string;
  public searchSettings: IDropdownSettings = Object.assign(
    new DropdownSettings(),
    {
      key: 'entityId',
      value: 'name',
      searchPlaceholder: 'Search for Funds and Companies',
      placeholderPersists: true,
      filterBy: 'name',
      filterStrategy: 'async_inline',
      filterTriggerStrategy: 3,
      popupHideStrategy: 'click_outside',
      selectedOptionStrategy: 'marked',
    },
  );

  private _searchResults$?: BehaviorSubject<ISearchEntityResponse>;

  public get searchResults() {
    return this._searchResults$?.value!;
  }

  public set searchResults(value: ISearchEntityResponse) {
    if (!this._searchResults$) {
      this._searchResults$ = new BehaviorSubject(value);
    } else {
      this._searchResults$.next(value);
    }
  }

  public searchEntities = (query?: string) => {
    if (!query) return of([]);
    return this.api.post('basicdata/search/main', { Query: query }).pipe(
      map((data) => {
        return data
          ? [
              ...data.funds,
              ...data.investmentPrograms,
              ...data.managementCompanies,
            ]
          : [];
      }),
    ) as Observable<Array<unknown>>;
  };
}
