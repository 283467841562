import { DROPSTATES, DropStateType } from '@aksia/infrastructure';
import {
  Directive,
  EventEmitter,
  Host,
  HostBinding,
  HostListener,
  Input,
  Output,
} from '@angular/core';

@Directive({
  selector: '[dropzone]',
  exportAs: 'dropzone',
  standalone: true,
})
export class DropzoneDirective {
  @Input() dropzone: boolean = true;
  @Input() dropAllowed?: Array<string>;

  _dragged?: string;
  @Input()
  get dragged() {
    return this._dragged;
  }
  set dragged(value: string | undefined) {
    this._dragged = value;
    if (!this.dragged && !this.dropped) {
      this.dropzoneState = DROPSTATES.IDLE;
    }
  }

  @HostBinding('[attr.dropped]')
  @Input()
  dropped?: string;

  @HostBinding('[attr.dropzone]')
  @HostBinding('[attr.status]')
  dropzoneState: DropStateType = DROPSTATES.IDLE;
  @HostBinding('class.hovered') hovered: boolean = false;

  @Output() Clicked = new EventEmitter();
  @Output() DraggedOver = new EventEmitter();
  @Output() DragLeft = new EventEmitter();

  @HostListener('click', ['$event'])
  click(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
    this.Clicked.emit(event);
  }

  @HostListener('mouseover', ['$event'])
  mouseover(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
    this.hovered = true;
  }

  @HostListener('mouseout', ['$event'])
  mouseout(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
    this.hovered = false;
  }

  @HostListener('keydown.delete', ['$event'])
  deletePressed(event: KeyboardEvent) {
    event.stopPropagation();
    event.preventDefault();
  }

  @HostListener('dragenter', ['$event'])
  dragenter(event: DragEvent) {
    event.preventDefault();
  }

  @HostListener('dragleave', ['$event'])
  dragleave(event: DragEvent) {
    event.preventDefault();
    this.dropzoneState = DROPSTATES.IDLE;
    this.DragLeft.emit();
  }

  @HostListener('dragover', ['$event'])
  dragover(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.dropzoneState = DROPSTATES.ALLOWED;
    if (
      this.dragged &&
      this.dropAllowed?.length! > 0 &&
      !this.dropAllowed?.includes(this.dragged)
    ) {
      this.dropzoneState = DROPSTATES.NOTALLOWED;
    }

    this.DraggedOver.emit(this.dropzoneState);
  }
}
