import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Injectable,
  WritableSignal,
  inject,
  signal,
} from '@angular/core';
import { SectionComponent } from '../../controls/layout/section/section.component';
import { DropdownComponent } from '../../controls/input/dropdown/dropdown.component';
import { MultiDropdownComponent } from '../../controls/input/multi-dropdown/multi-dropdown.component';
import { DateComponent } from '../../controls/input/date/date.component';
import { TextComponent } from '../../controls/input/text/text.component';
import { SearchEntity } from '@aksia/models';
import { ICONS, UTILS, DeepSignal } from '@aksia/infrastructure';
import { ButtonComponent } from '../../controls/input/button/button.component';
import { ChipComponent } from '../../controls/presentation/chip/chip.component';
import { IconComponent } from '../../controls/presentation/icon/icon.component';

import { dataDTO, dataEnum, dataOptionsDTO } from './view-sampler.data';
import { MockService } from '@aksia/services';
import {
  DebugData,
  DebugDataOption,
  IDebugData,
} from './view-sampler.interface';
import { Observable, of } from 'rxjs';
import { SectionGroupComponent } from '../../controls/layout/section-group/section-group.component';
import { NumberComponent } from '../../controls/input/number/number.component';
import { CheckComponent } from '../../controls/input/check/check.component';
import { SpreadsheetComponent } from '../../controls/input/spreadsheet/spreadsheet.component';
import { RichTextComponent } from '../../controls/input/rich-text/rich-text.component';

@Injectable({
  providedIn: 'root',
})
export class ViewSamplerService {
  public useDeepSignal = signal(false);
  public pageSize = signal(1000);
  public data = signal<any>(undefined);
  public dataValueOptions = signal<any>(undefined);
  public dataObjectOptions = signal<any>(undefined);
  public dataValuesMultiOptions = signal<any>(undefined);
  public dataObjectsMultiOptions = signal<any>(undefined);
  public dataCollection = signal<any>(undefined);

  public printed: WritableSignal<string> = signal('');

  public loadData() {
    if (this.useDeepSignal()) {
      this.data.set(
        UTILS.SIGNALS.toDeepSignal<DebugData>(dataDTO, DebugData)(),
      );

      this.dataValueOptions.set(
        UTILS.SIGNALS.toDeepSignal<Array<DebugDataOption>>(
          dataOptionsDTO,
          DebugDataOption,
        )(),
      );

      this.dataObjectOptions.set(
        UTILS.SIGNALS.toDeepSignal<Array<DebugDataOption>>(
          dataOptionsDTO,
          DebugDataOption,
        )(),
      );

      this.dataValuesMultiOptions.set(
        UTILS.SIGNALS.toDeepSignal<Array<DebugDataOption>>(
          dataOptionsDTO,
          DebugDataOption,
        )(),
      );

      this.dataObjectsMultiOptions.set(
        UTILS.SIGNALS.toDeepSignal<Array<DebugDataOption>>(
          dataOptionsDTO,
          DebugDataOption,
        )(),
      );
    } else {
      this.data.set(Object.assign(new DebugData(), dataDTO));

      this.dataValueOptions.set(
        dataOptionsDTO.map((option) =>
          Object.assign(new DebugDataOption(), option),
        ),
      );

      this.dataObjectOptions.set(
        dataOptionsDTO.map((option) =>
          Object.assign(new DebugDataOption(), option),
        ),
      );
      this.dataValuesMultiOptions.set(
        dataOptionsDTO.map((option) =>
          Object.assign(new DebugDataOption(), option),
        ),
      );
      this.dataObjectsMultiOptions.set(
        dataOptionsDTO.map((option) =>
          Object.assign(new DebugDataOption(), option),
        ),
      );
    }

    //this.generateCollection();
  }

  public printDataClass() {
    let debugData = this.useDeepSignal()
      ? UTILS.SIGNALS.toPlain<DebugData>(this.data() as DeepSignal<DebugData>)
      : this.data();
    this.printed.set(JSON.stringify(debugData, null, 2));
  }

  public generateCollection() {
    this.dataCollection.set(
      UTILS.SIGNALS.toDeepSignalArray<IDebugData>(
        Array.from({ length: this.pageSize() }, (_, i) => dataDTO),
      )(),
    );
  }
}

@Component({
  selector: 'page-view-sampler',
  imports: [CommonModule],
  template: `
    <!-- <section
      [settings]="{
        label: 'Controls End to End Test',
      }"
    >
      <icon
        uitool
        [iconSize]="'normal'"
        [iconDefinition]="Icons.filecode"
        class="labelInfo copy"
        (click)="viewSamplerService.printDataClass()"
      ></icon>

      <section-group [settings]="{ label: 'Check' }">
        <check
          [settings]="{ label: 'Use Deep Signal' }"
          [(value)]="dataFromClass()!.simpleBoolean"
          (valueChange)="toggleUseDeepSignal($event)"
        ></check>
      </section-group>

      <section-group [settings]="{ label: 'Text' }">
        <text
          class="c1s1"
          [settings]="{ label: 'Simple String' }"
          [(value)]="dataFromClass()!.simpleString"
        ></text>
        <text
          class="c2s1"
          [settings]="{ label: 'Multi String', multiline: true }"
          [(value)]="dataFromClass()!.multiString"
        >
        </text>
      </section-group>

      <section-group [settings]="{ label: 'Number' }">
        <number
          [settings]="{ label: 'Simple Number' }"
          [(value)]="dataFromClass()!.simpleNumber"
        ></number>
      </section-group>

      <section-group [settings]="{ label: 'Date' }">
        <date
          UiInput
          UiStyle="form"
          [settings]="{ label: 'Simple Date' }"
          [(value)]="dataFromClass()!.simpleDate"
        ></date>
      </section-group>

      <section-group [settings]="{ label: 'Dropdown' }">
        <dropdown
          #simpleDropdownValue
          class="c1s1"
          [settings]="{
            label: 'Simple Dropdown Value',
            options: dataValueOptions,
          }"
          [(value)]="dataFromClass()!.simpleDropdownValue"
        >
          <icon
            uitool
            [iconDefinition]="Icons.add"
            [iconSize]="'normal'"
            uitag="Simple Dropdown Value Add"
            (click)="
              addDropdownValueOption(); $event.stopImmediatePropagation()
            "
          ></icon>
          <icon
            uitool
            [iconDefinition]="Icons.remove"
            [iconSize]="'normal'"
            uitag="Simple Dropdown Value Remove"
            (click)="
              removeDropdownValueOption(simpleDropdownValue.selectedOption());
              $event.stopImmediatePropagation()
            "
          ></icon>
        </dropdown>
        <dropdown
          #simpleDropdownObject
          class="c2s1"
          [settings]="{
            label: 'Simple Dropdown Object',
            options: dataObjectOptions,
            storeOnlyValue: false,
          }"
          [(value)]="dataFromClass()!.simpleDropdownObject"
        >
          <icon
            uitool
            [iconDefinition]="Icons.add"
            [iconSize]="'normal'"
            uitag="Simple Dropdown Object Add"
            (click)="
              addDropdownObjectOption(); $event.stopImmediatePropagation()
            "
          ></icon>
          <icon
            uitool
            [iconDefinition]="Icons.remove"
            [iconSize]="'normal'"
            uitag="Simple Dropdown Object Remove"
            (click)="
              removeDropdownObjectOption(simpleDropdownObject.selectedOption());
              $event.stopImmediatePropagation()
            "
          ></icon>
        </dropdown>
        <multi-dropdown
          #multiDropdownValues
          class="c3s1"
          [settings]="{
            label: 'Multi Dropdown Values',
            options: dataValuesMultiOptions,
            shrinkCount: 2,
            storeOnlyValue: true,
          }"
          [(value)]="dataFromClass()!.multiDropdownValues"
        >
          <icon
            uitool
            [iconDefinition]="Icons.add"
            [iconSize]="'normal'"
            uitag="Multi Dropdown Values Add"
            (click)="
              addMultiDropdownValuesOption(); $event.stopImmediatePropagation()
            "
          ></icon>
          <icon
            uitool
            [iconDefinition]="Icons.remove"
            [iconSize]="'normal'"
            uitag="Multi Dropdown Values Remove"
            (click)="
              removeMultiDropdownValuesOption(
                multiDropdownValues.selectedOption()
              );
              $event.stopImmediatePropagation()
            "
          ></icon>
        </multi-dropdown>
        <multi-dropdown
          #multiDropdownObjects
          class="c4s1 w-[340px]"
          [settings]="{
            label: 'Multi Dropdown Objects',
            options: dataObjectsMultiOptions,
            shrinkCount: 2,
            optionsAsyncFn: getAsyncMultiDropdownOptions,
            optionsAsyncMerging: true,
          }"
          [(value)]="dataFromClass()!.multiDropdownObjects"
        >
          <icon
            uitool
            [iconDefinition]="Icons.add"
            [iconSize]="'normal'"
            uitag="Multi Dropdown Objects Add"
            (click)="
              addMultiDropdownObjectsOption(); $event.stopImmediatePropagation()
            "
          ></icon>
          <icon
            uitool
            [iconDefinition]="Icons.remove"
            [iconSize]="'normal'"
            uitag="Multi Dropdown Objects Remove"
            (click)="
              removeMultiDropdownObjectsOption(
                multiDropdownObjects.selectedOption()
              );
              $event.stopImmediatePropagation()
            "
          ></icon>
        </multi-dropdown>
      </section-group>

      <section-group [settings]="{ label: 'Rich Text' }">
        <rich-text></rich-text>
      </section-group>
    </section>

    <section
      [settings]="{
        label: 'Test Multiple for Performance'
      }"
    >
      <number
        UiInput
        UiStyle="form"
        [settings]="{ label: 'Page Size' }"
        [(value)]="pageSize"
        (ValueChanged)="debugService.generateCollection()"
      ></number>
      <br />
      <br />
      @for (item of dataCollection(); track i; let i = $index) {
        <section-group [settings]="{ label: i.toString() }">
          <text
            UiInput
            UiStyle="form"
            [settings]="{ label: 'Simple String' }"
            [(value)]="item!.simpleString"
          ></text>
          <br />
          <br />
          <text
            UiInput
            UiStyle="form"
            [settings]="{ label: 'Multi String', multiline: true }"
            [(value)]="item!.multiString"
          >
          </text>
          <br />
          <br />
          <number
            UiInput
            UiStyle="form"
            [settings]="{ label: 'Simple Number' }"
            [(value)]="item!.simpleNumber"
          ></number>
        </section-group>
      }
    </section> -->
  `,
  styles: [
    `
      :host {
        margin: 5px;
      }
      .center {
        align: center;
      }
      [uisectioncontent] {
        display: grid !important;
        row-gap: 20px !important;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewSamplerPage {
  public readonly viewSamplerService = inject(ViewSamplerService);
  protected readonly mockService = inject(MockService<SearchEntity>);
  protected readonly Icons = ICONS;
  protected readonly Enums = dataEnum;

  protected pageSize = this.viewSamplerService.pageSize;
  protected dataFromClass = this.viewSamplerService.data;
  protected dataValueOptions = this.viewSamplerService.dataValueOptions;
  protected dataObjectOptions = this.viewSamplerService.dataObjectOptions;
  protected dataValuesMultiOptions =
    this.viewSamplerService.dataValuesMultiOptions;
  protected dataObjectsMultiOptions =
    this.viewSamplerService.dataObjectsMultiOptions;
  protected dataCollection = this.viewSamplerService.dataCollection;

  constructor() {
    this.viewSamplerService.loadData();
  }

  toggleUseDeepSignal(check: unknown) {
    this.viewSamplerService.useDeepSignal.update(
      (useDeepSignal) => check as boolean,
    );
    this.viewSamplerService.loadData();
  }

  addDropdownValueOption() {
    if (this.viewSamplerService.useDeepSignal()) {
      (
        this.dataValueOptions as WritableSignal<
          DeepSignal<Array<DebugDataOption>>
        >
      ).update((options) => [
        ...(options ?? []),
        UTILS.SIGNALS.toDeepSignal<DebugDataOption>(
          { value: 6, label: 'String Option 6' },
          DebugDataOption,
        ),
      ]);
    } else {
      (this.dataValueOptions as WritableSignal<Array<DebugDataOption>>).update(
        (options) => [
          ...(options ?? []),
          { value: 6, label: 'String Option 6' },
        ],
      );
    }
  }

  removeDropdownValueOption(selectedOption: DebugDataOption) {
    if (this.viewSamplerService.useDeepSignal()) {
      (
        this.dataValueOptions as WritableSignal<
          DeepSignal<Array<DebugDataOption>>
        >
      ).update((options) =>
        options?.filter((option) => option()?.value() !== selectedOption.value),
      );
    } else {
      (this.dataValueOptions as WritableSignal<Array<DebugDataOption>>).update(
        (options) =>
          options?.filter((option) => option.value !== selectedOption.value),
      );
    }
  }

  addDropdownObjectOption() {
    if (this.viewSamplerService.useDeepSignal()) {
      (
        this.dataObjectOptions as WritableSignal<
          DeepSignal<Array<DebugDataOption>>
        >
      ).update((options) => [
        ...(options ?? []),
        UTILS.SIGNALS.toDeepSignal<DebugDataOption>(
          { value: 6, label: 'String Option 6' },
          DebugDataOption,
        ),
      ]);
    } else {
      (this.dataObjectOptions as WritableSignal<Array<DebugDataOption>>).update(
        (options) => [
          ...(options ?? []),
          { value: 6, label: 'String Option 6' },
        ],
      );
    }
  }

  removeDropdownObjectOption(selectedOption: DebugDataOption) {
    if (this.viewSamplerService.useDeepSignal()) {
      (
        this.dataObjectOptions as WritableSignal<
          DeepSignal<Array<DebugDataOption>>
        >
      ).update((options) =>
        options?.filter((option) => option()?.value() !== selectedOption.value),
      );
    } else {
      (this.dataObjectOptions as WritableSignal<Array<DebugDataOption>>).update(
        (options) =>
          options?.filter((option) => option.value !== selectedOption.value),
      );
    }
  }

  addMultiDropdownValuesOption() {
    if (this.viewSamplerService.useDeepSignal()) {
      (
        this.dataValuesMultiOptions as WritableSignal<
          DeepSignal<Array<DebugDataOption>>
        >
      ).update((options) => [
        ...(options ?? []),
        UTILS.SIGNALS.toDeepSignal<DebugDataOption>(
          { value: 6, label: 'String Option 6' },
          DebugDataOption,
        ),
      ]);
    } else {
      (
        this.dataValuesMultiOptions as WritableSignal<Array<DebugDataOption>>
      ).update((options) => [
        ...(options ?? []),
        { value: 6, label: 'String Option 6' },
      ]);
    }
  }

  removeMultiDropdownValuesOption(selectedOption: Array<DebugDataOption>) {
    if (this.viewSamplerService.useDeepSignal()) {
      (
        this.dataValuesMultiOptions as WritableSignal<
          DeepSignal<Array<DebugDataOption>>
        >
      ).update((options) =>
        options?.filter(
          (option) =>
            !selectedOption
              .map((selectedOption) => selectedOption?.value)
              ?.includes(option()?.value()),
        ),
      );
    } else {
      (
        this.dataValuesMultiOptions as WritableSignal<Array<DebugDataOption>>
      ).update((options) =>
        options?.filter(
          (option) =>
            !selectedOption
              .map((selectedOption) => selectedOption?.value)
              ?.includes(option?.value),
        ),
      );
    }
  }

  addMultiDropdownObjectsOption() {
    if (this.viewSamplerService.useDeepSignal()) {
      (
        this.dataObjectsMultiOptions as WritableSignal<
          DeepSignal<Array<DebugDataOption>>
        >
      ).update((options) => [
        ...(options ?? []),
        UTILS.SIGNALS.toDeepSignal<DebugDataOption>(
          { value: 6, label: 'String Option 6' },
          DebugDataOption,
        ),
      ]);
    } else {
      (
        this.dataObjectsMultiOptions as WritableSignal<Array<DebugDataOption>>
      ).update((options) => [
        ...(options ?? []),
        { value: 6, label: 'String Option 6' },
      ]);
    }
  }

  removeMultiDropdownObjectsOption(selectedOption: Array<DebugDataOption>) {
    if (this.viewSamplerService.useDeepSignal()) {
      (
        this.dataObjectsMultiOptions as WritableSignal<
          DeepSignal<Array<DebugDataOption>>
        >
      ).update((options) =>
        options?.filter(
          (option) =>
            !selectedOption
              .map((selectedOption) => selectedOption?.value)
              ?.includes(option()?.value()),
        ),
      );
    } else {
      (
        this.dataObjectsMultiOptions as WritableSignal<Array<DebugDataOption>>
      ).update((options) =>
        options?.filter(
          (option) =>
            !selectedOption
              .map((selectedOption) => selectedOption?.value)
              ?.includes(option?.value),
        ),
      );
    }
  }

  getAsyncMultiDropdownOptions = (
    query?: string,
  ): Observable<Array<unknown>> => {
    return of([
      { label: 'Async String Option 1', value: 10 },
      { label: 'Async String Option 2', value: 11 },
      { label: 'Async String Option 3', value: 12 },
    ]);
  };
}

@Component({
  selector: 'page-debug-right',
  imports: [CommonModule, SectionComponent],
  template: `
    <section [settings]="{ label: 'Debug', headerIsHidden: true }">
      <pre>{{ debugService.printed() }}</pre>
    </section>
  `,
})
export class DebugRightPage {
  protected readonly debugService = inject(ViewSamplerService);
  protected readonly Icons = ICONS;
}

@Component({
  selector: 'page-debug-bottom',
  imports: [CommonModule, SectionComponent],
  template: ` <section [settings]="{ label: 'Field Profile' }"></section> `,
})
export class DebugBottomPage {}
