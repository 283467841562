import { IUiOption, UTILS } from '@aksia/infrastructure';

export enum StreamSourceEnum {
  PPM = 1,
  Pitchbook = 6,
  MonthlyOrQuarterlyLetter = 7,
  MonthlyOrQuarterlyRiskReport = 8,
  Factsheet = 9,
  PerformanceDocument = 10,
  FinancialStatement = 12,
  ManagerWebsite = 13,
  DDQ = 27,
  Form10Q = 32,
  EmailManager = 101,
  Phone = 102,
  OnsiteMeeting = 103,
}

export namespace StreamSourceEnum {
  const replace = [{ key: /([a-z])([A-Z])/g, value: '$1 $2' }];
  export const toOptions = () =>
    UTILS.UI.enumToOptions(StreamSourceEnum, replace)?.sort((a, b) =>
      a.label! > b.label! ? 1 : -1,
    );
}

export enum StreamPeriodicityEnum {
  Daily = 1,
  Weekly,
  Monthly,
  Quarterly,
}

//#region AUM Specific

export enum AUMRelationEnum {
  None,
  Parent,
  Children,
  GrandChildren,
}

export enum AUMSourceEnum {
  EmailReportingInbox = 1,
  EmailPersonal = 2,
  InvestorLetter = 3,
  RiskReport = 4,
  FactSheet = 5,
  PitchBook = 6,
  PerformanceDocument = 7,
  ManagerWebsite = 8,
  Phone = 9,
  DDQ = 10,
  DataProvider = 11,
  AumDocument = 12,
  PPM = 13,
  FinancialStatements = 14,
  AdministratorTransparency = 15,
  OnsiteMeeting = 16,
  AksiaTemplate = 17,
  QuarterlyDataRequestTemplate = 18,
  OrgChart,
  FormADV,
  Form10Q = 32,
}

export namespace AUMSourceEnum {
  const replace = [{ key: /([a-z])([A-Z])/g, value: '$1 $2' }];
  export const toOptions = () =>
    UTILS.UI.enumToOptions(AUMSourceEnum, replace)?.sort(
      (a: IUiOption, b: IUiOption) => (a.label! > b.label! ? 1 : -1),
    );
}

//#endregion

//#region Public Returns Specific

export enum PublicReturnSourceEnum {
  EmailReportingInbox = 1,
  EmailPersonal = 2,
  InvestorLetter = 3,
  RiskReport = 4,
  FactSheet = 5,
  PitchBook = 6,
  PerformanceDocument = 7,
  WebSite = 8,
  Phone = 9,
  DDQ = 10,
  DataProvider = 11,
  AumDocument = 12,
  PPM = 13,
  FinancialStatements = 14,
  AdministratorTransparency = 15,
  OnsiteMeeting = 16,
  ESGReport = 17,
  QuarterlyDataRequestTemplate = 18,
  RMB = 19,
  FormADV = 20,
}

export namespace PublicReturnSourceEnum {
  const replace = [{ key: /([a-z])([A-Z])/g, value: '$1 $2' }];
  export const toOptions = () =>
    UTILS.UI.enumToOptions(PublicReturnSourceEnum, replace)?.sort((a, b) =>
      a.label! > b.label! ? 1 : -1,
    );
}

export enum PublicReturnFeeEnum {
  Net = 1,
  Gross,
  GrossMgmtFee,
  GrossPerfFee,
}

export namespace PublicReturnFeeEnum {
  const replace = [{ key: /([a-z])([A-Z])/g, value: '$1 $2' }];
  export const toOptions = () =>
    UTILS.UI.enumToOptions(PublicReturnFeeEnum, replace)?.sort((a, b) =>
      a.label! > b.label! ? 1 : -1,
    );
}

export enum PublicReturnGeneralClassificationEnum {
  Fund = 3,
  InvestmentProgram = 2,
  Master = 10,
  Composite = 20,
  InternalOnly = 25,
  FundOfOne = 26,
  CarveOut = 21,
  Backtested = 23,
}

export namespace PublicReturnGeneralClassificationEnum {
  const replace = [{ key: /([a-z])([A-Z])/g, value: '$1 $2' }];
  export const toOptions = () =>
    UTILS.UI.enumToOptions(
      PublicReturnGeneralClassificationEnum,
      replace,
    )?.sort((a: IUiOption, b: IUiOption) => (a.label! > b.label! ? 1 : -1));
}

//#endregion
