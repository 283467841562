import {
  SimpleAnswerEnum,
  DiscountTypeEnum,
  FundLevelsEnum,
  CurrencyEnum,
  EntityTypeEnum,
} from '../../enums/entity.enum';
import {
  RecyclingTimeLimitEnum,
  RecyclingProceedsLimitEnum,
  LpClawbackCalculatedOnEnum,
  LpClawbackTimeLimitFromEnum,
  MgmtFeeFromEnum,
  MgmtFeeToEnum,
  MgmtFeeCalcOnEnum,
  RateIndexEnum,
  CloseDateTypeEnum,
  ExtensionTypeEnum,
  LeverageBasisEnum,
  LeverageTypeEnum,
  LeverageUseEnum,
} from '../../enums/closedEnd.enum';
import { HurdleIndexEnum, LockUpEnum } from '../../enums/openEnd.enum';
import { IMasterFund } from '../../interfaces/entities/entities.interface';
import { UTILS, StoreModel, WritableDeepSignal } from '@aksia/infrastructure';
import { AssetModeEnum } from '@aksia/enums';

//#region Common

export class MasterFund extends StoreModel implements IMasterFund {
  id?: number = undefined;
  fundId?: number = undefined;
  name?: string = undefined;
}

export class Location extends StoreModel {
  addressId?: number = undefined;
  entityId?: number = undefined;
  entityTypeId?: EntityTypeEnum = undefined;
  address1?: string = undefined;
  address2?: string = undefined;
  city?: string = undefined;
  state?: string = undefined;
  zip?: string = undefined;
  country?: string = undefined;
  fax?: string = undefined;
  phone?: string = undefined;
  isPrimary?: boolean = undefined;
  latitude?: number = undefined;
  longitude?: number = undefined;
}

export class Discount extends StoreModel {
  id?: number = undefined;
  discountType?: DiscountTypeEnum = undefined;
  discountRate?: number = undefined;
  discountDesc?: string = undefined;
  markedForDeletion?: boolean = undefined;
}

export class ContractualExtension extends StoreModel {
  id?: number = undefined;
  duration?: number = undefined;
  type?: ExtensionTypeEnum = undefined;
}

//#endregion

//#region Open

export class Lockup extends StoreModel {
  id?: number = undefined;
  lockupDurationMinRange?: number = undefined;
  lockupDurationMaxRange?: number = undefined;
  lockupType?: LockUpEnum = undefined;
  lockupRolling?: boolean = undefined;
  lockupFee?: number = undefined;
  markedForDeletion?: boolean = undefined;
}

export class Gate extends StoreModel {
  id?: number = undefined;
  gateLevel?: FundLevelsEnum = undefined;
  gateMasterFundPercentage?: number = undefined;
  gateFundPercentage?: number = undefined;
  gateInvestorPercentage?: number = undefined;
  gatePercentage?: number = undefined;
  markedForDeletion?: boolean = undefined;
}

export class SidePocket extends StoreModel {
  id?: number = undefined;
  sidePocketLevel?: FundLevelsEnum = undefined;
  sidePocketLimit?: number = undefined;
  markedForDeletion?: boolean = undefined;
}

export class MgmtFeeRate extends StoreModel {
  id?: number = undefined;
  mgmtFeeRatePercentage?: number = undefined;
  mgmtFeeRateAmountFrom?: number = undefined;
  mgmtFeeRateAmountTo?: number = undefined;
  markedForDeletion?: boolean = undefined;
}

export class IncFeeRate extends StoreModel {
  id?: number = undefined;
  incFeeRatePercentage?: number = undefined;
  incFeeMinRatePercentage?: number = undefined;
  incFeeMaxRatePercentage?: number = undefined;
  hasHurdle?: SimpleAnswerEnum = undefined;
  hurdleRateType?: RateIndexEnum = undefined;
  hurdleIndex?: HurdleIndexEnum = undefined;
  hurdleIndexDesc?: string = undefined;
  hurdleRate?: number = undefined;
  hurdleMinRatePercentage?: number = undefined;
  hurdleMaxRatePercentage?: number = undefined;
  markedForDeletion?: boolean = undefined;
}

export class OpenMgmtFeeDiscount extends StoreModel {
  id?: number = undefined;
  discountType?: DiscountTypeEnum = undefined;
  discountRate?: number = undefined;
  discountDesc?: string = undefined;
}

export class IncentiveFeeDiscount extends StoreModel {
  id?: number = undefined;
  discountType?: DiscountTypeEnum = undefined;
  discountRate?: number = undefined;
  discountDesc?: string = undefined;
}

//#endregion

//#region Closed

export class ContractualFinalCloseExtension extends StoreModel {
  id?: number = undefined;
  duration?: number = undefined;
  type?: ExtensionTypeEnum = undefined;
}

export class ContractualInvestmentPeriodExtension extends StoreModel {
  id?: number = undefined;
  duration?: number = undefined;
  type?: ExtensionTypeEnum = undefined;
}

export class ContractualTermExtension extends StoreModel {
  id?: number = undefined;
  duration?: number = undefined;
  type?: ExtensionTypeEnum = undefined;
}

export class ClosedEndInterimDate extends StoreModel {
  id?: number = undefined;
  date?: Date = undefined;
  type?: CloseDateTypeEnum = undefined;
  description?: string = undefined;
}

export class Leverage extends StoreModel {
  id?: number = undefined;
  fundId?: number = undefined;
  leverageBasis?: LeverageBasisEnum = undefined;
  leverageType?: LeverageTypeEnum = undefined;
  leveragePercentageMin?: number = undefined;
  leveragePercentageMax?: number = undefined;
  leveragePercentageTarget?: number = undefined;
  leverageMultipleMin?: number = undefined;
  leverageMultipleMax?: number = undefined;
  leverageMultipleTarget?: number = undefined;
  leverageDesc?: string = undefined;
}

export class RecyclingTimeLimit extends StoreModel {
  id?: number = undefined;
  timeLimitType?: RecyclingTimeLimitEnum = undefined;
  monthsFromAcquisition?: number = undefined;
}

export class RecyclingProceedsLimit extends StoreModel {
  id?: number = undefined;
  proceedsType?: RecyclingProceedsLimitEnum = undefined;
  proceedsDesc?: string = undefined;
}

export class LpClawbackPerLimit extends StoreModel {
  id?: number = undefined;
  lpClawbackPerLimitRate?: number = undefined;
  lpClawbackCalculatedOnType?: LpClawbackCalculatedOnEnum = undefined;
}

export class LpClawbackTimeLimit extends StoreModel {
  id?: number = undefined;
  lpClawbackTimeLimitYears?: number = undefined;
  lpClawbackTimeLimitFrom?: LpClawbackTimeLimitFromEnum = undefined;
}

export class MgmtFeeAmount extends StoreModel {
  id?: number = undefined;
  mgmtFeeRate?: number = undefined;
  mgmtFeeCalcOnType?: MgmtFeeCalcOnEnum = undefined;
  mgmtFeeCalcOnDesc?: string = undefined;
  useOfLeverage?: LeverageUseEnum;
  markedForDeletion?: boolean = undefined;
}

export class MgmtFeePeriod extends StoreModel {
  id?: number = undefined;
  mgmtFeeTimeFrom?: MgmtFeeFromEnum = undefined;
  mgmtFeeTimeFromDesc?: string = undefined;
  mgmtFeeTimeFromDate?: Date = undefined;
  mgmtFeeTimeTo?: MgmtFeeToEnum = undefined;
  mgmtFeeTimeToDesc?: string = undefined;
  mgmtFeeTimeToDate?: Date = undefined;
  mgmtFeeAmounts?: Array<MgmtFeeAmount> = [new MgmtFeeAmount()];
  markedForDeletion?: boolean = undefined;
}

export class CarriedInterestTieredRate extends StoreModel {
  id?: number = undefined;
  feeRate?: number = undefined;
  prefReturn?: number = undefined;
  multiple?: number = undefined;
  hasCatchup?: SimpleAnswerEnum = undefined;
  catchupRate?: number = undefined;
  markedForDeletion?: boolean = undefined;
}

export class ClosedMgmtFeeDiscount extends StoreModel {
  id?: number = undefined;
  discountType?: DiscountTypeEnum = undefined;
  discountRate?: number = undefined;
  discountDesc?: string = undefined;
}

export class CarriedInterestDiscount extends StoreModel {
  id?: number = undefined;
  discountType?: DiscountTypeEnum = undefined;
  discountRate?: number = undefined;
  discountDesc?: string = undefined;
}

//#endregion

//#region Secondary

export class SecondaryAsset extends StoreModel {
  id?: number = undefined;
  secondaryDataFieldsId?: number = undefined;
  assetId?: number = undefined;
  assetName?: string = undefined;
  vintage?: number = undefined;
  recordDate?: Date = undefined;
  currency?: CurrencyEnum = undefined;
  navRecordDate?: number = undefined;
  uncalledCapital?: number = undefined;
  cashFlowsSinceRefDate?: number = undefined;
  unadjustedInvestmentAmount?: number = undefined;
  adjustedInvestmentAmount?: number = undefined;
  showInReport?: boolean = undefined;
}

//#endregion
