import { Component, Input } from '@angular/core';

/**
 * @deprecated This component is deprecated and should be removed.
 */
@Component({
  selector: 'it-test-sign',
  standalone: true,
  templateUrl: './it-test-sign.component.html',
  styleUrls: ['./it-test-sign.component.scss'],
})
export class ItTestSignComponent {
  @Input() size!: string;

  constructor() {}
}
