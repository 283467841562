import { NgComponentOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  input,
  Type,
} from '@angular/core';
import { UiControlDirective } from '../../../directives/ui/ui-control.directive';

@Component({
  selector: 'page',
  templateUrl: './page-component.html',
  imports: [NgComponentOutlet],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageComponent extends UiControlDirective {
  //#region Host Bindings

  @HostBinding('attr.uipage')
  uipage = '';

  //#endregion

  pageHeaderComponent = input<Type<any> | null>(null);
  pageContentComponent = input<Type<any> | null>(null);
  pageContentInput = input<any | null>(null);
}
