import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  input,
  model,
  Output,
  signal,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from '../../presentation/icon/icon.component';
import { CopyDirective } from '../../../directives/copy.directive';
import { UiControlDirective } from '../../../directives/ui/ui-control.directive';
import {
  ISectionSettings,
  PREFERENCES,
  SectionSettings,
  UTILS,
} from '@aksia/infrastructure';
import { EventEmitter } from '@angular/core';
@Component({
    selector: 'section',
    imports: [CommonModule, IconComponent, CopyDirective],
    templateUrl: './section.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SectionComponent extends UiControlDirective {
  //#region Inputs/Outputs

  override settings = input<SectionSettings, ISectionSettings>(
    new SectionSettings(),
    {
      transform: (settings: ISectionSettings) => this.initSettings(settings),
    },
  );

  sectionContentComponent = model<Type<any> | null>(null);
  sectionContentInput = model<any | undefined>(undefined);

  @Output() toggleState = new EventEmitter<boolean>();

  //#endregion

  //#region Host Bindings

  @HostBinding('class.isMinimized')
  get isMinimized() {
    return this.settings().isMinimized;
  }
  // Signal to control visibility
  isRemoved = signal(false);
  //#endregion

  //#region View Children

  @ViewChild('UiSectionCodeContent', { read: ViewContainerRef })
  uiSectionCodeContent!: ViewContainerRef;

  //#endregion

  //#region Functions

  constructor() {
    super();
    this.loadUserPreferences();
  }

  protected override initSettings(settings: ISectionSettings) {
    let sectionSettings = UTILS.OBJECT.assign(new SectionSettings(), settings);
    super.initSettings(sectionSettings);
    this.loadUserPreferences();
    return sectionSettings;
  }

  protected toggle() {
    if (!this.settings().isCollapsible) {
      return;
    }

    this.settings().isMinimized = !this.settings().isMinimized;
    //this.updateUserPreferences();
    this.toggleState.emit(this.settings().isMinimized);
  }

  protected close(event: MouseEvent) {
    event.stopPropagation();
    this.isRemoved.set(true);
    this.toggleState.emit(true);
  }

  private loadUserPreferences() {
    /* const minimizedSections = this.authSvc.getUserPreference<Array<string>>(
      PREFERENCES.DEFAULT_MINIMIZED_SECTIONS,
    );

    if (minimizedSections?.length! > 0) {
      this.settings().isMinimized =
        minimizedSections?.includes(this.settings().label!) ?? false;
    } */
  }

  /* private updateUserPreferences() {
    let minimizedSections =
      this.authSvc.getUserPreference<Array<string>>(
        PREFERENCES.DEFAULT_MINIMIZED_SECTIONS,
      ) ?? [];
    minimizedSections = minimizedSections.includes(this.settings().label!)
      ? minimizedSections?.filter((ms) => ms !== this.settings().label)
      : minimizedSections.concat(this.settings().label!);
    this.authSvc.setUserPreference(
      PREFERENCES.DEFAULT_MINIMIZED_SECTIONS,
      minimizedSections,
    );
  } */

  //#endregion
}
