@if (permDir.uipermission() !== PERMISSIONS.NONE) {
  <input
    #uiValueRef
    uivalue
    type="file"
    [class.hide]="uiType() === 'button'"
    [class.has-uploaded-file]="!!value"
    (change)="
      permDir.uipermission() === PERMISSIONS.VIEW
        ? false
        : onFileSelected($event)
    "
    [multiple]="allowMultipleFiles()"
    [accept]="allowedFileTypes()"
  />
  @if (label() && settings().uiType === 'field') {
    <label
      [attr.uilabelup]="labelUp()"
      [label]="labelFormatted()"
      [labelTip]="labelTip()"
      (click)="
        permDir.uipermission() === PERMISSIONS.VIEW ? false : focusin();
        uiValueRef.click()
      "
    >
    </label>
  }
  <div uitoolstrip>
    <ng-content #uiToolsMarkupRef select="[uitool]"></ng-content>
    <ng-template #uiToolsCodeRef></ng-template>
    <ng-container #uiToolsDefaultRef>
      @if (defaultIcon()) {
        <icon
          uidefaulttool
          [iconSize]="'normal'"
          [iconDefinition]="defaultIcon()"
          (click)="uiValueRef.click()"
        ></icon>
      }
    </ng-container>
  </div>
}
