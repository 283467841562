import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  WritableSignal,
  input,
  signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  IconSizeType,
  ButtonSettings,
  IButtonSettings,
  IconType,
  UTILS,
} from '@aksia/infrastructure';
import { IconComponent } from '../../presentation/icon/icon.component';
import { UiControlDirective } from '../../../directives/ui/ui-control.directive';

@Component({
  selector: 'btn',
  imports: [CommonModule, IconComponent],
  templateUrl: './button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent extends UiControlDirective {
  //#region Inputs/Outputs

  override settings = input<ButtonSettings, IButtonSettings>(
    new ButtonSettings(),
    {
      transform: (settings: IButtonSettings) => this.initSettings(settings),
    },
  );

  inputCssClass = input<string | undefined>();

  //#endregion

  //#region Host Bindings

  @HostBinding('class.iconOnly')
  get iconOnlyProp() {
    return this.iconOnly();
  }

  @HostBinding('class.disabled')
  get isDisabled() {
    return this.settings().disabled;
  }

  //#endregion

  //#region Properties

  icon: WritableSignal<IconType | undefined> = signal<IconType | undefined>(
    undefined,
  );
  iconSize: WritableSignal<IconSizeType | undefined> = signal<
    IconSizeType | undefined
  >(undefined);
  iconOnly: WritableSignal<boolean | undefined> = signal<boolean | undefined>(
    undefined,
  );
  iconColor: WritableSignal<string | undefined> = signal<string | undefined>(
    undefined,
  );

  disabled: WritableSignal<boolean | undefined> = signal<boolean | undefined>(
    undefined,
  );

  //#endregion

  //#region Functions

  protected override initSettings(settings: IButtonSettings) {
    let buttonSettings = UTILS.OBJECT.assign(new ButtonSettings(), settings);
    super.initSettings(buttonSettings);
    this.icon.set(buttonSettings.icon);
    this.iconSize.set(buttonSettings.iconSize);
    this.iconOnly.set(buttonSettings.iconOnly);
    this.iconColor.set(buttonSettings.iconColor);
    this.disabled.set(buttonSettings.disabled);
    return buttonSettings;
  }

  //#endregion
}
