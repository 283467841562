import { UTILS } from '@aksia/infrastructure';
import {
  Directive,
  ElementRef,
  HostListener,
  inject,
  Input,
} from '@angular/core';

@Directive({
  selector: '[UiCopy]',
  standalone: true,
})
export class CopyDirective {
  private hostEl = inject(ElementRef);
  @Input() UiCopy!: string;

  copy() {
    UTILS.EVENTS.copyToClipboard(this.UiCopy);
    const originalText = (this.hostEl.nativeElement as HTMLElement).innerHTML;
    (this.hostEl.nativeElement as HTMLElement).innerHTML = 'Copied...';
    setTimeout(() => {
      (this.hostEl.nativeElement as HTMLElement).innerHTML = originalText;
    }, 2000);
    return false;
  }

  @HostListener('contextmenu', ['$event'])
  rightClickEvent(event: MouseEvent) {
    this.copy();
    event.preventDefault();
  }
}
