import {
  ChangeDetectionStrategy,
  Component,
  Signal,
  WritableSignal,
  computed,
  input,
  isSignal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiInputDirective } from '../../../directives/ui/ui-input.directive';
import { DeepSignal, PropExtend, UTILS } from '@aksia/infrastructure';
import { RadioGroupSettings } from '@aksia/infrastructure';
import { IRadioGroupSettings } from '@aksia/infrastructure';

@Component({
    selector: 'radio-group',
    imports: [CommonModule],
    templateUrl: './radio-group.component.html',
    styleUrls: ['./radio-group.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RadioGroupComponent extends UiInputDirective {
  //#region Inputs/Outputs

  override settings = input(new RadioGroupSettings(), {
    transform: (settings: IRadioGroupSettings) => this.initSettings(settings),
  });

  protected radioSize = computed(
    () => this.formatSize(this.settings().radioSize) || '16px',
  );
  protected plainOptions = computed(() => {
    let options = isSignal(this.settings().options)
      ? this.settings().options()
      : this.settings().options;

    let plainOptions = options?.map((option: PropExtend<unknown>) => {
      let optionPlain: PropExtend<unknown> = UTILS.SIGNALS.isDeepSignal(option)
        ? isSignal(option)
          ? (
              option as WritableSignal<
                DeepSignal<unknown & { _plain: unknown }>
              >
            )?.()?._plain()
          : (option as DeepSignal<unknown & { _plain: unknown }>)?._plain()
        : isSignal(option)
          ? option()
          : option;
      return optionPlain;
    });

    return plainOptions;
  });

  selectedOption = computed(() =>
    this.plainOptions()?.find(
      (option: PropExtend<unknown>) =>
        option[this.settings().optionValue] ===
        this.plainValue()?.[this.settings().optionValue],
    ),
  );

  private plainValue = computed<PropExtend<unknown>>(() =>
    UTILS.SIGNALS.isDeepSignal(this.value())
      ? (
          this.value as WritableSignal<
            DeepSignal<unknown & { _plain: Signal<unknown> }>
          >
        )?.()?._plain()
      : this.value(),
  );

  protected override initSettings(settings: IRadioGroupSettings) {
    let radioGroupSettings = UTILS.OBJECT.assign(
      new RadioGroupSettings(),
      settings,
    );

    super.initSettings(radioGroupSettings);
    return radioGroupSettings;
  }
  formatSize(size: string | number): string {
    return typeof size === 'number' ? `${size}px` : size;
  }

  updateRadioValue(newValue: any) {
    this.updateValue(newValue);
  }

  //#endregion
}
