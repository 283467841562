<ng-content></ng-content>
<span
  [attr.uistate]="state()"
  [title]="labelTip() || label()"
  [UiCopy]="label()!"
  >{{ label() }}
</span>

@if (labelTip()) {
  <icon
    uiinfo
    [iconSize]="'small'"
    [iconDefinition]="ICONS.info"
    [title]="labelTip()"
  ></icon>
}
