import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { BroadcastMessage } from '@aksia/infrastructure';

@Injectable({
  providedIn: 'root',
})
export class BroadcastService {
  broadcastChannel!: BroadcastChannel;
  onMessage = new Subject<any>();

  constructor() {
    this.initialize('o2_channel');
  }

  initialize(name: string) {
    this.broadcastChannel = new BroadcastChannel(name);
    this.broadcastChannel.onmessage = (message) =>
      this.onMessage.next(message.data);
  }

  publish(message: BroadcastMessage): void {
    this.broadcastChannel.postMessage(message);
  }

  messageOfType(type: string): Observable<BroadcastMessage> {
    return this.onMessage.pipe(filter((message) => message.type === type));
  }
}
