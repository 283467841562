import { IUiOption } from '@aksia/infrastructure';
import { SimpleAnswerEnum } from '../../enums/entity.enum';
import { IClosedEndGPTargetInvestmentStat } from '../../interfaces/investment-vehicle/closed-end-gp-target-investment-stat.interface';

export class ClosedEndGPTargetInvestmentStat {
  closedEndDataFieldsId?: number = undefined;

  //#region Portfolio Company Statistics (GP Provided)

  revenueRangeHigh?: number = undefined;
  revenueRangeLow?: number = undefined;
  ebitdaRangeHigh?: number = undefined;
  ebitdaRangeLow?: number = undefined;
  enterpriseValueRangeHigh?: number = undefined;
  enterpriseValueRangeLow?: number = undefined;
  equityInvestmentRangeHigh?: number = undefined;
  equityInvestmentRangeLow?: number = undefined;
  transactionMultipleHigh?: number = undefined;
  transactionMultipleLow?: number = undefined;
  transactionMultipleRevenueHigh?: number = undefined;
  transactionMultipleRevenueLow?: number = undefined;
  numPortfolioCompaniesHigh?: number = undefined;
  numPortfolioCompaniesLow?: number = undefined;

  //#endregion

  //#region Investment Details

  seedAssets?: SimpleAnswerEnum = undefined;
  programEquityCommittedSeedAssets?: number = undefined;
  maxDevelopment?: number = undefined;
  targetHoldingPeriod?: number = undefined;

  //#endregion

  boardSeats?: SimpleAnswerEnum = undefined;
  numSeedInvestments?: number = undefined;
  preferredCompanyValuationLow?: number = undefined;
  preferredCompanyValuationHigh?: number = undefined;
}

/* @MetaClass({
  audit: {
    url: 'basicdata/fund/audit/{0}/closedend/{1}/closedEndGPTargetInvestmentStat/{2}',
    urlParams: [
      'grandParent.fundId@model',
      'parent.id@model',
      '@prop',
    ]
  }
}) */
export class ClosedEndGPTargetInvestmentStatMeta
  implements IClosedEndGPTargetInvestmentStat
{
  closedEndDataFieldsId: number = 0;

  //#region Portfolio Company Statistics (GP Provided)

  /*  @MetaField({
    ui: {
      label: 'GP Revenue Range High',
    },
  }) */
  revenueRangeHigh?: number;

  /* @MetaField({
    ui: {
      label: 'GP Revenue Range Low',
    },
  }) */
  revenueRangeLow?: number;

  /* @MetaField({
    ui: {
      label: 'GP EBITDA Range High',
    },

  }) */
  ebitdaRangeHigh?: number;

  /* @MetaField({
    ui: {
      label: 'GP EBITDA Range Low',
    },
  }) */
  ebitdaRangeLow?: number;

  /* @MetaField({
    ui: {
      label: 'GP Enterprise Value Range High',
    },
  }) */
  enterpriseValueRangeHigh?: number;

  /* @MetaField({
    ui: {
      label: 'GP Enterprise Value Range Low',
    },
  }) */
  enterpriseValueRangeLow?: number;

  /* @MetaField({
    ui: {
      label: 'GP Equity Investment Range High',
    },
  }) */
  equityInvestmentRangeHigh?: number;

  /* @MetaField({
    ui: {
      label: 'GP Equity Investment Range Low',
    },
  }) */
  equityInvestmentRangeLow?: number;

  /* @MetaField({
    ui: {
      label: 'GP Transaction Multiple Range High',
    },
  }) */
  transactionMultipleHigh?: number;

  /* @MetaField({
    ui: {
      label: 'GP Transaction Multiple Range Low',
    },
  }) */
  transactionMultipleLow?: number;

  /* @MetaField({
    ui: {
      label: 'GP Transaction Multiple Range High (Revenue)',
    },
  }) */
  transactionMultipleRevenueHigh?: number;

  /* @MetaField({
    ui: {
      label: 'GP Transaction Multiple Range Low (Revenue)',
    },
  }) */
  transactionMultipleRevenueLow?: number;

  /* @MetaField({
    ui: {
      label: 'Number of Portfolio Companies High',
    },
  }) */
  numPortfolioCompaniesHigh?: number;

  /* @MetaField({
    ui: {
      label: 'Number of Portfolio Companies Low',
    },
  }) */
  numPortfolioCompaniesLow?: number;

  //#endregion

  //#region Investment Details

  /* @MetaField({
    ui: {
      label: 'Seed Assets',
      options: SimpleAnswerEnum.toOptions().filter(
        (sa: IUiOption) => sa.value !== SimpleAnswerEnum.NotSpecified,
      ),
    },
  }) */
  seedAssets?: SimpleAnswerEnum;

  /*@MetaField({
    ui: {
      label: 'Program Equity Committed to Seed Assets',
    },
  }) */
  programEquityCommittedSeedAssets?: number;

  /* @MetaField({
    ui: {
      label: 'Max Development',
    },
  }) */
  maxDevelopment?: number;

  /* @MetaField({
    ui: {
      label: 'Target Holding Period',
    },
  }) */
  targetHoldingPeriod?: number;

  //#endregion

  /* @MetaField({
    ui: {
      label: 'Board Seats',
      options: SimpleAnswerEnum.toOptions().filter(
        (sa: IUiOption) => sa.value !== SimpleAnswerEnum.NotSpecified,
      ),
    },
  }) */
  boardSeats?: SimpleAnswerEnum;

  /* @MetaField({
    ui: {
      label: 'Number of Seed Investment',
    },
  }) */
  numSeedInvestments?: number;

  /*  @MetaField({
    ui: {
      label: 'Preferred Company Valuation Low',
    },
  }) */
  preferredCompanyValuationLow?: number;

  /*  @MetaField({
    ui: {
      label: 'Preferred Company Valuation High',
    },
  }) */
  preferredCompanyValuationHigh?: number;

  isDeleted?: boolean;
  modifiedBy?: string;
  modifiedOn?: Date;
}
