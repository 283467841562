import { Injectable } from '@angular/core';
import { Observable, Subject, catchError, of, tap } from 'rxjs';
import { ApiService } from '../api/api.service';
import { UTILS } from '@aksia/infrastructure';

const mockAPIRoutes: Array<{ source: string; target: string }> = [
  /* { source: 'shareclass/fund/', target: 'shareclass/fund?id_gte=' }, */
  /* { source: 'aum/mergeStream/|0', target: 'aum?id=' }, */
];

@Injectable({
  providedIn: 'root',
})
export class MockService<T> extends ApiService {
  mockData$: Subject<Array<T>> = new Subject<Array<T>>();
  mockData = this.mockData$.asObservable();
  mockDelay = 1000;

  private routeResolve(route: string): string {
    let newRoute = route;
    mockAPIRoutes.forEach((r) => {
      if (route.includes(r.source.split('|')[0])) {
        if (r.source.includes('|')) {
          let sourceRoute = r.source.split('|')[0];
          let idIndex = +r.source.split('|')[1];

          let id = route.split(sourceRoute)[1].split('/')[idIndex];
          newRoute = r.target + id;
        } else {
          newRoute = route.replace(r.source, r.target);
        }
      }
    });
    console.warn('Mocking Route: ', route, 'with: ', newRoute);
    return newRoute;
  }

  public override get(
    route: string,
    additionalParams?: Map<string, any>,
    useCache?: boolean,
  ): Observable<any> {
    if (route.includes('?')) {
      console.warn('Ignoring Query Param: ', route.split('?')[1]);
      route = route.split('?')[0];
    }
    let data = this.localStorage.get(route);
    if (data) {
      return of(data);
    } else {
      return this.http
        .get(
          `${this.environment.mock_service_api_url}/${this.routeResolve(route)}`,
        )
        .pipe(
          catchError((error) => {
            console.error(error);
            return of(null);
          }),
          tap((data) => {
            if (data) {
              //console.log('Mock Data: ', data);
              this.localStorage.set(route, data);
            }
          }),
        );
    }
  }

  public override put(route: string, body: any = null): Observable<any> {
    if (this.environment.mock_use_get_only) {
      return this.get(route);
    }
    return this.http.put(`/assets/mock/${route}.json`, body).pipe(
      catchError((error) => {
        console.error(error);
        return of(null);
      }),
      tap((data) => {
        if (data) {
          this.localStorage.set(route, data);
        }
      }),
    );
  }

  public override post(route: string, body: any = null): Observable<any> {
    if (this.environment.mock_use_get_only) {
      return this.get(route);
    }
    if (route.includes('?')) {
      console.warn('Ignoring Query Param: ', route.split('?')[1]);
      route = route.split('?')[0];
    }
    let data = this.localStorage.get(route);
    if (data) {
      return of(data);
    } else {
      return this.http
        .post(
          `${this.environment.mock_service_api_url}/${this.routeResolve(route)}`,
          body,
        )
        .pipe(
          catchError((error) => {
            console.error(error);
            return of(null);
          }),
          tap((data) => {
            if (data) {
              console.log('Mock Data: ', data);
              this.localStorage.set(route, data);
            }
          }),
        );
    }
  }

  public override delete(route: string): Observable<any> {
    console.warn('Mocking Delete Route: ', route);
    return of(true);
  }
}
