import { UTILS } from '@aksia/infrastructure';

export const dataDTO = {
  simpleString: 'Simple String',
  multiString: 'Multi String',
  simpleNumber: 1,
  simpleDate: '2023-01-01T00:00:00.000Z',
  simpleMonth: '2023-02-01T00:00:00.000Z',
  simpleBoolean: false,
  simpleDropdownValue: 1,
  simpleDropdownObject: {
    label: 'Simple Option 1',
    value: 1,
  },
  multiDropdownValues: [1, 2],
  multiDropdownObjects: [
    {
      label: 'Simple Option 1',
      value: 1,
    },
    {
      label: 'Simple Option 2',
      value: 2,
    },
  ],
};

export const dataOptionsDTO = [
  { value: 1, label: 'String Option 1' },
  { value: 2, label: 'String Option 2' },
  { value: 3, label: 'String Option 3' },
  { value: 4, label: 'String Option 4' },
  { value: 5, label: 'String Option 5' },
];

export enum dataEnum {
  StringOption1 = 'String Option 1',
  StringOption2 = 'String Option 2',
  StringOption3 = 'String Option 3',
  StringOption4 = 'String Option 4',
  StringOption5 = 'String Option 5',
}

export namespace dataEnum {
  export const toOptions = () => UTILS.UI.enumToOptions(dataEnum);
}
