import { Signal, isSignal } from '@angular/core';
import { UTILS } from '../functions/utils';

export type AksiaValidatorMessageType = { error: string; warning: string };

export type AksiaValidatorMessagesType = {
  required: AksiaValidatorMessageType;
  equals: AksiaValidatorMessageType;
  notEquals: AksiaValidatorMessageType;
  min: AksiaValidatorMessageType;
  minIncluding: AksiaValidatorMessageType;
  minDate: AksiaValidatorMessageType;
  minDateIncluding: AksiaValidatorMessageType;
  max: AksiaValidatorMessageType;
  maxIncluding: AksiaValidatorMessageType;
  maxDate: AksiaValidatorMessageType;
  maxDateIncluding: AksiaValidatorMessageType;
  equalsDate: AksiaValidatorMessageType;
  notIncludedIn: AksiaValidatorMessageType;
  email: AksiaValidatorMessageType;
};

export const AKSIA_VALIDATION_DEFAULT_MESSAGES = {
  required: { error: 'Should not be empty', warning: 'Is usually not empty' },
  equals: { error: 'Should be equal to', warning: 'Is usually equal to' },
  notEquals: {
    error: 'Should not be equal to',
    warning: 'Is usually not equal to',
  },
  min: {
    error: 'Should not be less than',
    warning: 'Is usually not less than',
  },
  minIncluding: {
    error: 'Should not be less or equal to',
    warning: 'Is usually not less or equal to',
  },
  minDate: {
    error: 'Should not occur earlier than',
    warning: 'Is usually not occuring earlier than',
  },
  minDateIncluding: {
    error: 'Should not occur earlier or on',
    warning: 'Is usually not occuring earlier or on',
  },
  max: {
    error: 'Should not be more than',
    warning: 'Is usually not more than',
  },
  maxIncluding: {
    error: 'Should not be more or equal to',
    warning: 'Is usually not more or equal to',
  },
  maxDate: {
    error: 'Should not occur later than',
    warning: 'Is usually not occuring later than',
  },
  maxDateIncluding: {
    error: 'Should not occur later or on',
    warning: 'Is usually not occuring later or on',
  },
  equalsDate: {
    error: 'Should not occur on',
    warning: 'Is usually not occuring on',
  },
  notIncludedIn: {
    error: 'Should not be included in',
    warning: 'Is usually not included in',
  },
  email: {
    error: 'Should be a valid email address',
    warning: 'Is not a valid email address',
  },
};

export type AksiaValidatorFn = (value: unknown) => AksiaValidatorResult;

export type AksiaValidatorOptions = {
  isWarning?:
    | boolean
    | Signal<boolean | undefined>
    | (() => boolean | undefined);
  userMessage?:
    | string
    | Signal<string | undefined>
    | (() => string | undefined);
  includeValue?:
    | boolean
    | Signal<string | undefined>
    | (() => boolean | undefined);
  isDisabled?:
    | boolean
    | Signal<boolean | undefined>
    | (() => boolean | undefined);
};

type AksiaValidatorNotRepeatedOptions = AksiaValidatorOptions & {
  excludeValue?: unknown;
};

class AksiaValidatorResult {
  errors: string[] = [];
  warnings: string[] = [];
}

export class AksiaValidators {
  public static validate(
    value: unknown | undefined,
    validators: AksiaValidatorFn[],
  ): AksiaValidatorResult {
    const result = validators.map((validator) => validator(value));
    return {
      errors: result.flatMap((result) => result.errors),
      warnings: result.flatMap((result) => result.warnings),
    };
  }

  public static validateForErrors(
    value: unknown,
    validators?: AksiaValidatorFn[] | (() => AksiaValidatorFn[] | undefined),
  ): string[] {
    let validatorFunctions =
      validators instanceof Function ? validators() : validators;
    return (
      validatorFunctions
        ?.map((validator) => validator(value))
        ?.flatMap((result) => result.errors) ?? []
    );
  }

  public static validateForWarnings(
    value: unknown,
    validators?: AksiaValidatorFn[] | (() => AksiaValidatorFn[] | undefined),
  ): string[] {
    let validatorFunctions =
      validators instanceof Function ? validators() : validators;
    return (
      validatorFunctions
        ?.map((validator) => validator(value))
        ?.flatMap((result) => result.warnings) ?? []
    );
  }

  public static delegatePlainSignalFn<T = unknown>(
    value: unknown | Signal<unknown | undefined> | (() => unknown | undefined),
  ): T | undefined {
    return isSignal(value)
      ? value()
      : typeof value === 'function'
        ? value()
        : value;
  }

  public static generateMessage(
    options: AksiaValidatorOptions,
    defaultMessage: AksiaValidatorMessageType,
    value?: string,
  ) {
    const result = new AksiaValidatorResult();
    const isDisabled = AksiaValidators.delegatePlainSignalFn<boolean>(
      options?.isDisabled,
    );
    const isWarning = AksiaValidators.delegatePlainSignalFn<boolean>(
      options?.isWarning,
    );
    if (isDisabled) return result;
    if (UTILS.OBJECT.isNil(value)) value = '';

    if (isWarning) {
      result.warnings.push(
        AksiaValidators.delegatePlainSignalFn<string>(options?.userMessage) ??
          `${defaultMessage.warning} ${value}`,
      );
    } else
      result.errors.push(
        AksiaValidators.delegatePlainSignalFn<string>(options?.userMessage) ??
          `${defaultMessage.error} ${value}`,
      );
    return result;
  }

  public static readonly required =
    (options?: AksiaValidatorOptions): AksiaValidatorFn =>
    (value: unknown) => {
      return UTILS.OBJECT.isNil(value)
        ? AksiaValidators.generateMessage(
            options!,
            AKSIA_VALIDATION_DEFAULT_MESSAGES.required,
          )
        : new AksiaValidatorResult();
    };

  public static readonly email =
    (options?: AksiaValidatorOptions): AksiaValidatorFn =>
    (value: unknown) => {
      return !UTILS.STRING.isValidEmail(value as string)
        ? AksiaValidators.generateMessage(
            options!,
            AKSIA_VALIDATION_DEFAULT_MESSAGES.email,
          )
        : new AksiaValidatorResult();
    };

  public static readonly equals =
    (
      equals?: unknown | Signal<unknown> | (() => unknown | undefined),
      options?: AksiaValidatorOptions,
    ): AksiaValidatorFn =>
    (value?: unknown) => {
      let equalsValue = AksiaValidators.delegatePlainSignalFn(equals);

      if (UTILS.OBJECT.isNil(value) || UTILS.OBJECT.isNil(equalsValue))
        return new AksiaValidatorResult();

      return value !== equalsValue
        ? AksiaValidators.generateMessage(
            options!,
            AKSIA_VALIDATION_DEFAULT_MESSAGES.equals,
            `${equalsValue}`,
          )
        : new AksiaValidatorResult();
    };

  public static readonly notEquals =
    (
      notEquals?: unknown | Signal<unknown> | (() => unknown | undefined),
      options?: AksiaValidatorOptions,
    ): AksiaValidatorFn =>
    (value?: unknown) => {
      let notEqualsValue = AksiaValidators.delegatePlainSignalFn(notEquals);

      if (UTILS.OBJECT.isNil(value) || UTILS.OBJECT.isNil(notEqualsValue))
        return new AksiaValidatorResult();

      return value === notEqualsValue
        ? AksiaValidators.generateMessage(
            options!,
            AKSIA_VALIDATION_DEFAULT_MESSAGES.notEquals,
            `${notEqualsValue}`,
          )
        : new AksiaValidatorResult();
    };

  public static readonly min =
    (
      min?: number | Signal<unknown> | (() => number | undefined),
      options?: AksiaValidatorOptions,
    ): AksiaValidatorFn =>
    (value?: unknown) => {
      let minValue = this.delegatePlainSignalFn<number>(min);
      let includeValue = AksiaValidators.delegatePlainSignalFn<boolean>(
        options?.includeValue,
      );

      if (UTILS.OBJECT.isNil(value) || UTILS.OBJECT.isNil(minValue))
        return new AksiaValidatorResult();

      return (
        includeValue
          ? (value as number) <= minValue!
          : (value as number) < minValue!
      )
        ? AksiaValidators.generateMessage(
            options!,
            includeValue
              ? AKSIA_VALIDATION_DEFAULT_MESSAGES.minIncluding
              : AKSIA_VALIDATION_DEFAULT_MESSAGES.min,
            `${minValue}`,
          )
        : new AksiaValidatorResult();
    };

  public static readonly minDate =
    (
      minDate?: Date | Signal<unknown> | (() => Date | undefined),
      options?: AksiaValidatorOptions,
    ): AksiaValidatorFn =>
    (value?: unknown) => {
      let minDateValue = this.delegatePlainSignalFn<Date>(minDate);
      minDateValue =
        typeof minDateValue === 'string'
          ? new Date(minDateValue)
          : minDateValue;
      value = typeof value === 'string' ? new Date(value) : value;

      let includeValue = AksiaValidators.delegatePlainSignalFn<boolean>(
        options?.includeValue,
      );

      if (UTILS.OBJECT.isNil(value) || UTILS.OBJECT.isNil(minDateValue))
        return new AksiaValidatorResult();

      return (
        includeValue
          ? (value as Date).getTime() <= minDateValue!.getTime()
          : (value as Date).getTime() < minDateValue!.getTime()
      )
        ? AksiaValidators.generateMessage(
            options!,
            includeValue
              ? AKSIA_VALIDATION_DEFAULT_MESSAGES.minDateIncluding
              : AKSIA_VALIDATION_DEFAULT_MESSAGES.minDate,
            `${minDateValue!.toLocaleString('en-US', { dateStyle: 'short' })}`,
          )
        : new AksiaValidatorResult();
    };

  public static readonly max =
    (
      max?: number | Signal<unknown> | (() => number | undefined),
      options?: AksiaValidatorOptions,
    ): AksiaValidatorFn =>
    (value?: unknown) => {
      let maxValue = this.delegatePlainSignalFn<number>(max);
      let includeValue = AksiaValidators.delegatePlainSignalFn<boolean>(
        options?.includeValue,
      );

      if (UTILS.OBJECT.isNil(value) || UTILS.OBJECT.isNil(maxValue))
        return new AksiaValidatorResult();

      return (
        includeValue
          ? (value as number) >= maxValue!
          : (value as number) > maxValue!
      )
        ? AksiaValidators.generateMessage(
            options!,
            includeValue
              ? AKSIA_VALIDATION_DEFAULT_MESSAGES.maxIncluding
              : AKSIA_VALIDATION_DEFAULT_MESSAGES.max,
            `${maxValue}`,
          )
        : new AksiaValidatorResult();
    };

  public static readonly maxDate =
    (
      maxDate?: Date | Signal<unknown> | (() => Date | undefined),
      options?: AksiaValidatorOptions,
    ): AksiaValidatorFn =>
    (value?: unknown) => {
      let maxDateValue = this.delegatePlainSignalFn<Date>(maxDate);
      maxDateValue =
        typeof maxDateValue === 'string'
          ? new Date(maxDateValue)
          : maxDateValue;
      value = typeof value === 'string' ? new Date(value) : value;

      let includeValue = AksiaValidators.delegatePlainSignalFn<boolean>(
        options?.includeValue,
      );

      if (UTILS.OBJECT.isNil(value) || UTILS.OBJECT.isNil(maxDateValue))
        return new AksiaValidatorResult();

      return (
        includeValue
          ? (value as Date).getTime() >= maxDateValue!.getTime()
          : (value as Date).getTime() > maxDateValue!.getTime()
      )
        ? AksiaValidators.generateMessage(
            options!,
            includeValue
              ? AKSIA_VALIDATION_DEFAULT_MESSAGES.maxDateIncluding
              : AKSIA_VALIDATION_DEFAULT_MESSAGES.maxDate,
            `${maxDateValue!.toLocaleString('en-US', { dateStyle: 'short' })}`,
          )
        : new AksiaValidatorResult();
    };

  public static readonly equalsDate =
    (
      equalsDate?: Date | Signal<unknown> | (() => Date | undefined),
      options?: AksiaValidatorOptions,
    ): AksiaValidatorFn =>
    (value?: unknown) => {
      let equalsDateValue = this.delegatePlainSignalFn<Date>(equalsDate);
      equalsDateValue =
        typeof equalsDateValue === 'string'
          ? new Date(equalsDateValue)
          : equalsDateValue;
      value = typeof value === 'string' ? new Date(value) : value;

      if (UTILS.OBJECT.isNil(value) || UTILS.OBJECT.isNil(equalsDateValue))
        return new AksiaValidatorResult();

      return (value as Date).getTime() === equalsDateValue!.getTime()
        ? AksiaValidators.generateMessage(
            options!,
            AKSIA_VALIDATION_DEFAULT_MESSAGES.equalsDate,
            `${equalsDate!.toLocaleString('en-US', { dateStyle: 'short' })}`,
          )
        : new AksiaValidatorResult();
    };

  public static readonly notIncludedIn =
    (
      collection?:
        | Array<string | number>
        | Signal<Array<string | number>>
        | (() => Array<string | number> | undefined),
      options?: AksiaValidatorOptions,
    ): AksiaValidatorFn =>
    (value?: unknown) => {
      let collectionValues =
        this.delegatePlainSignalFn<Array<string | number>>(collection);

      if (
        UTILS.OBJECT.isNil(collectionValues) ||
        collectionValues?.length === 0
      )
        return new AksiaValidatorResult();

      return Array.isArray(collectionValues) &&
        collectionValues.length > 0 &&
        collectionValues?.includes(value as string | number)
        ? AksiaValidators.generateMessage(
            options!,
            AKSIA_VALIDATION_DEFAULT_MESSAGES.notIncludedIn,
            `${collectionValues.join(', ')}`,
          )
        : new AksiaValidatorResult();
    };

  public static readonly notRepeated =
    (
      collection?:
        | Array<string | number>
        | Signal<Array<string | number>>
        | (() => Array<string | number> | undefined),
      options?: AksiaValidatorNotRepeatedOptions,
    ): AksiaValidatorFn =>
    (value?: unknown) => {
      let collectionValues =
        this.delegatePlainSignalFn<Array<string | number>>(collection);

      if (
        UTILS.OBJECT.isNil(collectionValues) ||
        collectionValues?.length === 0
      )
        return new AksiaValidatorResult();

      return collectionValues?.filter((v) => v === value).length! > 1 &&
        value !== options?.excludeValue
        ? AksiaValidators.generateMessage(options!, {
            error: 'This value should not be repeated',
            warning: 'This value is usually not repeated',
          })
        : new AksiaValidatorResult();
    };

  public static readonly userDefined =
    (
      userDefinedFn: (value: unknown) => {
        isInvalid: boolean;
        message?: string;
      },
      options?: AksiaValidatorOptions,
    ): AksiaValidatorFn =>
    (value: unknown) => {
      const result = new AksiaValidatorResult();

      let { isInvalid, message } = userDefinedFn(value);

      return isInvalid
        ? AksiaValidators.generateMessage(options!, {
            error: message ?? 'There are errors for this value',
            warning: message ?? 'There are warnings for this value',
          })
        : result;
    };
}
