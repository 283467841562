import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from '../icon/icon.component';
import { CopyDirective } from '../../../directives/copy.directive';
import { ICONS, StateEnum } from '@aksia/infrastructure';

@Component({
    selector: 'label',
    imports: [CommonModule, IconComponent, CopyDirective],
    templateUrl: './label.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabelComponent {
  //#region Inputs/Outputs

  label = input<string>();
  labelTip = input<string>();
  state = input<StateEnum>();

  //#endregion

  //#region Properties

  protected readonly ICONS = ICONS;

  //#endregion
}
