import { TViewMapItem, UTILS } from '@aksia/infrastructure';
import { AssetTypeEnum } from '../enums/entity.enum';

//#region O2 Views

//TViewLayout
export const ASSET_TEAM_VIEWS = {
  LEGAL_DOC: 'Legal Doc',
  DATA: 'Data',
  INVESTMENT_RESEARCH: 'Investment Research',
  RISK: 'Risk',
  CLIENT_OPS: 'Client Ops',
  CO_INVESTMENT: 'Co-Investment',
  SECONDARY: 'Secondary',
  toKeyValue: UTILS.KEYVALUE.toKeyValue,
  getKeyFromValue: UTILS.KEYVALUE.getKeyFromValue,
} as const;

type TAssetTeamViewCompleteType =
  (typeof ASSET_TEAM_VIEWS)[keyof typeof ASSET_TEAM_VIEWS];

export type AssetTeamViewType = Omit<
  TAssetTeamViewCompleteType,
  'toKeyValue' | 'getKeyFromValue'
>;

//TViewLayoutMap
export const ASSET_TEAM_VIEW_MAP: Array<
  TViewMapItem<AssetTypeEnum, { key: string; value: number | string }>
> = [
  {
    viewCategory: AssetTypeEnum.ManagementCompany,
    views: [],
  },
  {
    viewCategory: AssetTypeEnum.CoInvest,
    views: ASSET_TEAM_VIEWS.toKeyValue()?.filter(
      (view) => view.value !== ASSET_TEAM_VIEWS.SECONDARY,
    ),
  },
  {
    viewCategory: AssetTypeEnum.Secondary,
    views: ASSET_TEAM_VIEWS.toKeyValue()?.filter(
      (view) => view.value !== ASSET_TEAM_VIEWS.CO_INVESTMENT,
    ),
  },
  ...AssetTypeEnum.toOptions()
    ?.filter(
      (option) =>
        ![AssetTypeEnum.CoInvest, AssetTypeEnum.Secondary].includes(
          option.label as AssetTypeEnum,
        ),
    )
    .map((option) => ({
      viewCategory: option.label as AssetTypeEnum,
      views: ASSET_TEAM_VIEWS.toKeyValue()?.filter(
        (viewLayout) =>
          viewLayout.value !== ASSET_TEAM_VIEWS.CO_INVESTMENT &&
          viewLayout.value !== ASSET_TEAM_VIEWS.SECONDARY,
      ),
    })),
] as const;

export type AssetViewMap = Array<
  TViewMapItem<AssetTypeEnum, { key: string; value: number | string }>
>;

export const DEFAULT_VIEW_BY_ROLE = {
  LEGAL_DOC: ASSET_TEAM_VIEWS.LEGAL_DOC,
  DATA: ASSET_TEAM_VIEWS.DATA,
  INVESTMENT_RESEARCH: ASSET_TEAM_VIEWS.INVESTMENT_RESEARCH,
  RISK: ASSET_TEAM_VIEWS.RISK,
  CLIENT_OPS: ASSET_TEAM_VIEWS.CLIENT_OPS,
  ADMIN: ASSET_TEAM_VIEWS.DATA,
} as const;

export type DefaultViewByRoleType = typeof DEFAULT_VIEW_BY_ROLE;

//#endregion
