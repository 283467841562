import {
  ChangeDetectionStrategy,
  Component,
  input,
  HostBinding,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { PROVIDED_ICONS, IconType, IconSizeType } from '@aksia/infrastructure';

@Component({
  selector: 'icon',
  imports: [CommonModule, NgIconComponent],
  providers: [provideIcons(PROVIDED_ICONS)],
  templateUrl: './icon.component.html',
  styles: `
    :host {
      * {
        pointer-events: none;
      }
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent {
  //#region Inputs/Outputs

  iconDefinition = input<IconType | undefined>(undefined);
  iconSize = input<string, number | IconSizeType>('14', {
    transform: (value: number | IconSizeType) => {
      switch (value) {
        case 'smaller':
          return '10';
        case 'small':
          return '12';
        case 'normal':
          return '14';
        case 'large':
          return '16';
        case 'x-large':
          return '20';
        case '2x-large':
          return '24';
        case '3x-large':
          return '30';
        default:
          return `${value}`;
      }
    },
  });

  color = input<string | undefined>(undefined, { alias: 'iconColor' });

  renderInGrid = input<boolean | undefined>(undefined);
  //#endregion

  //#region Host Bindings

  @HostBinding('style.height.px') get iconHeight() {
    return this.iconSize();
  }

  //#endregion
}
