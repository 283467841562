import {
  ChangeDetectionStrategy,
  Component,
  computed,
  ElementRef,
  HostBinding,
  inject,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationService, LayoutService } from '@aksia/services';
import { IconComponent } from '../../controls/presentation/icon/icon.component';
import { ChipComponent } from '../../controls/presentation/chip/chip.component';
import { CheckComponent } from '../../controls/input/check/check.component';
import { DropdownComponent } from '../../controls/input/dropdown/dropdown.component';
import { CopyDirective } from '../../../lib/directives/copy.directive';
import { CONTROLS, ICONS, PropExtend, UTILS } from '@aksia/infrastructure';
import { ButtonComponent } from '../../controls/input/button/button.component';
import { SectionComponent } from '../../controls/layout/section/section.component';
import { TextComponent } from '../../controls/input/text/text.component';
import { SectionGroupComponent } from '../../controls/layout/section-group/section-group.component';
import { NumberComponent } from '../../controls/input/number/number.component';
import { ModalComponent } from '../../controls/layout/modal/modal.component';
import { AssetTypeEnum } from '@aksia/enums';
import { MultiDropdownComponent } from '../../controls/input/multi-dropdown/multi-dropdown.component';
import { UiControlDirective } from '../../directives/ui/ui-control.directive';
import { ASSET_TEAM_VIEWS } from '@aksia/models';

@Component({
  selector: 'page-header-view-designer',
  imports: [CommonModule],
  template: `
    <div UiPageHeader>
      <!-- <chip IconArea class="place-stretch">
        <icon [iconDefinition]="ICONS.sitemap" [iconSize]="'x-large'"></icon>
      </chip>
      <div TitleArea>
        <h2 class="r1s1" TitleText>View Designer</h2>
        <div Subtitle>Edit & design Views</div>
      </div>
      <div ControlsArea class="align-center justify-end">
        <btn
          class="primary"
          [settings]="{ label: 'Save' }"
          (click)="save()"
        ></btn>
        <dropdown
          [settings]="{
            label: 'View',
            options: navigation.views(),
          }"
          [initialValue]="navigation.selectedView()"
          (OptionIsSelected)="onSelectView($event)"
        ></dropdown>
        @if (navigation.selectedView()) {
          <btn [settings]="{ label: 'Normalize' }" (click)="normalize()"></btn>
        }
      </div> -->
    </div>
  `,
  styleUrls: ['./view-designer.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewDesignerHeaderPage extends UiControlDirective {
  view = inject(LayoutService);

  /* constructor() {
    super();
    this.view.loadViews();
  }

  protected onSelectView(view: any) {
    this.view.selectView(view.value);
  }

  save() {
    console.log('save');
  }

  normalize() {
    let selectedView = this.view
      .viewLayouts()
      .find(
        (viewSection) => viewSection.view === this.view.selectedView(),
      );
    selectedView?.viewEntityTypesAndSections.forEach((vet) => {
      vet.sections.forEach((section, index) => {
      });
    });
    this.view.viewLayouts.update((viewSections) => {
      viewSections.forEach((viewSection) => {
        if (viewSection.view === selectedView?.view) {
          viewSection = selectedView;
        }
      });
      return viewSections;
    });
  } */
}

@Component({
  selector: 'page-json-view-designer',
  imports: [CommonModule],
  template: `
    <!-- <section class="c1s1" [settings]="{ label: 'JSON schema' }">
      @if (selectedViewSchema()) {
        <pre
          #jsonViewer
          [UiCopy]="selectedViewSchema() | json"
          [innerText]="selectedViewSchema() | json"
        ></pre>
      }
    </section> -->
  `,
  styleUrls: ['./view-designer.page.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ViewDesignerJsonPage {
  /* navigation = inject(NavigationService);

  protected selectedViewSchema = computed(
    () =>
      this.navigation
        .viewLayouts()
        ?.find(
          (viewSection) => viewSection.view === this.navigation.selectedView(),
        )?.viewEntityTypesAndSections,
  );

  @ViewChild('jsonViewer', { read: ElementRef })
  jsonViewer!: ElementRef; */
}

@Component({
  selector: 'page-view-designer',
  imports: [CommonModule],
  template: `
    <!-- @if (selectedViewSchema()) {
      <section
        [settings]="{
          headerIsHidden: true,
          content: { cols: 1 },
        }"
      >
        <section
          [settings]="{
            headerIsHidden: true,
            content: { cols: 1, rowGap: 10 },
          }"
        >
          @for (
            viewEntityTypesAndSections of selectedViewSchema();
            track $index
          ) {
            <section
              [settings]="{
                label: viewEntityTypesAndSections.viewEntityType,
                isMinimized: true,
                content: { cols: 1, rowGap: 10 },
              }"
            >
              <icon
                uitool
                [iconSize]="'normal'"
                [iconDefinition]="ICONS.addSimple"
                class="labelInfo"
                (click)="addSection(viewEntityTypesAndSections.viewEntityType)"
              ></icon>
              @for (
                section of viewEntityTypesAndSections.sections;
                track $index;
                let isFirst = $first, isLast = $last
              ) {
                <section
                  [settings]="{
                    label: section.settings?.label,
                    isMinimized: true,
                    content: { cols: 1, rowGap: 1 },
                  }"
                >
                  @if (!isFirst) {
                    <icon
                      uitool
                      [title]="'move up'"
                      [iconSize]="'normal'"
                      [iconDefinition]="ICONS.sortAZ"
                      class="labelInfo"
                      (click)="
                        updateSectionOrder(
                          -1,
                          viewEntityTypesAndSections.viewEntityType,
                          section
                        )
                      "
                    ></icon>
                  }
                  @if (!isLast) {
                    <icon
                      uitool
                      [title]="'move down'"
                      [iconSize]="'normal'"
                      [iconDefinition]="ICONS.sortZA"
                      class="labelInfo"
                      (click)="
                        updateSectionOrder(
                          1,
                          viewEntityTypesAndSections.viewEntityType,
                          section
                        )
                      "
                    ></icon>
                  }
                  <icon
                    uitool
                    [title]="'copy'"
                    [iconSize]="'normal'"
                    [iconDefinition]="ICONS.copy"
                    class="labelInfo"
                    (click)="
                      uiCopyModalRef.open(
                        'Copy Section ' + section.settings?.label,
                        undefined,
                        true
                      )
                    "
                  >
                  </icon>
                  <icon
                    uitool
                    [title]="'remove'"
                    [iconSize]="'normal'"
                    [iconDefinition]="ICONS.removeSimple"
                    class="labelInfo"
                    (click)="
                      uiRemoveModalRef.open(
                        section.settings?.label,
                        'Are you sure you want to remove this section?',
                        true
                      )
                    "
                  >
                  </icon>
                  <section-group
                    class="c1s1"
                    [settings]="{ label: 'Settings' }"
                  >
                    <text
                      class="c1s1"
                      [settings]="{ label: 'Label' }"
                      [initialValue]="section.settings?.label"
                      (ValueChanged)="
                        updateSection($event, section, 'settings.label')
                      "
                    ></text>
                    <check
                      class="c3s1"
                      [settings]="{ label: 'Header Is Hidden', isToggle: true }"
                      [initialValue]="section.settings?.headerIsHidden"
                      (ValueChanged)="
                        updateSection(
                          $event,
                          section,
                          'settings.headerIsHidden'
                        )
                      "
                    ></check>
                    <number
                      class="r2s1 c1s1"
                      [settings]="{ label: 'Columns' }"
                      [initialValue]="section.settings?.content?.cols"
                      (ValueChanged)="
                        updateSection($event, section, 'settings.content.cols')
                      "
                    ></number>
                    <number
                      class="r2s1 c2s1"
                      [settings]="{ label: 'Rows' }"
                      [initialValue]="section.settings?.content?.rows"
                      (ValueChanged)="
                        updateSection($event, section, 'settings.content.rows')
                      "
                    ></number>
                    <number
                      class="r2s1 c3s1"
                      [settings]="{ label: 'Column Gap' }"
                      [initialValue]="section.settings?.content?.colGap"
                      (ValueChanged)="
                        updateSection(
                          $event,
                          section,
                          'settings.content.colGap'
                        )
                      "
                    ></number>
                    <number
                      class="r2s1 c4s1"
                      [settings]="{ label: 'Row Gap' }"
                      [initialValue]="section.settings?.content?.rowGap"
                      (ValueChanged)="
                        updateSection(
                          $event,
                          section,
                          'settings.content.rowGap'
                        )
                      "
                    ></number>
                  </section-group>
                  <section-group
                    class="c1s1"
                    [settings]="{
                      label: 'Content',
                    }"
                  >
                    <dropdown
                      class="c1s8"
                      [settings]="{
                        label: 'Render',
                        options: [
                          { label: 'Mark up', value: 'markup' },
                          { label: 'Component', value: 'component' },
                        ],
                      }"
                      [initialValue]="section.render ?? 'markup'"
                      (ValueChanged)="updateSection($event, section, 'render')"
                    ></dropdown>
                    @if (section.render === 'markup' || !section.render) {
                      <text
                        class="c9s3"
                        [settings]="{ label: 'Template Id' }"
                        [initialValue]="section.settings?.label"
                        (ValueChanged)="
                          updateSection($event, section, 'renderTemplateId')
                        "
                      ></text>
                    } @else if (section.render === 'component') {
                      <btn
                        class="c9s1"
                        disabled
                        [settings]="{ label: 'Add Control' }"
                      ></btn>
                    }
                  </section-group>

                  <span style="margin-bottom: 200px"></span>
                </section>
              }
            </section>
          }
        </section>
      </section>

      <modal
        #uiRemoveModalRef
        [text]="'Are you sure you want to remove this section?'"
        [settings]="{
          label: 'Remove Section',
          hasBackdrop: true,
        }"
      >
        <div UiModalFooter>
          <btn
            [settings]="{ label: 'Yes' }"
            (click)="applyRemoveSection(uiRemoveModalRef.data)"
          ></btn>
          <btn
            [settings]="{ label: 'No' }"
            (click)="cancelRemoveSection()"
          ></btn>
        </div>
      </modal>

      <modal
        #uiCopyModalRef
        [settings]="{
          hasBackdrop: true,
        }"
      >
        <section
          UiModalContent
          [settings]="{
            headerIsHidden: true,
            content: { cols: 2 },
          }"
        >
          <multi-dropdown
            class="c1s2"
            #viewsDD
            [settings]="{
              label: 'Views',
              options: views.toKeyValue(),
              optionLabel: 'value',
              optionValue: 'value',
              popup: { maxHeight: 140 },
            }"
          ></multi-dropdown>
          <multi-dropdown
            class="c1s2"
            #typesDD
            [settings]="{
              label: 'Types',
              options: types,
            }"
          ></multi-dropdown>
          <number
            class="c1s1"
            #orderNum
            [settings]="{
              label: 'Order',
              onlyWholeNumbers: true,
              permitZero: true,
            }"
            [initialValue]="0"
          ></number>
          <check
            class="c2s1 center"
            #directionChk
            [settings]="{ isToggle: true, label: 'From the end' }"
            [initialValue]="false"
          ></check>
        </section>

        <div UiModalFooter>
          <btn
            [settings]="{ label: 'Copy' }"
            (click)="
              applyCopySection(
                uiCopyModalRef.data,
                viewsDD,
                typesDD,
                orderNum,
                directionChk
              )
            "
          ></btn>
          <btn
            [settings]="{ label: 'Cancel' }"
            (click)="cancelCopySection()"
          ></btn>
        </div>
      </modal>
    } -->
  `,
  styleUrls: ['./view-designer.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewDesignerPage extends UiControlDirective {
  /* navigation = inject(NavigationService);

  @HostBinding('[attr.UiPage]') page = true;

  @ViewChild('jsonViewer', { read: ElementRef })
  jsonViewer!: ElementRef;

  @ViewChild('uiRemoveModalRef') uiRemoveModalRef!: ModalComponent;
  @ViewChild('uiCopyModalRef') uiCopyModalRef!: ModalComponent;

  protected readonly controls = CONTROLS;
  protected readonly views = ASSET_TEAM_VIEW_LAYOUTS;
  protected readonly types = AssetTypeEnum.toOptions();

  protected selectedViewSchema = computed(
    () =>
      this.navigation
        .viewLayouts()
        ?.find(
          (viewSection) => viewSection.view === this.navigation.selectedView(),
        )?.viewEntityTypesAndSections,
  );

  protected addSection(selectedViewEntityType: string) {
    this.navigation.viewLayouts.update((viewSections) => {
      let viewSection = viewSections.find(
        (vs) => vs.view === this.navigation.selectedView(),
      );
      if (viewSection) {
        let viewEntityType = viewSection.viewEntityTypesAndSections.find(
          (iv) => iv.viewEntityType === selectedViewEntityType,
        );
        viewEntityType?.sections.push({
          settings: { label: '' },
          order: viewEntityType.sections.length,
        });
      }
      return viewSections;
    });
  }

  protected updateSection(
    value: unknown,
    section: PropExtend<IUiSectionSpec>,
    field: string,
  ) {
    if (!field.includes('.')) {
      section[field] = value;
    } else {
      UTILS.OBJECT.assignToPath(section, field, value);
    }
  }

  protected updateSectionOrder(
    direction: number,
    selectedViewEntityType: string,
    selectedSection: PropExtend<IUiSectionSpec>,
  ) {
    this.navigation.viewLayouts.update((viewSections) => {
      let viewSection = viewSections.find(
        (vs) => vs.view === this.navigation.selectedView(),
      );
      if (viewSection) {
        let viewEntityType = viewSection.viewEntityTypesAndSections.find(
          (iv) => iv.viewEntityType === selectedViewEntityType,
        );
        if (viewEntityType) {
          let oldIndex = viewEntityType.sections.indexOf(selectedSection);
          selectedSection.order = selectedSection.order + direction;
          viewEntityType.sections[oldIndex + direction].order =
            selectedSection.order - direction;
          viewEntityType.sections = viewEntityType.sections.sort(
            (a, b) => a.order! - b.order!,
          );
        }
      }
      return viewSections;
    });
  }

  protected applyCopySection(
    selectedSection: PropExtend<unknown>,
    viewDD: PropExtend<unknown>,
    typeDD: PropExtend<unknown>,
    orderNum: PropExtend<unknown>,
    directionChk: PropExtend<unknown>,
  ) {
    let section = selectedSection as IUiSectionSpec;
    let views = viewDD.value();
    let types = typeDD.value();
    let order = orderNum.value();
    let direction = directionChk.value();

    this.navigation.viewLayouts.update((viewSections) => {
      viewSections.forEach((viewSection) => {
        if (views.includes(viewSection.view)) {
          viewSection.viewEntityTypesAndSections.forEach((vets) => {
            if (types.includes(vets.viewEntityType)) {
              let sectionExists = vets.sections.find(
                (s) => s.settings?.label === section.settings?.label,
              );
              if (!sectionExists) {
                vets.sections.splice(
                  direction ? vets.sections.length - order : order,
                  0,
                  { ...section },
                );
                vets.sections.forEach((section, index) => {
                  section.order = index;
                });
              }
            }
          });
        }
      });
      return viewSections;
    });

    this.uiCopyModalRef.close();
  }

  protected cancelCopySection() {
    this.uiCopyModalRef.close();
  }

  protected applyRemoveSection(selectedSection: PropExtend<unknown>) {
    this.navigation.viewLayouts.update((viewSections) => {
      let viewSection = viewSections.find(
        (vs) => vs.view === this.navigation.selectedView(),
      );
      if (viewSection) {
        viewSection.viewEntityTypesAndSections.forEach((viewEntityType) => {
          let index = viewEntityType.sections.indexOf(selectedSection);
          if (index > -1) {
            viewEntityType.sections.splice(index, 1);
            viewEntityType.sections.forEach((section, index) => {
              section.order = index;
            });
          }
        });
      }
      return viewSections;
    });
    this.uiRemoveModalRef.close();
  }

  protected cancelRemoveSection() {
    this.uiRemoveModalRef.close();
  } */
}
