import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  input,
  Input,
  model,
  Output,
  signal,
  TemplateRef,
  viewChild,
  ViewChild,
  ViewContainerRef,
  WritableSignal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from '../../presentation/icon/icon.component';
import { fromEvent, Observable, Subject, takeUntil } from 'rxjs';
import { UiControlDirective } from '../../../directives/ui/ui-control.directive';
import {
  IModalSettings,
  ModalSettings,
  UI_POPUP_HIDE_STRATEGY,
  UTILS,
} from '@aksia/infrastructure';

@Component({
    selector: 'modal',
    imports: [CommonModule, IconComponent],
    templateUrl: './modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalComponent extends UiControlDirective {
  //#region Inputs/Outputs

  override settings = input(new ModalSettings(), {
    transform: (settings: IModalSettings) => this.initSettings(settings),
  });

  @Input() text?: string;
  bind = model();
  @Input() UiModalContentTemplateRef!: TemplateRef<any>;
  @Input() UiModalFooterTemplateRef!: TemplateRef<any>;

  @Output() ModalIsClosed: EventEmitter<never> = new EventEmitter<never>();

  //#endregion

  //#region Host Bindings

  @HostBinding('style.display') get display() {
    return this.isOpen() ? 'block' : 'none';
  }

  @HostListener('document:keydown.escape', ['$event']) onEscHandler(
    event: KeyboardEvent,
  ) {
    this.close();
  }
  //#endregion

  //#region View Children

  @ViewChild('UiModalContentCmps', { read: ViewContainerRef })
  uiModalContentCmps!: ViewContainerRef;
  @ViewChild('UiModalFooterCmps', { read: ViewContainerRef })
  uiModalFooterCmps!: ViewContainerRef;
  /* @ViewChild('modal') modal!: ElementRef; */

  modal = viewChild('modal', { read: ElementRef });

  //#endregion

  //#region Properties

  protected title: WritableSignal<string | undefined> = signal(undefined);
  modalListener$?: Observable<any>;
  modalClosed$: Subject<boolean> = new Subject<boolean>();
  data?: unknown;

  //#endregion
  //#region Functions

  protected override initSettings(settings: IModalSettings) {
    let modalSettings = UTILS.OBJECT.assign(new ModalSettings(), settings);
    super.initSettings(modalSettings);
    return modalSettings;
  }

  private enableCloseOnBackdrop(enable: boolean) {
    const listener = (event: MouseEvent) => {
      const isInDialog = this.dialogContainsTarget(event);
      if (!isInDialog) {
        this.close();
      }
    };

    if (enable) {
      this.modal()?.nativeElement?.addEventListener('click', listener);
    } else {
      this.modal()?.nativeElement?.removeEventListener('click', listener);
    }
  }

  private dialogContainsTarget(event: MouseEvent) {
    const dialog = this.modal()?.nativeElement;

    if (!dialog) {
      return;
    }

    // Get dialog's bounding rectangle
    const rect = dialog.getBoundingClientRect();
    // Check if click is outside the dialog
    return (
      rect.top <= event.clientY &&
      event.clientY <= rect.bottom &&
      rect.left <= event.clientX &&
      event.clientX <= rect.right
    );
  }

  private updateSettings() {
    this.title.set(this.settings().label);
  }

  public isOpen(): boolean {
    let dialog = this.modal()?.nativeElement as HTMLDialogElement;
    return dialog?.hasAttribute('open');
  }

  public open(
    overrideTitle?: string,
    overrideText?: string,
    hasBackdrop?: boolean,
    bind?: unknown,
  ) {
    let dialog = this.modal()?.nativeElement as HTMLDialogElement;

    if (overrideTitle) {
      this.label.set(overrideTitle);
    }

    if (overrideText) {
      this.text = overrideText;
    }

    if (hasBackdrop || this.settings().hasBackdrop) {
      dialog.showModal();
    } else {
      dialog.show();
    }

    if (this.settings().hasBackdrop) {
      this.enableCloseOnBackdrop(true);
    }

    if (bind) {
      this.bind.set(bind);
    }

    if (this.settings().hideStrategy !== UI_POPUP_HIDE_STRATEGY.ClickTrigger) {
      if (!this.modalListener$) {
        this.modalListener$ = fromEvent(document, 'click');
        this.modalListener$
          .pipe(takeUntil(this.modalClosed$))
          .subscribe((event: MouseEvent) => {
            if (
              (this.settings().hideStrategy === 'click_outside' &&
                !this.dialogContainsTarget(event)) ||
              this.settings().hideStrategy === 'click_anywhere' ||
              this.settings().hideStrategy === 'click_outside_or_option' ||
              this.settings().hideStrategy === 'click_outside_or_trigger'
            ) {
              if (dialog.hasAttribute('open')) {
                this.close();
                return;
              }
            }
          });
      }
      event?.stopPropagation();
    }
  }

  public close() {
    let dialog = this.modal()?.nativeElement as HTMLDialogElement;
    dialog.close();
    this.modalClosed$.next(true);
    this.ModalIsClosed.emit();
    this.modalListener$ = undefined;
    this.enableCloseOnBackdrop(false);
  }

  //#endregion
}
