import { SimpleAnswerEnum } from '../../enums/entity.enum';
import { RankEnum } from '../../enums/closedEnd.enum';
import { IClosedEndGPInformation } from '../../interfaces/investment-vehicle/closed-end-gp-information.interface';
import { IUiOption } from '@aksia/infrastructure';

export class ClosedEndGPInformation {
  closedEndDataFieldsId?: number = undefined;

  //#region Structure

  thirdPartyManagement?: SimpleAnswerEnum = undefined;

  //#endregion

  //#region GP Information

  wmbe?: SimpleAnswerEnum = undefined;

  //#endregion

  //#region Key Considerations

  commingledFundByManager?: RankEnum = undefined;
  commingledFundInSeries?: RankEnum = undefined;

  //#endregion
}

/* @MetaClass({
  audit: {
    url: 'basicdata/fund/audit/{0}/closedend/{1}/closedEndGPInformation/{2}',
    urlParams: ['grandParent.fundId@model', 'parent.id@model', '@prop'],
  },
}) */
export class ClosedEndGPInformationMeta implements IClosedEndGPInformation {
  closedEndDataFieldsId?: number;

  //#region Structure

  /* @MetaField({
    ui: {
      label: '3rd Party Management Company Interest',
      options: SimpleAnswerEnum.toOptions().filter(
        (sa: IUiOption) => sa.value !== SimpleAnswerEnum.NotSpecified,
      ),
    },
  }) */
  thirdPartyManagement?: SimpleAnswerEnum;

  //#endregion

  //#region GP Information

  /* @MetaField({
    ui: {
      label: 'MWBE',
      options: SimpleAnswerEnum.toOptions().filter(
        (sa: IUiOption) => sa.value !== SimpleAnswerEnum.NotSpecified,
      ),
    },
  }) */
  wmbe?: SimpleAnswerEnum;

  //#endregion

  //#region Key Considerations

  /* @MetaField({
    ui: {
      label: 'Commingled Fund by Manager',
      options: RankEnum.toOptions().filter(
        (sa: IUiOption) => sa.value !== RankEnum._4th,
      ),
    },
  }) */
  commingledFundByManager?: RankEnum;

  /* @MetaField({
    ui: {
      label: 'Commingled Fund in Series',
      options: RankEnum.toOptions().filter(
        (sa: IUiOption) => sa.value !== RankEnum._4th,
      ),
    },
  }) */
  commingledFundInSeries?: RankEnum;

  //#endregion

  modifiedBy?: string;
}
