import { SimpleAnswerEnum } from '../../enums/entity.enum';
import {
  CloseDateTypeEnum,
  CommitmentsTypeEnum,
  PeriodTypeEnum,
  RateIndexEnum,
  SubClInterestIndexEnum,
  MonthEnum,
} from '../../enums/closedEnd.enum';

export class ClosedEndKeyTerm {
  closedEndDataFieldsId?: number = undefined;

  //#region Details Date Info

  effectiveDateType?: CloseDateTypeEnum = undefined;
  effectiveDate?: Date = undefined;
  effectiveDateDesc?: string = undefined;

  //#endregion

  //#region Capital Call Procedure / Capital Raise / Fund Terms

  commitmentTarget?: number = undefined;
  hasCommitmentCap?: SimpleAnswerEnum = undefined;
  commitmentCap?: number = undefined;
  commitmentsActual?: number = undefined;
  commitmentsActualType?: CommitmentsTypeEnum = undefined;
  commitmentsAsOf?: Date = undefined;
  placementAgent?: string = undefined;
  subsequentCloseInterest?: SimpleAnswerEnum = undefined;
  subsequentCloseInterestType?: RateIndexEnum = undefined;
  subsequentCloseInterestIndex?: SubClInterestIndexEnum = undefined;
  subsequentCloseInterestIndexDesc?: string = undefined;
  subsequentCloseInterestRate?: number = undefined;

  //#endregion

  //#region Fund Timing

  contractualInvestmentPeriodExpirationYears?: number = undefined;
  contractualInvestmentPeriodExpirationType?: PeriodTypeEnum = undefined;
  contractualInvestmentPeriodExpirationDate?: Date = undefined;
  contractualInvestmentPeriodExpirationDesc?: string = undefined;
  hasContractualInvestmentPeriodUnlimitedExtensions?: boolean = undefined;
  contractualTermExpirationYears?: number = undefined;
  contractualTermExpirationType?: PeriodTypeEnum = undefined;
  contractualTermExpirationDate?: Date = undefined;
  contractualTermExpirationDesc?: string = undefined;
  hasContractualTermUnlimitedExtensions?: boolean = undefined;

  //#endregion

  //#region Recycling

  hasRecycling?: SimpleAnswerEnum = undefined;
  hasTimeLimit?: SimpleAnswerEnum = undefined;
  hasRecyclingLimit?: SimpleAnswerEnum = undefined;
  recyclingCommitmentsRate?: number = undefined;
  hasProceedsLimit?: SimpleAnswerEnum = undefined;

  //#endregion

  //#region Target Return Profile

  capitalCallLine?: SimpleAnswerEnum = undefined;

  //#endregion

  //#region Structure

  agmTiming?: MonthEnum = undefined;

  //#endregion

  //#region Fund Terms

  oversubscribedFundRaise?: SimpleAnswerEnum = undefined;
  fundCommitmentsTotal?: number = undefined;
  fundCommitmentsLP?: number = undefined;
  fundCommitmentsGP?: number = undefined;

  //#endregion

  //Already exist in meta?
  isDeleted?: boolean = undefined;
  modifiedBy?: string = undefined;
  modifiedOn?: Date = undefined;
}
