import { UTILS } from '@aksia/infrastructure';

export enum DealDynamicEnum {
  Competitive = 1,
  LimitedProcess,
  Proprietary,
}
export namespace DealDynamicEnum {
  const replace = [{ key: /([a-z])([A-Z])/g, value: '$1 $2' }];
  export const toOptions = () =>
    UTILS.UI.enumToOptions(DealDynamicEnum, replace);
}

export enum DealTypeSecondaryEnum {
  LP_Sale = 1,
  Fund_Restructuring,
  Continuation_Vehicle,
}
export namespace DealTypeSecondaryEnum {
  const replace = [{ key: /_/g, value: ' ' }];
  export const toOptions = () =>
    UTILS.UI.enumToOptions(DealTypeSecondaryEnum, replace);
}

export enum AssetModeEnum {
  Viewing = 'isViewing',
  Editing = 'isEditing',
  Linking = 'isLinking',
}
