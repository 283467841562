<div area="logo">
  <img src="assets/aksia_logo.svg" />
</div>
<div area="separator"></div>
<div area="app-tools">
  <dropdown
    class="w-[400px]"
    #searchDD
    [popupCssClass]="'w-auto left-0 whitespace-nowrap'"
    [settings]="{
      placeholder: 'Search for Funds and Companies',
      optionLabel: 'name',
      optionValue: 'entityId',
      optionsFiltering: false,
      optionsSorting: false,
      optionsAsyncFn: search.searchEntities,
      selectedOptionStrategy: 'none',
    }"
    [UiOptionTemplateRef]="customUiOptionTmpl"
    (OptionIsSelected)="visitEntity($event)"
  >
  </dropdown>
  <ng-template #customUiOptionTmpl let-option>
    <chip
      class="asset-class w-[30px] h-[30px]"
      [class]="option.assetClass?.replaceAll(' ', '') || 'default'"
    >
      @if (!option.assetClass) {
        <icon
          [iconSize]="'normal'"
          [iconDefinition]="
            option.entityTypeId === entityTypes.ManagementCompany
              ? ICONS.company
              : ICONS.fund
          "
        >
        </icon>
      }
    </chip>

    <span [innerHtml]="option.name"></span>
    <!-- <span [innerHtml]="option.value | highlight:searchDD.query"></span> -->
  </ng-template>
</div>
<div area="common-tools">
  <icon [iconDefinition]="'faSolidBell'" [iconSize]="'x-large'"></icon>
  <icon [iconDefinition]="'faSolidGear'" [iconSize]="'x-large'"></icon>
  <btn
    class="!border-none"
    [settings]="{
      label: 'APOSTOLOS',
      icon: 'faSolidCircleUser',
      iconSize: 'x-large',
    }"
    (click)="uiModalRef.open()"
  >
  </btn>
</div>

<modal
  #uiModalRef
  [settings]="{
    hasBackdrop: true,
  }"
>
  <div UiModalHeader>
    <h1>Impersonate</h1>
  </div>
  <div UiModalContent class="h-[300px] w-[500px]">
    <dropdown
      #impersonateDD
      [value]="auth.user()!.impersonate"
      [settings]="{
        optionLabel: 'value',
        optionValue: 'value',
        optionsFiltering: false,
        options: ROLES.toKeyValue(),
      }"
      [popupCssClass]="'h-[200px]'"
    ></dropdown>
  </div>
  <div UiModalFooter>
    <btn
      [settings]="{ label: 'Cancel' }"
      (click)="
        impersonateDD.value.set(auth.user()!.impersonate); uiModalRef.close()
      "
    ></btn>
    <btn
      [settings]="{ label: 'Save & Refresh' }"
      (click)="
        auth.impersonate?.(impersonateDD.value());
        uiModalRef.close();
        window.location.reload()
      "
    ></btn>
  </div>
</modal>
