import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'propsJoin',
  standalone: true
})
export class PropsJoinPipe implements PipeTransform {

  transform(value: any, returnFields: Array<string>): unknown {
    let output: string = "";
    if (value){
      for (let i: number = 0; i < returnFields.length; i++){
        output += (i > 0) && value[returnFields[i]] && output[output.length - 1] ? `, ${value[returnFields[i]]}` : value[returnFields[i]] ? `${value[returnFields[i]]}` : "";
      }
      return output;
    }
    else return ""; 
  }
}
