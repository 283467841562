import { Injectable, inject } from '@angular/core';
import { ApiService } from '../../infrastructure/api/api.service';
import { Observable, concat, concatMap, of, toArray } from 'rxjs';
import {
  IAUMStreamPoint,
  IPeriodicStream,
  IPeriodicStreamPoint,
  PERIODIC_STREAMS,
  PERIODIC_STREAM_REQUESTS,
  PERIODIC_STREAM_URIS,
  PeriodicStreamRequest,
  PeriodicStreamRequestOptions,
  PeriodicStreamType,
} from '@aksia/models';
import { EntityTypeEnum, StreamPeriodicityEnum } from '@aksia/enums';
import { IErrorResult } from '@aksia/infrastructure';
import { PropExtend } from '@aksia/infrastructure';

@Injectable({
  providedIn: 'root',
})
export class PeriodicStreamService {
  private api = inject(ApiService);

  public updateStream(
    entityId: number,
    stream: Array<IPeriodicStreamPoint>,
    periodicStreamType: PeriodicStreamType,
    periodicity: StreamPeriodicityEnum = StreamPeriodicityEnum.Monthly,
  ): Observable<Array<IPeriodicStreamPoint | IErrorResult>> {
    return this.api.put(
      `periodicdata/${periodicStreamType}/${entityId}/stream/edit?periodicity=${periodicity}`,
      stream,
    );
  }

  public getStreams(
    requestOptions: PeriodicStreamRequestOptions,
  ): Observable<Array<IPeriodicStream>> {
    const streams$: Array<Observable<unknown>> = [];
    requestOptions.streamTypes = [
      ...(requestOptions.streamTypes.filter(
        (streamType) => !streamType.includes(','),
      ) as Array<PeriodicStreamType>),
      ...(requestOptions.streamTypes
        .filter((streamType) => streamType.includes(','))
        .flatMap((streamType) =>
          streamType.split(','),
        ) as Array<PeriodicStreamType>),
    ];
    requestOptions.streamTypes.forEach((streamType) => {
      let uri = PERIODIC_STREAM_URIS.getURI({
        entityId: requestOptions.entityId,
        entityTypeId: requestOptions.entityTypeId,
        streamType: streamType as PeriodicStreamType,
        request: requestOptions.streamRequest,
        uriPrefix:
          requestOptions.entityTypeId === EntityTypeEnum.ManagementCompany
            ? 'latestForEntityTree'
            : 'latest',
        periodiciy: StreamPeriodicityEnum.Monthly.toString(),
        asOf: requestOptions.asOf,
      });
      streams$.push(this.api.get(uri!));
    });

    return concat(...streams$).pipe(
      toArray(),
      concatMap((results: Array<PropExtend<unknown>>) => {
        const streamsDTO: Array<IPeriodicStream> = [];
        if (
          requestOptions.streamTypes.includes(PERIODIC_STREAMS.PRIVATE_RETURNS)
        ) {
          requestOptions.streamTypes =
            PERIODIC_STREAMS.PRIVATE_RETURNS_EXPAND.split(
              ',',
            ) as Array<PeriodicStreamType>;
          results = PERIODIC_STREAMS.PRIVATE_RETURNS_EXPAND.split(',')?.map(
            (streamType, index) => {
              return results?.at(0)?.[streamType];
            },
          );
        }
        results?.forEach((result: PropExtend<unknown>, index) => {
          streamsDTO.push({
            streamType: requestOptions.streamTypes?.at(index)!,
            periodicity: StreamPeriodicityEnum.Monthly,
            datapoints: this.toPeriodicDataPoints(
              result,
              requestOptions.streamTypes?.at(index)!,
              requestOptions.streamRequest,
            ),
            ytd: result?.ytd,
          });
        });
        return of(streamsDTO);
      }),
    );
  }

  private toPeriodicDataPoints(
    result: PropExtend<unknown>,
    periodicStreamType: PeriodicStreamType,
    periodicStreamRequest: PeriodicStreamRequest,
  ): Array<IPeriodicStreamPoint> {
    switch (periodicStreamRequest) {
      case PERIODIC_STREAM_REQUESTS.MINMAX: {
        let maxPoint = {
          asOf: PERIODIC_STREAMS.AUM_EXPAND.includes(periodicStreamType)
            ? result?.asOfDate
            : periodicStreamType === PERIODIC_STREAMS.PUBLIC_RETURNS
              ? result?.max?.asOf
              : result?.max?.asOf,
          source:
            PERIODIC_STREAMS.AUM_EXPAND.includes(periodicStreamType) ||
            periodicStreamType === PERIODIC_STREAMS.PUBLIC_RETURNS
              ? result?.source
              : result?.max?.source,
          value: PERIODIC_STREAMS.AUM_EXPAND.includes(periodicStreamType)
            ? result?.amount
            : periodicStreamType === PERIODIC_STREAMS.PUBLIC_RETURNS
              ? result?.max?.value
              : result?.max?.value,
        };
        return [this.toPeriodicStreamPoint(maxPoint, periodicStreamType)];
      }
      case PERIODIC_STREAM_REQUESTS.ASOF:
        break;
      case PERIODIC_STREAM_REQUESTS.HISTORICAL:
        let datapoints = PERIODIC_STREAMS.AUM_EXPAND.includes(
          periodicStreamType,
        )
          ? result?.aum
          : result?.datapoints;
        return datapoints?.map((point: PropExtend<unknown>) =>
          this.toPeriodicStreamPoint(point, periodicStreamType),
        );
    }
    return [];
  }

  private toPeriodicStreamPoint<IPeriodicStreamPoint>(
    point: IPeriodicStreamPoint,
    periodicStreamType: PeriodicStreamType,
  ): IPeriodicStreamPoint {
    if (PERIODIC_STREAMS.AUM_EXPAND.includes(periodicStreamType)) {
      const { asOfDate, amount, ...restPoint } = point as IAUMStreamPoint;
      (point as IAUMStreamPoint) = {
        asOf: asOfDate,
        value: amount,
        ...restPoint,
      };
    }
    return point;
  }
}
