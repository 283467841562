import { Signal, signal } from '@angular/core';
import {
  IChipSettings,
  IButtonSettings,
  ICheckSettings,
  IDateSettings,
  IDropdownSettings,
  IFileUploadSettings,
  IModalSettings,
  INumberSettings,
  ISectionGroupSettings,
  ISectionSettings,
  ISpreadsheetSettings,
  ITextSettings,
  IUiControlSettings,
  IUiInputSettings,
  IUiLayoutSettings,
  IUiMultiSelectSettings,
  IUiPopupSettings,
  IUiSettings,
  IUiSpreadSheetCell,
  IUiOption,
  ILocationSettings,
  IUiLocationTag,
  IPageSettings,
  IMultiDropdownSettings,
  IRadioGroupSettings,
  IPageLayoutSettings,
  IPageAreaSettings,
} from '../interfaces';
import {
  UI_POPUP_HIDE_STRATEGY,
  TEXT_INPUT_TYPE,
  DATE_SELECTION,
  DROPDOWN_SELECTED_OPTION_STRATEGY,
  FILE_UPLOAD_UI_TYPE,
  SPREADSHEET_ALLOWED_VALUES,
  DateSelectionType,
  IconSizeType,
  TextInputType,
  DropdownSelectedOptionStrategyType,
  FileUploadUiType,
  UiPopupHideStrategyType,
  ICONS,
  IconType,
  ICON_SIZE,
} from '../types';

//#region Common

export class SettingsGlobal {
  static settingsUid: number = 0;
}

export class UiLayoutSettings implements IUiSettings {
  constructor() {
    this.instanceId = SettingsGlobal.settingsUid++;
  }
}

export interface UiLayoutSettings extends IUiLayoutSettings {}

export class UiControlSettings extends UiLayoutSettings implements IUiSettings {
  constructor() {
    super();
  }
}

export interface UiControlSettings extends IUiControlSettings {}

export class UiInputSettings
  extends UiControlSettings
  implements IUiInputSettings
{
  constructor() {
    super();
    this.placeholder = '';
    this.placeholderPersists = false;
    this.disabled = false;
  }
}

export interface UiInputSettings extends IUiInputSettings {}

export class UiPopupSettings extends UiControlSettings implements IUiSettings {
  static defaultMaxHeight: number = 250;
  static defaultPopupHideStrategy: UiPopupHideStrategyType =
    UI_POPUP_HIDE_STRATEGY.ClickAnywhere;

  constructor() {
    super();
    this.maxHeight = UiPopupSettings.defaultMaxHeight;
    this.hideStrategy = UiPopupSettings.defaultPopupHideStrategy;
  }
}

export interface UiPopupSettings extends IUiPopupSettings {}

//#endregion

//#region Ui Directives

export class UiMultiSelectSettings implements IUiSettings {
  constructor() {
    this.listField = 'options';
  }
}

export interface UiMultiSelectSettings extends IUiMultiSelectSettings {}

//#endregion

//#region Input

export class ButtonSettings extends UiControlSettings implements IUiSettings {
  static defaultIconSize: IconSizeType = ICON_SIZE.Large;
  static defaultIconOnly: boolean = false;

  constructor() {
    super();
    this.iconSize = ButtonSettings.defaultIconSize;
    this.iconOnly = ButtonSettings.defaultIconOnly;
    this.disabled = false;
  }
}

export interface ButtonSettings extends IButtonSettings {}

export class CheckSettings extends UiInputSettings implements IUiSettings {
  static defaultIsToggle: boolean = true;

  constructor() {
    super();
    this.isToggle = CheckSettings.defaultIsToggle;
  }
}
export interface CheckSettings extends ICheckSettings {}

export class RadioGroupSettings extends UiInputSettings implements IUiSettings {
  constructor() {
    super();
    this.defaultSelect = false;
    this.disabled = false;
    this.optionLabel = DropdownSettings.defaultOptionLabel;
    this.optionValue = DropdownSettings.defaultOptionValue;
    this.radioSize = '16px';
  }
}

export interface RadioGroupSettings extends IRadioGroupSettings {}

export class DateSettings extends UiInputSettings implements IUiSettings {
  static defaultSelection: DateSelectionType = DATE_SELECTION.Day;
  static defaultDayFormat: string = 'MM/dd/yyyy';
  static defaultMonthFormat: string = 'MMM - yyyy';
  static defaultYearFormat: string = 'yyyy';
  static defaultYearsInPage: number = 14;
  static defaultPopup: IUiPopupSettings = {
    hideStrategy: UI_POPUP_HIDE_STRATEGY.ClickOutside,
  };

  static defaultFormat(selection?: DateSelectionType): string {
    switch (selection) {
      case DATE_SELECTION.Day:
        return DateSettings.defaultDayFormat;
      case DATE_SELECTION.Month:
        return DateSettings.defaultMonthFormat;
      case DATE_SELECTION.Year:
        return DateSettings.defaultYearFormat;
      default:
        return DateSettings.defaultDayFormat;
    }
  }

  static defaultIcon(selection: DateSelectionType): IconType {
    switch (selection) {
      case DATE_SELECTION.Day:
        return ICONS.calendarDay;
      case DATE_SELECTION.Month:
        return ICONS.calendarMonth;
      case DATE_SELECTION.Year:
        return ICONS.calendarYear;
    }
  }

  constructor() {
    super();
    this.selection = DateSettings.defaultSelection;
    this.placeholder = DateSettings.defaultFormat(this.selection);
    this.format = DateSettings.defaultFormat(this.selection);
    this.yearsInPage = DateSettings.defaultYearsInPage;
    this.defaultIcon = DateSettings.defaultIcon(this.selection);
    this.popup = new UiPopupSettings();
    this.popup.hideStrategy = DateSettings.defaultPopup.hideStrategy;
  }
}

export interface DateSettings extends IDateSettings {}

export class DropdownSettings
  extends UiInputSettings
  implements IDropdownSettings
{
  static defaultOptionLabel: keyof IUiOption = 'label';
  static defaultOptionValue: keyof IUiOption = 'value';
  static defaultOptionDeleted: keyof IUiOption = 'isDeleted';
  static defaultSelectedOptionStrategy: DropdownSelectedOptionStrategyType =
    DROPDOWN_SELECTED_OPTION_STRATEGY.Marked;
  static defaultIcon: IconType = ICONS.chevronDown;
  static defaultPopup: IUiPopupSettings = {
    hideStrategy: UI_POPUP_HIDE_STRATEGY.ClickOutsideOrOption,
  };
  static defaultValueOutput: 'signal' | 'plain' = 'signal';

  constructor() {
    super();
    this.selectedOptionStrategy =
      DropdownSettings.defaultSelectedOptionStrategy;
    this.defaultIcon = DropdownSettings.defaultIcon;
    this.hideDefaultIcon = false;
    this.optionsSorting = true;
    this.optionsFiltering = true;
    this.optionsDebounce = 2000;
    this.popup = new UiPopupSettings();
    this.popup.hideStrategy = UI_POPUP_HIDE_STRATEGY.ClickOutsideOrOption;
    this.optionLabel = DropdownSettings.defaultOptionLabel;
    this.optionValue = DropdownSettings.defaultOptionValue;
    this.storeOnlyValue = true;
    this.showClearButton = true;
    this.allowMultiple = true;
    this.loadingIcon = ICONS.faSolidSpinner;
    this.valueOutput = DropdownSettings.defaultValueOutput;
  }
}

export class MultiDropdownSettings
  extends DropdownSettings
  implements IMultiDropdownSettings
{
  shrinkCount = 0;
  override placeholder = 'Select';
  override storeOnlyValue = false;
}

export interface DropdownSettings extends IDropdownSettings {}

export class LocationSettings extends UiInputSettings implements IUiSettings {
  static defaultMapTypeId: google.maps.MapTypeId =
    'roadmap' as google.maps.MapTypeId;
  static defaultControlSize: number = 25;
  static defaultValueFormat: string = 'address1,city,state,country';
  static defaultFieldMap: Array<{
    field: string;
    tags: Array<IUiLocationTag>;
  }> = [
    {
      field: 'address1',
      tags: [
        { tag: 'premise', writeMode: 'overwrite' },
        { tag: 'route', writeMode: 'overwrite' },
        { tag: 'street_number', writeMode: 'prepend' },
      ],
    },
    {
      field: 'city',
      tags: [
        { tag: 'postal_town', writeMode: 'overwrite' },
        { tag: 'locality', writeMode: 'overwrite' },
      ],
    },
    {
      field: 'state',
      tags: [{ tag: 'administrative_area_level_1', writeMode: 'overwrite' }],
    },
    {
      field: 'country',
      tags: [{ tag: 'country', writeMode: 'overwrite' }],
    },
  ];
  static defaultPopup?: IUiPopupSettings = {
    hideStrategy: UI_POPUP_HIDE_STRATEGY.ClickOutsideOrTrigger,
  };
  static defaultMap?: google.maps.MapOptions = {
    mapTypeId: 'roadmap',
    controlSize: 25,
  };

  constructor() {
    super();
    this.valueFormat = LocationSettings.defaultValueFormat;
    this.popup = { ...LocationSettings.defaultPopup };
    this.map = { ...LocationSettings.defaultMap };
  }
}

export interface LocationSettings extends ILocationSettings {}

export class FileUploadSettings extends UiInputSettings implements IUiSettings {
  static defaultUiType: FileUploadUiType = FILE_UPLOAD_UI_TYPE.Field;
  static defaultAllowMultipleFiles: boolean = false;
  static defaultAllowedFileTypes: string =
    '.pdf,.xls,.xlsx,.doc,.docx,.txt,.ppt,.pptx';
  static defaultIcon: IconType = ICONS.upload;

  constructor() {
    super();
    this.uiType = FileUploadSettings.defaultUiType;
    this.allowMultipleFiles = FileUploadSettings.defaultAllowMultipleFiles;
    this.allowedFileTypes = FileUploadSettings.defaultAllowedFileTypes;
    this.defaultIcon = FileUploadSettings.defaultIcon;
  }
}

export interface FileUploadSettings extends IFileUploadSettings {}

export class NumberSettings extends UiInputSettings implements IUiSettings {
  static defaultPrefix: string = '';
  static defaultSuffix: string = '';
  static defaultOnlyWholeNumbers: boolean = false;
  static defaultPermitZero: boolean = false;
  static defaultPermitShortcuts: boolean = true;
  static defaultAbbr: boolean = true;
  static defaultDecimalAmount: number = 4;
  static defaultThousandsSeparator: string = ',';

  constructor() {
    super();
    this.prefix = NumberSettings.defaultPrefix;
    this.suffix = NumberSettings.defaultSuffix;
    this.onlyWholeNumbers = NumberSettings.defaultOnlyWholeNumbers;
    this.decimalAmount = NumberSettings.defaultDecimalAmount;
    this.thousandsSeparator = NumberSettings.defaultThousandsSeparator;
    this.permitZero = NumberSettings.defaultPermitZero;
    this.permitShortcuts = NumberSettings.defaultPermitShortcuts;
    this.abbr = NumberSettings.defaultAbbr;
  }
}

export interface NumberSettings extends INumberSettings {}

export class SpreadsheetSettings implements IUiSettings {
  static readonly defaultColumnTitles: Array<string> = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
  ];
  static readonly defaultRowTitles: Array<string> = ['1', '2', '3', '4', '5'];
  constructor() {
    this.inputMode = 'directly';

    //Toolbar
    this.showNumberFormat = true;
    this.showCloseToggle = true;

    this.numberFormat = true;

    //Sheet
    this.defaultRowQuantity = SpreadsheetSettings.defaultRowTitles.length;
    this.defaultColumnQuantity = SpreadsheetSettings.defaultColumnTitles.length;
    this.columnTitles = SpreadsheetSettings.defaultColumnTitles;
    this.rowTitles = SpreadsheetSettings.defaultRowTitles;

    //All Cells
    this.allowedValues = SPREADSHEET_ALLOWED_VALUES.All;
    this.stateField = '$state';
  }
}

export interface SpreadsheetSettings extends ISpreadsheetSettings {}

export class UiSpreadSheetCell implements IUiSpreadSheetCell {
  formattedValue: Signal<string | number | undefined> = signal(undefined);
}

export interface UiSpreadSheetCell extends IUiSpreadSheetCell {}

export class TextSettings extends UiInputSettings implements IUiSettings {
  static defaultType: TextInputType = TEXT_INPUT_TYPE.Text;
  static defaultMultiline: boolean = false;
  static defaultShowCharCount: boolean = false;

  constructor() {
    super();
    this.type = TextSettings.defaultType;
    this.multiline = TextSettings.defaultMultiline;
    this.showCharCount = TextSettings.defaultShowCharCount;
  }
}

export interface TextSettings extends ITextSettings {}

//#endregion

//#region Layout

export class PageSettings extends UiLayoutSettings implements IUiSettings {
  static defaultRowGap: number = 10;

  constructor() {
    super();
    this.rowGap = PageSettings.defaultRowGap;
  }
}

export interface PageSettings extends IPageSettings {}

export class PageLayoutSettings {
  constructor() {
    this.topArea = new PageAreaSettings();
    this.topArea.height = 'fit-content(100%)';
    this.bottomArea = new PageAreaSettings();
    this.bottomArea.height = 'fit-content(100%)';
    this.leftArea = new PageAreaSettings();
    this.leftArea.width = 'fit-content(100%)';
    this.rightArea = new PageAreaSettings();
    this.rightArea.width = 'fit-content(100%)';
    this.centerArea = new PageAreaSettings();
    this.centerArea.width = 'auto';
    this.centerArea.height = 'auto';
  }
}
export interface PageLayoutSettings extends IPageLayoutSettings {}

export class PageAreaSettings {
  constructor() {
    this.width = 'auto';
    this.height = 'auto';
    this.hasHandle = true;
  }
}
export interface PageAreaSettings extends IPageAreaSettings {}

export class SectionSettings extends UiControlSettings implements IUiSettings {
  static defaultSectionContentSettings: IUiLayoutSettings = {
    colGap: 5,
    rowGap: 30,
  };

  constructor() {
    super();
    this.isMinimized = false;
    this.isCollapsible = true;
    this.isRemovable = false;
    this.noContentMsg = '';
    this.content = { ...SectionSettings.defaultSectionContentSettings };
  }
}

export interface SectionSettings extends ISectionSettings {}

export class SectionGroupSettings
  extends UiControlSettings
  implements IUiSettings
{
  static defaultSectionGroupContentSettings: IUiLayoutSettings = {
    colGap: 5,
    rowGap: 30,
  };

  constructor() {
    super();
    this.isMinimized = false;
  }
}

export interface SectionGroupSettings extends ISectionGroupSettings {}

export class ModalSettings implements IUiSettings {}

export interface ModalSettings extends IModalSettings {}

//#endregion

//#region Presentation

export class ChipSettings implements IUiSettings {}
export interface ChipSettings extends IChipSettings {}

//#endregion
