export const DRAGSTATES = {
  IDLE: 'Idle',
  DRAGGING: 'Dragging',
  HOVERED: 'Hovered',
  NOTALLOWED: 'Not Allowed',
  DROPPED: 'Dropped',
} as const;

export type DragStateType = (typeof DRAGSTATES)[keyof typeof DRAGSTATES];

export const DROPSTATES = {
  IDLE: 'Idle',
  HOVERED: 'Hovered',
  ALLOWED: 'Allowed',
  NOTALLOWED: 'Not Allowed',
  ACTIVE: 'Active',
  MARKED: 'Marked',
} as const;

export type DropStateType = (typeof DROPSTATES)[keyof typeof DROPSTATES];
