import { InjectionToken } from '@angular/core';
import { UTILS } from '../functions/utils';

export const CONTROLS = {
  SWITCH: 'switch',
  REPEATER: 'repeater',
  SECTION: 'section',
  SECTIONGROUP: 'section-group',
  GRID: 'grid',
  CHECK: 'check',
  DATE: 'date',
  DROPDOWN: 'dropdown',
  MULTIDROPDOWN: 'multi-dropdown',
  FILEUPLOAD: 'file-upload',
  LOCATION: 'location',
  NOTE: 'note',
  NUMBER: 'number',
  RICHTEXT: 'rich-text',
  SPREADSHEET: 'spreadsheet',
  TEXT: 'text',
  HISTORY: 'history',
  BUTTON: 'btn',
  VIEW: 'view',
  DESIGNERDROPZONE: 'dropzone',
  toKeyValue: UTILS.KEYVALUE.toKeyValue,
  getKeyFromValue: UTILS.KEYVALUE.getKeyFromValue,
} as const;

export type ControlType = (typeof CONTROLS)[keyof typeof CONTROLS];

export const getControlFromType = (key: string) =>
  CONTROLS.toKeyValue().find((control: { key: string, value: string }) => control.key === key)
    ?.value as ControlType;

export const DYNAMIC_CONTROL_MAP: InjectionToken<any> = new InjectionToken(
  'DYNAMIC_CONTROL_MAP'
);
