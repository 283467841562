import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import { Subscription, fromEvent, switchMap, takeUntil, timer } from 'rxjs';

@Component({
  selector: 'tooltip',
  standalone: true,
  templateUrl: 'tooltip.component.html',
})
export class TooltipComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() message = '';
  @Input() placement: 'start' | 'end' = 'start';

  private hostElement = inject(ElementRef);
  private subs: Subscription = new Subscription();

  show = false;
  cssClass = 'tooltip-start';

  ngOnInit() {
    this.cssClass = `tooltip-${this.placement}`;
  }

  ngAfterViewInit(): void {
    const mouseOver$ = fromEvent(this.hostElement?.nativeElement, 'mouseover');
    const mouseOut$ = fromEvent(this.hostElement?.nativeElement, 'mouseout');

    this.subs.add(
      mouseOver$
        .pipe(switchMap(() => timer(1000).pipe(takeUntil(mouseOut$))))
        .subscribe(() => (this.show = true)),
    );
    this.subs.add(
      mouseOut$
        .pipe(switchMap(() => timer(500)))
        .subscribe(() => (this.show = false)),
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
