@if (permDir.uipermission() !== PERMISSIONS.NONE) {
  @if (label()) {
    <label
      [class.checked]="value()"
      [label]="labelFormatted()"
      [labelTip]="labelTip()"
      [state]="state()"
      (click)="updateValue(uiValueRef.checked)"
    >
      <input
        #uiValueRef
        uivalue
        type="checkbox"
        [class]="inputCssClass()"
        [checked]="value()"
        [attr.uitoggle]="isToggle() ? true : undefined"
        [class.checked]="value()"
        (click)="
          permDir.uipermission() === PERMISSIONS.VIEW
            ? false
            : updateValue(uiValueRef.checked)
        "
      />
    </label>
  } @else {
    <input
      #uiValueRef
      uivalue
      type="checkbox"
      [class]="inputCssClass()"
      [checked]="value()"
      [attr.uitoggle]="isToggle() ? true : undefined"
      [class.checked]="value()"
      (click)="
        permDir.uipermission() === PERMISSIONS.VIEW
          ? false
          : updateValue(uiValueRef.checked)
      "
    />
  }

  <div uitoolstrip>
    <ng-content #uiToolsMarkupRef select="[uitool]"></ng-content>
    <ng-template #uiToolsCodeRef></ng-template>
  </div>
}
