import {
  DeepSignal,
  DropdownSettings,
  IDropdownSettings,
  IUiPopupSettings,
  PropExtend,
  UTILS,
} from '@aksia/infrastructure';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  TemplateRef,
  WritableSignal,
  computed,
  input,
  signal,
  model,
  isSignal,
  output,
  Signal,
  viewChild,
} from '@angular/core';
import { UiPopupDirective } from '../../../directives/ui/ui-popup.directive';
import { IconComponent } from '../../presentation/icon/icon.component';
import { LabelComponent } from '../../presentation/label/label.component';
import { TextComponent } from '../text/text.component';
import { UiInputDirective } from '../../../directives/ui/ui-input.directive';
import {
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged,
  skip,
  switchMap,
  tap,
} from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'dropdown',
    imports: [
        CommonModule,
        LabelComponent,
        IconComponent,
        UiPopupDirective,
        TextComponent,
    ],
    templateUrl: './dropdown.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownComponent extends UiInputDirective {
  //#region Inputs/Outputs

  override settings = input(new DropdownSettings(), {
    transform: (settings: IDropdownSettings) => this.initSettings(settings),
  });

  @Input() UiValueTmpl?: TemplateRef<any>;
  @Input() UiOptionTemplateRef?: TemplateRef<any>;

  popupCssClass = input<string | undefined>();

  OptionIsSelected = output<unknown | undefined>();
  ListIsUpdated = output<Array<unknown>>();

  //#endregion

  //#region View Children

  uiPopupRef = viewChild('uiPopupRef', { read: ElementRef });
  uiPopupDir = viewChild('uiPopupRef', { read: UiPopupDirective });
  uiSearchRef = viewChild('uiSearchRef', { read: TextComponent });

  //#endregion

  //#region Properties

  protected override formattedValue = computed(() => {
    return this.selectedOption()?.[this.settings()?.optionLabel] ?? '';
  });

  filterQuery = model<string | undefined>(undefined);
  searchQuery = new BehaviorSubject<string | undefined>(undefined);

  get searchValue() {
    return this.searchQuery.value;
  }

  isSearching = signal(false);

  popupSettings: WritableSignal<IUiPopupSettings> = signal(
    this.settings().popup!,
  );

  protected optionsFromSettings = signal<Array<unknown>>([]);

  protected plainOptions = computed(() => {
    let options = this.optionsFromSettings();

    let plainOptions = options?.map((option: PropExtend<unknown>) => {
      let optionPlain: PropExtend<unknown> = UTILS.SIGNALS.isDeepSignal(option)
        ? isSignal(option)
          ? (
              option as WritableSignal<
                DeepSignal<unknown & { _plain: unknown }>
              >
            )?.()?._plain()
          : (option as DeepSignal<unknown & { _plain: unknown }>)?._plain()
        : isSignal(option)
          ? option()
          : option;
      return optionPlain;
    });

    if (this.settings().optionsSorting) {
      let sortField =
        typeof this.settings().optionsSorting === 'string'
          ? this.settings().optionsSorting
          : this.settings().optionLabel!;
      return plainOptions?.sort((a: any, b: any) =>
        a[sortField] > b[sortField] ? 1 : -1,
      );
    }

    return plainOptions;
  });

  protected visibleOptions = computed(() =>
    this.plainOptions()?.filter(
      (option: PropExtend<unknown>) =>
        option?.[this.settings().optionLabel]
          ?.toLowerCase()
          ?.includes(this.filterQuery()?.toLowerCase() ?? '') &&
        (option?.[this.settings().optionValue] !== this.value() ||
          this.settings().selectedOptionStrategy !== 'removed') &&
        !option?.[this.settings().optionDeleted],
    ),
  );

  selectedOption = computed(() =>
    this.plainOptions()?.find((option: PropExtend<unknown>) =>
      this.settings().storeOnlyValue
        ? option[this.settings().optionValue] === this.value()
        : option[this.settings().optionValue] ===
          this.plainValue()?.[this.settings().optionValue],
    ),
  );

  private plainValue = computed<PropExtend<unknown>>(() =>
    UTILS.SIGNALS.isDeepSignal(this.value())
      ? (
          this.value as WritableSignal<
            DeepSignal<unknown & { _plain: Signal<unknown> }>
          >
        )?.()?._plain()
      : this.value(),
  );

  //#endregion

  //#region Functions

  protected override initSettings(settings: IDropdownSettings) {
    let dropdownSettings = UTILS.OBJECT.assign(
      new DropdownSettings(),
      settings,
    );

    super.initSettings(dropdownSettings);

    if (isSignal(settings.options)) {
      this.optionsFromSettings = dropdownSettings.options;
    } else if (settings.options instanceof Function) {
      this.optionsFromSettings.set(settings.options());
    } else {
      this.optionsFromSettings.set(dropdownSettings.options);
    }

    this.popupSettings.set(dropdownSettings.popup);
    this.popupSettings.update((popupSettings) => {
      popupSettings.uid = dropdownSettings.controlId;
      return popupSettings;
    });

    if (dropdownSettings.optionsAsyncFn) {
      this.defaultIcon.update(() => this.ICONS.faSearch);
      if (!this.searchQuery.observed) {
        this.searchQuery
          .pipe(
            skip(1),
            tap(() => this.isSearching.set(true)),
            debounceTime(this.settings().optionsDebounce ?? 0),
            distinctUntilChanged((prev, current) => {
              if (prev === current) {
                this.isSearching.set(false);
                return true;
              }
              return false;
            }),
            switchMap((query) => dropdownSettings.optionsAsyncFn!(query)),
            takeUntilDestroyed(this.destroyRef),
          )
          .subscribe((results: unknown) => {
            this.isSearching.set(false);
            if (this.settings().optionsAsyncMerging) {
              this.optionsFromSettings.update((options) => {
                (results as Array<PropExtend<unknown>>)?.forEach((result) => {
                  if (
                    !options.some(
                      (option: PropExtend<unknown>) =>
                        option[this.settings().optionValue] ===
                        result[this.settings().optionValue],
                    )
                  ) {
                    options = [...(options ?? []), result];
                  }
                });
                return options;
              });
            } else {
              this.optionsFromSettings.set((results ?? []) as Array<unknown>);
            }
            if (!this.uiPopupDir()?.isVisible) {
              this.uiPopupDir()?.togglePopup(this.hostElRef.nativeElement);
            }
          });
      }
    }

    return dropdownSettings;
  }

  protected select(option?: PropExtend<unknown>) {
    if (this.settings().selectedOptionStrategy === 'none') {
      this.updateValue(undefined);
      this.uiValueRef.nativeElement.value = '';
    } else {
      this.updateValue(this.resolveValue(option));
    }

    if (option) {
      this.OptionIsSelected.emit(option);
    }
  }

  protected popupChanged(isVisible: boolean) {
    if (!isVisible) {
      this.focusout();
    }
  }

  protected clearValue(e?: Event) {
    this.updateValue(undefined);
    this.OptionIsSelected.emit(undefined);
    e?.stopPropagation();
  }

  protected toggleOptionsPopup() {
    if (!this.settings().disabled) {
      this.focusin();
      this.uiPopupDir()?.togglePopup(this.hostElRef.nativeElement);
    }
  }

  protected resolveValue(option?: PropExtend<unknown>) {
    let options = this.optionsFromSettings();
    let value;

    options.forEach((currentOption: PropExtend<unknown>, index: number) => {
      let currentValue = UTILS.SIGNALS.isDeepSignal(currentOption)
        ? isSignal(currentOption)
          ? (
              currentOption as WritableSignal<DeepSignal<PropExtend<unknown>>>
            )()[this.settings().optionValue]()
          : (currentOption as DeepSignal<PropExtend<unknown>>)[
              this.settings().optionValue
            ]()
        : isSignal(currentOption)
          ? (currentOption as WritableSignal<PropExtend<unknown>>)()[
              this.settings().optionValue
            ]
          : currentOption[this.settings().optionValue];

      if (currentValue === option[this.settings().optionValue]) {
        if (this.settings().storeOnlyValue) value = currentValue;
        else if (
          this.settings().valueOutput === 'signal' &&
          !isSignal(currentOption)
        ) {
          value = UTILS.SIGNALS.toDeepSignal(currentOption);
        } else {
          value = currentOption;
        }
        return;
      }
    });
    return value;
  }

  protected onFilterInputChanged(query: string) {
    this.filterQuery.set(query);
  }

  protected onSearchInputChanged(query: string) {
    this.searchQuery.next(query);
  }

  protected override focusin() {
    super.focusin();
    // setTimeout is needed even without time value to properly focus the element
    setTimeout(() => this.uiSearchRef()?.focusin());
  }

  //#endregion
}
