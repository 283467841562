import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from '../../presentation/icon/icon.component';
import { ICONS } from '@aksia/infrastructure';

@Component({
    selector: 'note',
    imports: [CommonModule, IconComponent],
    templateUrl: './note.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoteComponent {
  //#region Properties

  readonly ICONS = ICONS;

  //#endregion

  //#region Functions

  clicked() {
    console.error('note is not implemented');
  }

  //#endregion
}
