import { CompanyStatusEnum } from '../../enums/entity.enum';
import { InvestmentProgram } from '../investment-program/investment-program';
import { Location } from '../entities/entities.model';
import { StoreModel, UTILS } from '@aksia/infrastructure';
import { InvestmentVehicle } from '../investment-vehicle/investment-vehicle';

export class ManagementCompany extends StoreModel {
  /**
   * Retrieves the start date of a given management company.
   *
   * This function first checks if the company has an `inceptionDate` and converts it to a Date object.
   * If the `inceptionDate` is not available, it then looks into the company's investment programs,
   * retrieves the start dates of the associated funds, sorts them in ascending order, and returns the earliest date.
   *
   * @param company - The management company for which to retrieve the start date.
   * @returns The start date of the company or the earliest start date of its investment programs' funds, if available.
   */
  static readonly getStartDate = (
    company: ManagementCompany,
  ): Date | undefined => {
    let streamStartDate;
    if (company.inceptionDate) {
      streamStartDate = UTILS.DATE.toDate(company.inceptionDate);
    } else {
      streamStartDate = company?.investmentPrograms
        ?.flatMap((program) =>
          program.funds
            ?.map((fund) => InvestmentVehicle.getStartDate(fund))
            .sort((a, b) => a?.getTime()! - b?.getTime()!),
        )
        ?.at(0);
    }

    return streamStartDate;
  };

  managementCompanyId?: number = undefined;
  name?: string = undefined;

  //#region <<< Section - Status >>>

  managerStatus?: CompanyStatusEnum = undefined;
  isSensitive?: boolean = undefined;
  key?: boolean = undefined;

  //#endregion

  //#region <<< Section - Historical Info >>>

  formerNames?: string = undefined;

  //#endregion

  //#region <<< Section - Manager Overview >>>

  managerOverview?: string = undefined;

  //#endregion

  //#region <<< Section - Description >>>

  description?: string = undefined;

  //#endregion

  //#region <<< Section - AUM >>>

  isAumFirstMonth?: boolean = undefined;
  isAumQuarterly?: boolean = undefined;

  //#endregion

  //#region <<< Section - Audit >>>

  physicalFileName?: string = undefined;
  dataSource?: number = undefined;
  dataAsOfDate?: Date = undefined;

  //#endregion

  //#region <<< Section - Basic Info >>>

  inceptionDate?: Date = undefined;
  isAffiliate?: boolean = undefined;
  addresses?: Array<Location> = [];
  staffAsOf?: Date = undefined;
  totalStaff?: number = undefined;
  nonInvestmentStaff?: number = undefined;
  generalEmail?: string = undefined;
  url?: string = undefined;

  //#endregion

  //#region <<< Section - Program >>>

  investmentPrograms?: Array<InvestmentProgram> = [];

  //#endregion

  //#region <<< Section - Identification >>>

  managerCRD?: number = undefined;
  managerCIK?: number = undefined;

  //#endregion
}
