export class ClosedEndTargetReturnProfile {
  closedEndDataFieldsId?: number = undefined;
  gpTargetReturnsGross?: number = undefined;
  gpTargetReturnsNet?: number = undefined;
  targetAnnualDivident?: number = undefined;
  gpTargetGrossMultiple?: number = undefined;
  gpTargetNetMultiple?: number = undefined;
}

/* @MetaClass({ 
  audit: {
    url: 'basicdata/fund/audit/{0}/closedend/{1}/closedEndReturnProfile/{2}',
    urlParams: [
      'grandParent.fundId@model',
      'parent.id@model',
      '@prop',
    ]
  }
} ) */
export class ClosedEndTargetReturnProfileMeta {
  closedEndDataFieldsId: number = 0;

  /* @MetaField({
    tag: '#Target Returns Gross',
    ui: {
      label: 'Target Returns Gross',
    },
    validations: [
      {
        rule: VALIDATION_RULES.NotLessThan,
        type: ValidationStyleEnum.Hard,
        userMessage:
          'Target Gross IRR cannot be lesser than the Target Net IRR',
        valueFormat: (value: number) => '',
        tags: ['#Target Returns Net'],
      },
    ],
  }) */
  accessor gpTargetReturnsGross!: number;

  /* @MetaField({
    tag: '#Target Returns Net',
    ui: {
      label: 'Target Returns Net',
    },
    validations: [
      {
        rule: VALIDATION_RULES.NotMoreThan,
        type: ValidationStyleEnum.Hard,
        userMessage:
          'Target Net IRR cannot be greater than the Target Gross IRR',
        valueFormat: (value: number) => '',
        tags: ['#Target Returns Gross'],
      },
    ],
  }) */
  accessor gpTargetReturnsNet!: number;

  /* @MetaField({
    ui: {
      label: 'Expected Annual Distribution',
    },
  }) */
  targetAnnualDivident?: number;

  /* @MetaField({
    tag: '#Target Returns Gross MOIC',
    ui: {
      label: 'Target Returns Gross MOIC',
    },
    validations: [
      {
        rule: VALIDATION_RULES.NotLessThan,
        type: ValidationStyleEnum.Hard,
        userMessage:
          'Target Gross MOIC cannot be lesser than the Target Net MOIC',
        valueFormat: (value: number) => '',
        tags: ['#Target Returns Net MOIC'],
      },
    ],
    
  }) */
  accessor gpTargetGrossMultiple!: number;

  /* @MetaField({
    tag: '#Target Returns Net MOIC',
    ui: {
      label: 'Target Returns Net MOIC',
    },
    validations: [
      {
        rule: VALIDATION_RULES.NotMoreThan,
        type: ValidationStyleEnum.Hard,
        userMessage:
          'Target Net MOIC cannot be greater than the Target Gross MOIC',
        valueFormat: (value: number) => '',
        tags: ['#Target Returns Gross MOIC'],
      },
    ],
  }) */
  accessor gpTargetNetMultiple!: number;

  isDeleted?: boolean;
  modifiedBy?: string;
  modifiedOn?: Date;
}
