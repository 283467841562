import {
  ChangeDetectionStrategy,
  Component,
  WritableSignal,
  input,
  signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from '../../presentation/icon/icon.component';
import { NumberFormatPipe } from '../../../pipes/number-format.pipe';
import { LabelComponent } from '../../presentation/label/label.component';
import { NumberSettings, INumberSettings, UTILS } from '@aksia/infrastructure';
import { UiInputDirective } from '../../../directives/ui/ui-input.directive';

let allowedKeys = [
  'Tab',
  'Home',
  'End',
  'Backspace',
  'Delete',
  'ArrowLeft',
  'ArrowRight',
  'ArrowUp',
  'ArrowDown',
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
];

@Component({
  selector: 'number',
  imports: [CommonModule, IconComponent, LabelComponent],
  templateUrl: './number.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumberComponent extends UiInputDirective {
  //#region Inputs/Outputs

  override settings = input(new NumberSettings(), {
    transform: (settings: INumberSettings) => this.initSettings(settings),
  });

  //#endregion

  //#region Properties

  protected readonly numberPipe = new NumberFormatPipe();

  protected override valueFormatter(value: unknown) {
    return UTILS.OBJECT.isNil(value) || (value === 0 && !this.permitZero())
      ? ''
      : this.numberPipe.transform(
          value,
          this.isFocused(),
          this.prefix(),
          this.suffix(),
          this.abbr(),
          false,
          this.decimalAmount(),
          this.thousandsSeparator(),
          this.percentAsIs(),
        );
  }

  prefix: WritableSignal<string | undefined> = signal(this.settings().prefix);
  suffix: WritableSignal<string | undefined> = signal(this.settings().suffix);
  abbr: WritableSignal<boolean | undefined> = signal(this.settings().abbr);
  onlyWholeNumbers: WritableSignal<boolean | undefined> = signal(
    this.settings().onlyWholeNumbers,
  );
  permitZero: WritableSignal<boolean | undefined> = signal(
    this.settings().permitZero,
  );
  permitShortcuts: WritableSignal<boolean | undefined> = signal(
    this.settings().permitShortcuts,
  );
  decimalAmount: WritableSignal<number | undefined> = signal(
    this.settings().decimalAmount,
  );
  thousandsSeparator: WritableSignal<string | undefined> = signal(
    this.settings().thousandsSeparator,
  );
  percentAsIs: WritableSignal<boolean | undefined> = signal(
    this.settings().percentAsIs,
  );

  //#endregion

  //#region Functions

  protected override initSettings(settings: INumberSettings) {
    let numberSettings = UTILS.OBJECT.assign(new NumberSettings(), settings);
    super.initSettings(numberSettings);
    this.prefix.set(numberSettings.prefix);
    this.suffix.set(numberSettings.suffix);
    this.abbr.set(numberSettings.abbr);
    this.onlyWholeNumbers.set(numberSettings.onlyWholeNumbers);
    this.permitZero.set(numberSettings.permitZero);
    this.permitShortcuts.set(numberSettings.permitShortcuts);
    this.decimalAmount.set(numberSettings.decimalAmount);
    this.thousandsSeparator.set(numberSettings.thousandsSeparator);
    this.percentAsIs.set(numberSettings.percentAsIs);
    return numberSettings;
  }

  protected clearValue() {
    this.updateValue(undefined);
    super.focusout();
  }

  protected override focusout() {
    this.updateValue(this.uiValueRef.nativeElement.value);
    super.focusout();
  }

  public override updateValue(value: unknown) {
    let rawValue = value ? this.getRawValue(value as string) : undefined;
    if (this.percentAsIs() && rawValue) {
      rawValue = rawValue / 100;
    }
    super.updateValue(
      this.permitZero() ? rawValue : rawValue === 0 ? undefined : rawValue,
    );
    if (!this.permitZero() && rawValue === 0) {
      this.uiValueRef.nativeElement.value = '';
    }
  }

  protected filterKeys(event: KeyboardEvent, value: string) {
    if (event.key === 'm' && this.permitShortcuts()) {
      this.uiValueRef.nativeElement.value = (
        this.getRawValue(value) * 1000000
      ).toFixed(this.decimalAmount());
    } else if (event.key === 'b' && this.permitShortcuts()) {
      this.uiValueRef.nativeElement.value = (
        this.getRawValue(value) * 1000000000
      ).toFixed(this.decimalAmount());
    }

    if (
      allowedKeys.includes(event.key) ||
      (event.key === '.' && !value.includes('.') && !this.onlyWholeNumbers()) ||
      (event.key === '-' &&
        (event?.target as HTMLInputElement)?.selectionStart === 0 &&
        !value.includes('-'))
    ) {
      return true;
    } else {
      event.preventDefault();
      event.stopPropagation();
      return false;
    }
  }

  private getRawValue(value: string) {
    const sign = value?.startsWith('-') ? '-' : '';
    const raw = value
      ?.replace(/[^.\d]/g, '')
      .replace(/^(\d*\.?)|(\d*)\.?/g, '$1$2');
    return Number(`${sign}${raw}`);
  }

  //#endregion
}
