import { IUiOption, UTILS } from '@aksia/infrastructure';

//#region Common

export enum EntityTypeEnum {
  ManagementCompany = 1,
  Program = 2,
  Fund = 3,
  Shareclass = 7,
  AUM = 5,
}

export namespace EntityTypeEnum {
  const replace = [{ key: /([a-z])([A-Z])/g, value: '$1 $2' }];
  export const toOptions = (omit?: Array<string | number>) =>
    UTILS.UI.enumToOptions(EntityTypeEnum, replace, omit);
}

export enum DataSourceEnum {
  PPM = 1,
  /* LPA, */
  ManagerMaterials = 3,
  AksiaTemplate,
  /* ManagerPortal,  */
  Pitchbook = 6,
  Monthly__QuarterlyLetter,
  Monthly__QuarterlyRiskReport,
  Factsheet,
  PerformanceDocument,
  AumDocument,
  FinancialStatement,
  ManagerWebsite,
  Inbox,
  FundCreationTemplate,
  PPM_and_Supplement_s_,
  PPM_Supplement,
  DraftLPA,
  ExecutedLPA,
  ExecutedLPA_and_LPA_Amendment_s_,
  LPA_Amendment,
  LLC_Agreement,
  TermSheet,
  Aksia__Manager,
  SideLetter__Memo,
  Non___StandardLetter = 26,
  DDQ = 27,
  InceptionPlaceholder = 28,
  IMA,
  OrgChart,
  FormADV,
  Form10Q,
}
export namespace DataSourceEnum {
  const replace = [
    { key: /_s_/g, value: '(s)' },
    { key: /___/g, value: '-' },
    { key: /__/g, value: ' / ' },
    { key: /_/g, value: ' ' },
    { key: /([a-z])([A-Z])/g, value: '$1 $2' },
  ];
  export const toOptions = () =>
    UTILS.UI.enumToOptions(DataSourceEnum, replace)?.sort(
      (a: IUiOption, b: IUiOption) => (a.label! > b.label! ? 1 : -1),
    );
}

export enum SimpleAnswerEnum {
  Yes = 1,
  No,
}
export namespace SimpleAnswerEnum {
  const replace = [{ key: /([a-z])([A-Z])/g, value: '$1 $2' }];
  export const toOptions = (omit?: Array<string | number>) =>
    UTILS.UI.enumToOptions(SimpleAnswerEnum, replace, omit);
}

export enum SimpleAnswerNotSpecifiedEnum {
  Yes = 1,
  No,
  NotSpecified,
}
export namespace SimpleAnswerNotSpecifiedEnum {
  const replace = [{ key: /([a-z])([A-Z])/g, value: '$1 $2' }];
  export const toOptions = (omit?: Array<string | number>) =>
    UTILS.UI.enumToOptions(SimpleAnswerNotSpecifiedEnum, replace, omit);
}

export enum SimpleAnswerPassEnum {
  Yes = 1,
  No,
  PassThrough,
}
export namespace SimpleAnswerPassEnum {
  const replace = [{ key: /([a-z])([A-Z])/g, value: '$1 $2' }];
  export const toOptions = (omit?: Array<string | number>) =>
    UTILS.UI.enumToOptions(SimpleAnswerPassEnum, replace, omit);
}

export enum CurrencyEnum {
  USD = 1,
  GBP,
  EUR,
  JPY,
  MXN,
  INR,
  BRL,
  CAD,
  AUD,
  CHF,
  DKK,
  NOK,
  SEK,
  ZAR,
  HKD,
  CNY,
  KRW,
}
export namespace CurrencyEnum {
  export const toOptions = () => {
    let enumToOptions = UTILS.UI.enumToOptions(CurrencyEnum)?.sort(
      (a: IUiOption, b: IUiOption) => (a.label! > b.label! ? 1 : -1),
    );
    return enumToOptions;
  };
}

export enum CurrencyStringEnum {
  USD = 'USD',
  GBP = 'GBP',
  EUR = 'EUR',
  JPY = 'JPY',
  MXN = 'MXN',
  INR = 'INR',
  BRL = 'BRL',
  CAD = 'CAD',
  AUD = 'AUD',
  CHF = 'CHF',
  DKK = 'DKK',
  NOK = 'NOK',
  SEK = 'SEK',
  ZAR = 'ZAR',
  HKD = 'HKD',
  CNY = 'CNY',
  KRW = 'KRW',
}
export namespace CurrencyStringEnum {
  export const toOptions = () => {
    let enumToOptions = UTILS.UI.enumToOptions(CurrencyStringEnum)?.sort(
      (a: IUiOption, b: IUiOption) => (a.label! > b.label! ? 1 : -1),
    );
    return enumToOptions?.map((option: IUiOption) => ({
      label: option.label,
      value: option.label,
    }));
  };
}

//#endregion

//#region Management Company

export enum CompanyStatusEnum {
  Active = 1,
  Liquidating,
  Dissolved,
}

export namespace CompanyStatusEnum {
  const replace = [{ key: /([a-z])([A-Z])/g, value: '$1 $2' }];
  export const toOptions = () =>
    UTILS.UI.enumToOptions(CompanyStatusEnum, replace);
}

//#endregion

//#region Investment Vehicle

export enum AssetClassEnum {
  HedgeFund = 1,
  PrivateCredit,
  PrivateEquity,
  RealAssets,
  RealEstate,
  LongOnly,
}

export namespace AssetClassEnum {
  const replace = [{ key: /([a-z])([A-Z])/g, value: '$1 $2' }];
  export const toOptions = () =>
    UTILS.UI.enumToOptions(AssetClassEnum, replace);
}

export enum AssetTypeEnum {
  ManagementCompany = 'Management Company',
  HedgeFund = 'Hedge Fund',
  LongOnly = 'Long Only',
  PrivateCredit = 'Private Credit',
  PrivateEquity = 'Private Equity',
  RealAssets = 'Real Assets',
  RealEstate = 'Real Estate',
  CoInvest = 'Co-Invest',
  Secondary = 'Secondary',
}

export namespace AssetTypeEnum {
  export const toOptions = () => UTILS.UI.enumToOptions(AssetTypeEnum);
}

export enum LiquidityStructureEnum {
  OpenEnd = 1,
  ClosedEnd,
}
export namespace LiquidityStructureEnum {
  const replace = [{ key: /([a-z])([A-Z])/g, value: '$1 $2' }];
  export const toOptions = () =>
    UTILS.UI.enumToOptions(LiquidityStructureEnum, replace)?.sort(
      (a: IUiOption, b: IUiOption) => (a.label! > b.label! ? 1 : -1),
    );
}

export enum VEntityTypeEnum {
  CommingledFund = 1,
  Fund_of_One,
  SeparateAccount,
  Co_Investment,
  DirectInvestment,
  Secondary_Single,
  Secondary_Portfolio,
  Fund__of__Funds,
  ManagedAccountCompos,
  CarveOut,
  LiquidatingSPV___Sidepocket,
  AksiaMFCs___IFCs,
  JointVenture,
  N___A,
  GP_Stake,
  SidecarOverflow,
  ContinuationFund,
}

export namespace VEntityTypeEnum {
  const replace = [
    { key: /___/g, value: '/' },
    { key: /__/g, value: ' ' },
    { key: /_/g, value: ' - ' },
    { key: /([a-z])([A-Z])/g, value: '$1 $2' },
  ];
  export const toOptions = () =>
    UTILS.UI.enumToOptions(VEntityTypeEnum, replace)?.sort(
      (a: IUiOption, b: IUiOption) => (a.label! > b.label! ? 1 : -1),
    );
}

export enum HedgeFundStructureEnum {
  NeverLaunched = 1,
  Pre_Marketing,
  NotMapped,
  Liquidating,
  FullyLiquidated,
  Active,
}
export namespace HedgeFundStructureEnum {
  const replace = [
    { key: /_/g, value: '-' },
    { key: /([a-z])([A-Z])/g, value: '$1 $2' },
  ];
  export const toOptions = () =>
    UTILS.UI.enumToOptions(HedgeFundStructureEnum, replace)?.sort(
      (a: IUiOption, b: IUiOption) => (a.label! > b.label! ? 1 : -1),
    );
}

export enum FundStructureEnum {
  Stand_alone = 1,
  Master_feeder = 2,
  MultipleMasters = 6,
  Intermediate__Master = 3,
  UmbrellaStand_alone = 4,
  UmbrellaMaster_feeder = 5,
}
export namespace FundStructureEnum {
  const replace = [
    { key: /__/g, value: '/' },
    { key: /_/g, value: '-' },
    { key: /([a-z])([A-Z])/g, value: '$1 $2' },
  ];
  export const toOptions = () =>
    UTILS.UI.enumToOptions(FundStructureEnum, replace)?.sort(
      (a: IUiOption, b: IUiOption) => (a.label! > b.label! ? 1 : -1),
    );
}

export enum ProgramStatusEnum {
  Fundraising = 1,
  NotFundraising,
  Pre_Marketing,
  OpenEnded,
  NeverLaunched,
  WindingDownLiquidating,
  FundraisingSMAsOnly,
  FullyRealized,
  FundraisingPaused,
  N__A,
}

export namespace ProgramStatusEnum {
  const replace = [
    { key: /__/g, value: '/' },
    { key: /_/g, value: '-' },
    { key: /([a-z])([A-Z])/g, value: '$1 $2' },
  ];
  export const toOptions = () =>
    UTILS.UI.enumToOptions(ProgramStatusEnum, replace);
}

export enum FundStatusEnum {
  NewLaunch__Pre_Investing = 1,
  Active__Investing,
  Liquidating__Harvesting,
  FullyLiquidated__Realized,
  NeverLaunched,
}

export namespace FundStatusEnum {
  const replace = [
    { key: /__/g, value: ' / ' },
    { key: /_/g, value: '-' },
    { key: /([a-z])([A-Z])/g, value: '$1 $2' },
  ];
  export const toOptions = () =>
    UTILS.UI.enumToOptions(FundStatusEnum, replace);
}

export enum FundRaisingStatusEnum {
  Fundraising = 1,
  NotFundraising,
}

export namespace FundRaisingStatusEnum {
  const replace = [{ key: /([a-z])([A-Z])/g, value: '$1 $2' }];
  export const toOptions = () =>
    UTILS.UI.enumToOptions(FundRaisingStatusEnum, replace);
}

export enum ReportingSourceEnum {
  Bloomberg = 1,
  Refused,
  ReportingInbox,
  Website,
  Other,
}

export namespace ReportingSourceEnum {
  const replace = [{ key: /([a-z])([A-Z])/g, value: '$1 $2' }];
  export const toOptions = () =>
    UTILS.UI.enumToOptions(ReportingSourceEnum, replace);
}

export enum ThirdPartyProviderEnum {
  Pitchbook,
  Preqin,
  Other,
}

export namespace ThirdPartyProviderEnum {
  const replace = [{ key: /([a-z])([A-Z])/g, value: '$1 $2' }];
  export const toOptions = () =>
    UTILS.UI.enumToOptions(ThirdPartyProviderEnum, replace);
}

//#endregion

export enum MgmtFeeFrequencyEnum {
  MonthlyInAdvance = 1,
  MonthlyInArrears,
  QuarterlyInAdvance,
  QuarterlyInArrears,
  Semi_annuallyInAdvance,
  Semi_annuallyInArrears,
  AnnuallyInAdvance,
  AnnuallyInArrears,
  Other,
}
export namespace MgmtFeeFrequencyEnum {
  const replace = [
    { key: /_/g, value: '-' },
    { key: /([a-z])([A-Z])/g, value: '$1 $2' },
  ];
  export const toOptions = () =>
    UTILS.UI.enumToOptions(MgmtFeeFrequencyEnum, replace);
}

export enum PassThroughManagementFeeTypeEnum {
  Full = 1,
  Partial,
}
export namespace PassThroughManagementFeeTypeEnum {
  const replace = [{ key: /([a-z])([A-Z])/g, value: '$1 $2' }];
  export const toOptions = () =>
    UTILS.UI.enumToOptions(PassThroughManagementFeeTypeEnum, replace);
}

export enum DiscountTypeEnum {
  General = 1,
  Aksia_Clients = 3,
}
export namespace DiscountTypeEnum {
  const replace = [
    { key: /_/g, value: '-' },
    { key: /([a-z])([A-Z])/g, value: '$1 $2' },
  ];
  export const toOptions = () =>
    UTILS.UI.enumToOptions(DiscountTypeEnum, replace);
}

export enum FundLevelsEnum {
  MasterFund = 1,
  Fund,
  Class,
  Investor,
  Investor_Fund,
  Investor_MasterFund,
}
export namespace FundLevelsEnum {
  const replace = [
    { key: /_/g, value: '/' },
    { key: /([a-z])([A-Z])/g, value: '$1 $2' },
  ];
  export const toOptions = (omit?: Array<string | number>) =>
    UTILS.UI.enumToOptions(FundLevelsEnum, replace, omit);
}

export enum AksiaCoverageGPNewEnum {
  Tier_I__IDD___ODD_on_fund_associated_with_the_co____investment,
  Tier_I__IDD___ODD_within_past_4yrs_on_predecessor_fund_in_same_series_____program,
  Tier_II__IDD___ODD_within_past_4yrs_on_GP______s_fund_in_same_asset_class,
  Tier_II__ODD_on_prior_fund_in_any_series_____program_within_past_4yrs,
  Tier_III__At_least_2_existing_funds_in_the_same_asset_class_prior_to_current_associated_fund_and_Firm_AUM_of_at_least_$2_______5_bn,
  Tier_IV__All_others________and_at_least__________$250m_AUM_for_credit_participations_________,
}

export namespace AksiaCoverageGPNewEnum {
  const replace = [
    { key: /__________/g, value: ' >' },
    { key: /_________/g, value: ')' },
    { key: /________/g, value: ' (' },
    { key: /_______/g, value: '.' },
    { key: /______/g, value: "'" },
    { key: /_____/g, value: '/' },
    { key: /____/g, value: '-' },
    { key: /___/g, value: '+' },
    { key: /__/g, value: ': ' },
    { key: /_/g, value: ' ' },
  ];

  export const toOptions = (omit?: Array<string | number>) =>
    UTILS.UI.enumToOptions(AksiaCoverageGPNewEnum, replace, omit);
}
