import { Directive, forwardRef, inject, ViewContainerRef } from '@angular/core';
@Directive({
  selector: '[inject]',
  standalone: true
})
export class InjectDirective {
  public viewContainerRef = inject(ViewContainerRef);
}

export abstract class DirectiveProvider{}
export function returnProvider(component: unknown | null) {
  if (component){
    return { provide: DirectiveProvider, useExisting: forwardRef(() => component) };
  }
  return { provide: DirectiveProvider, useExisting: undefined };
}
