@if (iconDefinition()) {
  <ng-icon
    [ngStyle]="{
      display: renderInGrid() ? 'table-cell' : 'block !important',
      verticalAlign: renderInGrid() ? 'middle' : 'unset',
    }"
    style="display: block !important"
    [name]="iconDefinition()!"
    [size]="iconSize()"
    [color]="color()!"
  ></ng-icon>
}
