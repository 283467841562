@if (permDir.uipermission() !== PERMISSIONS.NONE) {
  <!-- Label -->
  @if (label()) {
    <label
      uipopuptrigger
      [attr.uilabelup]="labelUp()"
      [label]="labelFormatted()"
      [labelTip]="labelTip()"
      [state]="state()"
    >
    </label>
  }

  <!-- Input -->
  <input
    #uiValueRef
    uivalue
    type="text"
    readonly
    [class]="inputCssClass()"
    [value]="formattedValue()"
    [placeholder]="placeholder()"
    (click)="toggleOptionsPopup()"
  />

  <!-- Tools -->
  <div uitoolstrip>
    <ng-content #uiToolsMarkupRef select="[uitool]"></ng-content>
    <ng-template #uiToolsCodeRef></ng-template>
    <ng-container #uiToolsDefaultRef>
      @if (!isNil(value())) {
        <icon
          uihovertool
          uicleartool
          [iconSize]="'normal'"
          [iconDefinition]="ICONS.faTimes"
          (click)="updateValue(undefined)"
        ></icon>
      }
      <icon
        uidefaulttool
        [iconSize]="'normal'"
        [iconDefinition]="defaultIcon()"
        (click)="toggleOptionsPopup()"
      ></icon>
    </ng-container>
  </div>

  <!-- Selection Options -->
  @if (permDir.uipermission() !== PERMISSIONS.VIEW) {
    <ul
      #uiPopupRef
      [UiPopup]="popupSettings()"
      (UiPopupVisibilityChanged)="popupChanged($event)"
    >
      <li [attr.uipopupoption]="uid()" uipopupoptionleft (click)="scroll(-1)">
        <icon [iconSize]="'normal'" [iconDefinition]="ICONS.caretLeft"></icon>
      </li>
      <li
        [attr.uipopupoption]="uid()"
        uipopupoptioncenter
        (click)="selectCalendarContent()"
      >
        {{ calendarTitle() }}
      </li>
      <li [attr.uipopupoption]="uid()" uipopupoptionright (click)="scroll(1)">
        <icon [iconSize]="'normal'" [iconDefinition]="ICONS.caretRight"></icon>
      </li>

      @for (option of options(); track i; let i = $index) {
        <li
          tabindex="0"
          [attr.uipopupoption]="uid()"
          [attr.uipopupoptiondisabled]="!option.isSelectable || undefined"
          [attr.uipopupoptionselected]="option.isSelected"
          (click)="option.isSelectable ? select(option) : false"
        >
          {{ option.label }}
        </li>
      }
    </ul>
  }

  <!-- State -->
  @switch (uiState) {
    @case ('error') {
      <span
        uifooter
        uistate="error"
        class="cursor-help"
        [title]="errors().join('\r\n')"
        >{{
          errors().length > 1
            ? errors().at(0) +
              ' (' +
              (errors().length - 1).toString() +
              ' more)'
            : errors().at(0)
        }}</span
      >
    }
    @case ('warning') {
      <span
        uifooter
        uistate="warning"
        class="cursor-help"
        [title]="warnings().join('\r\n')"
        >{{
          warnings().length > 1
            ? warnings().at(0) +
              ' (' +
              (warnings().length - 1).toString() +
              ' more)'
            : warnings().at(0)
        }}</span
      >
    }
  }
}
