@if (permDir.uipermission() !== PERMISSIONS.NONE) {
  <!-- Label -->
  @if (label()) {
    <label
      uipopuptrigger
      [attr.uilabelup]="labelUp()"
      [label]="labelFormatted()"
      [labelTip]="labelTip()"
      [state]="state()"
    >
    </label>
  }

  <!-- Input -->
  <input
    #uiValueRef
    uivalue
    type="text"
    [attr.readonly]="!settings().optionsAsyncFn ? true : undefined"
    [class]="inputCssClass()"
    [value]="formattedValue()"
    [placeholder]="settings().placeholder"
    (focusin)="settings().clearInputOnFocus ? clearValue($event) : undefined"
    (click)="toggleOptionsPopup()"
    (input)="onSearchInputChanged(uiValueRef.value)"
  />

  <!-- Tools -->
  <div uitoolstrip>
    <ng-content #uiToolsMarkupRef select="[uitool]"></ng-content>
    <ng-template #uiToolsCodeRef></ng-template>
    <ng-container #uiToolsDefaultRef>
      @if (settings().showClearButton && !isNil(value())) {
        <icon
          uihovertool
          uicleartool
          [iconSize]="'normal'"
          [iconDefinition]="ICONS.faTimes"
          (click)="clearValue($event); focusout()"
        ></icon>
      }
      @if (!settings().hideDefaultIcon && !isSearching()) {
        <icon
          uidefaulttool
          [iconSize]="'normal'"
          [iconDefinition]="defaultIcon()"
          (click)="toggleOptionsPopup()"
        ></icon>
      } @else if (isSearching()) {
        <icon
          class="animate-spin"
          [iconSize]="'large'"
          [iconDefinition]="settings().loadingIcon"
        ></icon>
      }
    </ng-container>
  </div>

  <!-- Selection Options -->
  @if (permDir.uipermission() !== PERMISSIONS.VIEW) {
    <ul
      #uiPopupRef
      [class]="popupCssClass()"
      [UiPopup]="popupSettings()"
      (UiPopupVisibilityChanged)="popupChanged($event)"
    >
      <!-- Search -->
      @if (settings().optionsFiltering) {
        <text
          #uiSearchRef
          uipopupsearch
          [(value)]="filterQuery"
          [settings]="{
            placeholder: 'Start typing to filter',
            placeholderPersists: true,
            tag: settings().label + ' Search',
          }"
          (InputChanged)="onFilterInputChanged($event)"
        >
          <icon
            uitool
            [iconSize]="'normal'"
            [iconDefinition]="ICONS.faSearch"
          ></icon>
        </text>
      }
      <!-- End Search -->
      @for (option of visibleOptions(); track $index) {
        <li
          #uiOption
          [attr.uipopupoption]="uid()"
          [attr.uipopupoptionselected]="
            selectedOption()?.[settings().optionValue] ===
            option[settings().optionValue]
          "
          (click)="select(option)"
        >
          <ng-container
            *ngTemplateOutlet="
              UiOptionTemplateRef || uiOptionTemplateRef;
              context: { $implicit: option, index: $index, isFirst: $first }
            "
          ></ng-container>
        </li>
      } @empty {
        <li uipopupnonoption>There is no available info to show</li>
      }
    </ul>
  }

  <!-- Option Templates -->
  <ng-template #uiOptionTemplateRef let-option>
    @if (option) {
      {{ option[settings().optionLabel] }}
    }
  </ng-template>

  <!-- State -->
  @switch (uiState) {
    @case ('error') {
      <span
        uifooter
        uistate="error"
        class="cursor-help"
        [title]="errors().join('\r\n')"
        >{{
          errors().length > 1
            ? errors().at(0) +
              ' (' +
              (errors().length - 1).toString() +
              ' more)'
            : errors().at(0)
        }}</span
      >
    }
    @case ('warning') {
      <span
        uifooter
        uistate="warning"
        class="cursor-help"
        [title]="warnings().join('\r\n')"
        >{{
          warnings().length > 1
            ? warnings().at(0) +
              ' (' +
              (warnings().length - 1).toString() +
              ' more)'
            : warnings().at(0)
        }}</span
      >
    }
  }

  <!-- Modal Template -->
  <ng-template #uiModalsCodeRef></ng-template>
}
