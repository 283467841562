import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from '../icon/icon.component';
import { ICONS, ISchemaControl } from '@aksia/infrastructure';

@Component({
    selector: 'history',
    imports: [CommonModule, IconComponent],
    templateUrl: './history.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HistoryComponent {
  //#region Properties

  readonly ICONS = ICONS;

  //#endregion

  //#region Functions

  clicked() {
    console.error('history is not implemented');
  }

  //#endregion
}

export interface HistoryComponent extends ISchemaControl {}
