import {
  AUMSourceEnum,
  PublicReturnFeeEnum,
  PublicReturnGeneralClassificationEnum,
  PublicReturnSourceEnum,
  StreamPeriodicityEnum,
  StreamSourceEnum,
} from '../../enums/periodicStream.enum';
import { CurrencyEnum, EntityTypeEnum } from '../../enums/entity.enum';
import { StoreModel } from '@aksia/infrastructure';
import {
  PERIODIC_STREAMS,
  PeriodicStreamType,
} from '../../types/periodic-stream.type';
import { computed, Signal, signal, WritableSignal } from '@angular/core';

export class PeriodicStreamPoint extends StoreModel {
  asOf?: Date = undefined;
  source?: StreamSourceEnum | AUMSourceEnum | PublicReturnSourceEnum =
    undefined;
  value?: number = undefined;
  get $cellCssClass() {
    return '';
  }
}

export class PublicReturnStreamPoint extends PeriodicStreamPoint {
  feeType?: PublicReturnFeeEnum = undefined;
  classification?: PublicReturnGeneralClassificationEnum = undefined;
  estimationAsOf?: Date = undefined;
  override get $cellCssClass() {
    let css = '';
    switch (this.classification) {
      case PublicReturnGeneralClassificationEnum.Master:
      case PublicReturnGeneralClassificationEnum.InvestmentProgram:
        css = 'bg-orange-200';
        break;
      case PublicReturnGeneralClassificationEnum.Backtested:
      case PublicReturnGeneralClassificationEnum.CarveOut:
      case PublicReturnGeneralClassificationEnum.Composite:
      case PublicReturnGeneralClassificationEnum.FundOfOne:
      case PublicReturnGeneralClassificationEnum.InternalOnly:
        css = 'bg-orange-400';
        break;
    }

    if (this.estimationAsOf) {
      css += ' opacity-50';
    }

    if (!this.value) return css;
    else
      return this.value < 0 ? css + ' text-red-600' : css + ' text-green-600';
  }
}

export class AUMStreamPoint extends PeriodicStreamPoint {
  override source?: AUMSourceEnum = undefined;
  currency?: string = undefined;
  relation?: number = undefined;
  entityIds?: string = undefined;
}

export class PeriodicStream extends StoreModel {
  streamType?: PeriodicStreamType;
  periodicity?: StreamPeriodicityEnum;
  datapoints?: Array<PeriodicStreamPoint>;
  ytd?: Array<PeriodicStreamPoint>;

  historyIsLoaded?: boolean;
  selectedAsOf?: Date;
  minAsOf?: Date;
  maxAsOf?: Date;
  selectedSource?: StreamSourceEnum | PublicReturnSourceEnum | AUMSourceEnum;

  //#region AUM Specific

  selectedCurrency?: string;

  //#endregion

  //#region Public Returns Specific

  selectedFeeType?: PublicReturnFeeEnum;
  selectedClassification?: PublicReturnGeneralClassificationEnum;
  selectedEstimationAsOf?: Date;

  //#endregion

  selectedDataPoint?: PeriodicStreamPoint;
  markedDataPoints?: Array<PeriodicStreamPoint>;

  static getStreamPointClass(streamType: PeriodicStreamType) {
    switch (streamType) {
      case PERIODIC_STREAMS.AUM_EXPAND:
        return AUMStreamPoint;
      case PERIODIC_STREAMS.PUBLIC_RETURNS:
        return PublicReturnStreamPoint;
      default:
        return PeriodicStreamPoint;
    }
  }
}
