import {
  AksiaValidatorFn,
  IStateService,
  IValidationId,
  IValidationRequest,
  STATE_SERVICE_TOKEN,
  StateEnum,
  TValidators,
  UTILS,
} from '@aksia/infrastructure';
import {
  computed,
  Directive,
  inject,
  input,
  model,
  Signal,
  signal,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

const valueNotSet = Symbol.for('Not Set');

@Directive({
  selector: '[uivalidation]',
  standalone: true,
})
export class UiValidationDirective {
  //#region Injections

  private route = inject(ActivatedRoute);

  //#endregion

  //#region Inputs/Outputs

  validationId = input(undefined, {
    alias: 'validation.id',
    transform: (validationId: number | IValidationId | undefined) => {
      this.modelValidationId.set(validationId);
      this.registerValidators();
      return validationId;
    },
  });

  modelValidationId = model<number | IValidationId | undefined>();

  validationTag = input(undefined, {
    alias: 'validation.tag',
    transform: (tag: string | undefined) => {
      this.modelValidationTag.set(tag);
      this.registerValidators();
      return tag;
    },
  });

  modelValidationTag = model<string | undefined>();

  validators = input([], {
    transform: (validators: TValidators) => {
      let validatorsFn =
        typeof validators === 'function' ? validators() : validators;
      this.modelValidators.set(validatorsFn);
      this.registerValidators();
      return validatorsFn;
    },
  });

  modelValidators = model<Array<AksiaValidatorFn> | undefined>();

  modelValidationValue = model<unknown | Symbol>(Symbol.for('Not Set'));

  validationChain = input<
    IValidationRequest | Array<IValidationRequest> | undefined
  >(undefined, { alias: 'validation.chain' });

  //#endregion

  //#region Properties
  stateManager!: IStateService;
  registeredValidators?: Array<AksiaValidatorFn>;

  get errors(): Signal<Array<string>> {
    return this.modelValidationId() && this.modelValidationTag()
      ? (this.stateManager.validationErrors(
          this.modelValidationId()!,
          this.modelValidationTag()!,
        ) ?? signal([]))
      : signal([]);
  }
  get warnings(): Signal<Array<string>> {
    return this.modelValidationId() && this.modelValidationTag()
      ? (this.stateManager.validationWarnings(
          this.modelValidationId()!,
          this.modelValidationTag()!,
        ) ?? signal([]))
      : signal([]);
  }

  initialValue: unknown | Symbol = Symbol.for('Not Set');
  state: Signal<StateEnum | undefined> = signal(undefined);

  calculatedState = computed(() =>
    (this.state?.() ?? this.errors?.()?.length! > 0)
      ? StateEnum.Error
      : this.warnings?.()?.length! > 0
        ? StateEnum.Warning
        : this.initialValue !== this.modelValidationValue()
          ? StateEnum.Dirty
          : StateEnum.Pristine,
  );

  //#endregion

  //#region Methods

  constructor() {
    this.stateManager = inject(
      this.route.snapshot.data['store'] ?? STATE_SERVICE_TOKEN,
    );
  }

  private registerValidators() {
    if (
      this.modelValidationId() &&
      this.modelValidationTag() &&
      this.modelValidators() &&
      !this.stateManager.validationExists(
        this.modelValidationId()!,
        this.modelValidationTag()!,
      )
    ) {
      this.stateManager.registerValidation({
        validationId: this.modelValidationId()!,
        tag: this.modelValidationTag()!,
        validators: this.modelValidators()!,
      });
    }
  }

  updateValidationValue(value: unknown) {
    if (
      (Array.isArray(value) &&
        UTILS.ARRAY.compareArrayByJSON(
          this.modelValidationValue() as Array<unknown>,
          value,
        )) ||
      this.modelValidationValue() === value
    )
      return;

    if (this.initialValue === Symbol.for('Not Set')) {
      this.initialValue = value;
    }

    this.modelValidationValue.set(value);

    if (
      this.modelValidationId() &&
      this.modelValidationTag() &&
      this.modelValidators() &&
      !this.stateManager.validationIsInit(
        this.modelValidationId()!,
        this.modelValidationTag()!,
      )
    ) {
      this.stateManager.updateValidationValue({
        validationId: this.modelValidationId()!,
        tag: this.modelValidationTag()!,
        value,
      });
    }
    this.validate(this.modelValidationValue(), true);
  }

  updateValidationTag(tag?: string) {
    if (!tag || this.modelValidationTag() === tag) return;

    if (!this.modelValidationTag()) {
      this.modelValidationTag.set(tag);
      this.registerValidators();
    } else if (this.modelValidationTag()) {
      this.stateManager.updateValidationTag(
        {
          validationId: this.modelValidationId()!,
          tag: this.modelValidationTag()!,
          value: this.modelValidationValue(),
        },
        tag,
      );
      this.modelValidationTag.set(tag);
    }

    this.validate(this.modelValidationValue(), true);
  }

  validate(value: unknown, runValidationChain = true) {
    this.modelValidationValue.set(value);
    if (this.stateManager && this.modelValidationTag()) {
      let validationResponses = this.stateManager.validate(
        {
          validationId: this.modelValidationId()!,
          tag: this.modelValidationTag(),
          value,
        },
        runValidationChain ? this.validationChain() : undefined,
      );
    }
  }

  //#endregion
}
