import {
  ChangeDetectionStrategy,
  Component,
  WritableSignal,
  input,
  signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckSettings, ICheckSettings, UTILS } from '@aksia/infrastructure';
import { LabelComponent } from '../../presentation/label/label.component';
import { UiInputDirective } from '../../../directives/ui/ui-input.directive';

@Component({
  selector: 'check',
  imports: [CommonModule, LabelComponent],
  templateUrl: './check.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckComponent extends UiInputDirective {
  //#region Inputs/Outputs

  override settings = input<CheckSettings, ICheckSettings>(
    new CheckSettings(),
    {
      transform: (settings: ICheckSettings) => this.initSettings(settings),
    },
  );

  //#endregion

  //#region Properties

  isToggle: WritableSignal<boolean | undefined> = signal(
    this.settings().isToggle,
  );

  //#endregion

  //#region Functions

  protected override initSettings(settings: ICheckSettings) {
    let checkSettings = UTILS.OBJECT.assign(new CheckSettings(), settings);
    this.isToggle.set(checkSettings.isToggle);
    super.initSettings(checkSettings);
    return checkSettings;
  }

  //#endregion
}
