import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
  input,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from '../../presentation/icon/icon.component';
import { CopyDirective } from '../../../directives/copy.directive';
import { UiControlDirective } from '../../../directives/ui/ui-control.directive';
import {
  SectionGroupSettings,
  ISectionGroupSettings,
  ISchemaControl,
  UTILS,
} from '@aksia/infrastructure';

@Component({
    selector: 'section-group',
    imports: [CommonModule, IconComponent, CopyDirective],
    exportAs: 'section-group',
    templateUrl: './section-group.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SectionGroupComponent extends UiControlDirective {
  //#region Inputs/Outputs

  override settings = input<SectionGroupSettings, ISectionGroupSettings>(
    new SectionGroupSettings(),
    {
      transform: (settings: ISectionGroupSettings) =>
        this.initSettings(settings),
    },
  );

  @Input() isRendered: boolean = true;
  @Output() closed: EventEmitter<any> = new EventEmitter();

  //#endregion

  //#region Host Bindings

  @HostBinding('[attr.isMulti]') isMulti?: boolean;
  @HostBinding('[attr.hidePlaceholder') hidePlaceholder?: boolean;
  @HostBinding('[attr.isFirst]') isFirst?: boolean;
  @HostBinding('[attr.isLast]') isLast?: boolean;

  //#endregion

  //#region View Children

  @ViewChildren('hostRefs', { read: ViewContainerRef })
  hostRefs!: QueryList<ViewContainerRef>;
  @ViewChild('inject', { read: ViewContainerRef }) inject!: ViewContainerRef;

  //#endregion

  //#region Functions

  protected override initSettings(settings: ISectionGroupSettings) {
    let sectionGroupSettings = UTILS.OBJECT.assign(
      new SectionGroupSettings(),
      settings,
    );
    super.initSettings(sectionGroupSettings);
    this.isMulti = sectionGroupSettings.isMulti;
    this.hidePlaceholder = sectionGroupSettings.hidePlaceholder;
    this.isFirst = sectionGroupSettings.isFirst;
    this.isLast = sectionGroupSettings.isLast;
    return sectionGroupSettings;
  }

  protected toggle() {
    this.settings().isMinimized = !this.settings().isMinimized;
  }

  //#endregion
}
