import { ICONS, ISchemaControl, IconType, Icons } from '@aksia/infrastructure';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { IconComponent } from '../icon/icon.component';

export enum ToastTypeEnum {
  Info,
  Success,
  Error,
  Warning,
}

@Component({
    selector: 'toast',
    imports: [IconComponent],
    templateUrl: 'toast.component.html',
    styleUrls: [],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToastComponent implements OnInit {
  @Input() type: ToastTypeEnum = ToastTypeEnum.Info;
  @Input() message = '';

  cssClass = '';
  icon?: IconType;
  private icons = ICONS;

  ngOnInit(): void {
    switch (this.type) {
      case ToastTypeEnum.Success: {
        this.icon = this.icons.faCircleCheck;
        this.cssClass = 'toast-success';
        break;
      }
      case ToastTypeEnum.Warning: {
        this.icon = this.icons.faTriangleExclamation;
        this.cssClass = 'toast-warning';
        break;
      }
      case ToastTypeEnum.Error: {
        this.icon = this.icons.faCircleTimes;
        this.cssClass = 'toast-error';
        break;
      }
      default: {
        this.icon = this.icons.faCircleInfo;
        this.cssClass = 'toast-info';
        break;
      }
    }
  }
}
