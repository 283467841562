import { IO2User, ROLES, RoleType } from '@aksia/infrastructure';
import { O2Role } from './role.classes';
import { DEFAULT_VIEW_BY_ROLE } from '../../types/view.types';

export class O2User implements IO2User {
  userId!: number;
  userName!: string;
  email?: string;
  abbr!: string;
  fullName!: string;
  roles?: Array<RoleType>;
  impersonate?: RoleType;
  preferences?: Map<string, any>;

  isInRole(role: RoleType): boolean {
    return this.impersonate
      ? this.impersonate === role
      : (this.roles?.some((userRole) => userRole === role) ?? false);
  }

  getDefaultView() {
    let userRole = this.impersonate ?? (this.roles?.at(0)! as RoleType);
    let roleField = ROLES.toKeyValue().find(
      (role) => role.value === userRole,
    )?.key;
    let defaultRole =
      DEFAULT_VIEW_BY_ROLE[roleField as keyof typeof DEFAULT_VIEW_BY_ROLE];
    return defaultRole;
  }

  getPreference<T>(preference: string): T | undefined {
    return this.preferences?.get(preference);
  }

  constructor(user?: IO2User) {
    if (user) {
      this.userName = user.userName;
      this.email = `${user.userName}@aksia.com`;
      this.abbr = user.userName.substring(0, 2)?.toUpperCase();
      this.fullName = user.fullName;
      if (user.roles?.includes(ROLES.ADMIN)) {
        this.roles = [ROLES.ADMIN];
      } else {
        this.roles = user.roles?.filter((role) =>
          Object.values(ROLES).includes(role),
        );
      }
      this.preferences = new Map(Object.entries(user.preferences ?? {}));
    }
  }
}
