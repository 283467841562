import {
  AUTHENTICATION_SERVICE_TOKEN,
  ILayoutService,
  IPageLayoutSettings,
  PAGE_LAYOUT,
  PermissionType,
  RoleType,
  STORAGE_KEYS,
  TViewItem,
  TViewMap,
  VIEW_MAP_TOKEN,
} from '@aksia/infrastructure';
import {
  computed,
  DestroyRef,
  effect,
  ElementRef,
  inject,
  Injectable,
  Signal,
  signal,
  WritableSignal,
} from '@angular/core';
import { LocalStorageService } from '../browser/local-storage.service';
import { AssetService } from '../asset/asset.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter, map, mergeMap } from 'rxjs';

type ViewBP<View, ViewCategory> = {
  view: View | '*';
  categories: Array<ViewCategory>;
  tags: Array<string>;
};

type PermissionBP = {
  views?: Array<string>;
  roles: Array<RoleType>;
  tags: Array<string>;
  permission: PermissionType;
};

@Injectable({
  providedIn: 'root',
})
export class LayoutService<
  ViewCategory,
  View,
  ViewItem extends TViewItem<unknown>,
  ViewMap extends TViewMap<ViewCategory, View>,
> implements ILayoutService<ViewCategory, View, ViewItem, ViewMap>
{
  private readonly destroyRef = inject(DestroyRef);
  private readonly router = inject(Router);
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly asset = inject(AssetService);
  private readonly viewMapToken = inject(VIEW_MAP_TOKEN) as ViewMap;
  private readonly local = inject(LocalStorageService);
  private readonly auth = inject(AUTHENTICATION_SERVICE_TOKEN);

  //#region Properties

  //Layout
  readonly layoutIsReady = signal(false);
  readonly layoutType = signal(PAGE_LAYOUT.Blank);
  readonly layoutSettings = signal<IPageLayoutSettings>({});

  //Views
  readonly viewCategory = signal<ViewCategory | undefined>(undefined);
  readonly viewMap = signal<ViewMap | undefined>(undefined);
  private readonly availableViews = signal<Array<ViewBP<View, ViewCategory>>>(
    [],
  );
  readonly filteredViews = computed<Array<View> | undefined>(() =>
    this.viewCategory()
      ? this.viewMapToken?.find(
          (viewMapItem) => viewMapItem.viewCategory === this.viewCategory(),
        )!.views
      : undefined,
  );
  readonly defaultView = signal<View | undefined>(undefined);
  readonly selectedView = signal<View | undefined>(undefined);
  readonly availableViewItems = signal<Array<ViewItem>>([]);
  readonly selectedViewItems = signal<Array<ViewItem>>([]);

  //Permissions
  readonly permissions = signal<Array<PermissionBP>>([]);

  //Anchors
  anchorSelector: string = 'uitag';
  readonly anchorHost: WritableSignal<ElementRef<any> | undefined> =
    signal(undefined);
  readonly anchorElements: WritableSignal<readonly ElementRef<any>[]> = signal(
    [],
  );
  readonly anchorTags = computed(() => {
    return (
      this.anchorElements?.()
        ?.map((el) => el.nativeElement.getAttribute('uitag'))
        ?.filter((tag) => tag) || []
    );
  });

  //#endregion

  //#region Methods

  constructor() {
    effect(() => {
      if (this.asset?.collection()?.size! > 0) {
        this.initLoad();
      }
    });
    effect(() => {
      if (
        this.layoutType() &&
        this.viewCategory() &&
        this.selectedView() &&
        this.availableViews().length > 0 &&
        this.availableViewItems().length > 0 &&
        this.permissions().length > 0
      ) {
        this.updateItems();
      }
    });
  }

  initLoad() {
    this.getLayoutType()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(({ layoutType, layoutSettings }) => {
        this.layoutType.set(layoutType);
        this.layoutSettings.set(layoutSettings);
      });

    if (this.asset) {
      this.availableViews.set(
        this.asset.collection()?.get('views') as Array<
          ViewBP<View, ViewCategory>
        >,
      );
      this.availableViewItems.set(
        this.asset.collection()?.get('items') as Array<ViewItem>,
      );
      this.permissions.set(
        this.asset.collection()?.get('permissions') as Array<PermissionBP>,
      );
    }
  }

  private getLayoutType() {
    return this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data),
    );
  }

  selectView(view: View) {
    this.selectedView.set(view);
  }

  setDefaultView(view: View, store = true) {
    this.defaultView.set(view);
    if (store) {
      this.local.set(STORAGE_KEYS.LAYOUT.DEFAULT_VIEW, view);
    }
  }

  private updateItems() {
    if (this.selectedView()) {
      let viewTags = this.availableViews().find(
        (view) =>
          (view.view === this.selectedView() || view.view === '*') &&
          view.categories.includes(this.viewCategory()!),
      )?.tags;

      let viewItems: Array<ViewItem> = [];
      viewTags?.forEach((tag) => {
        let item = this.availableViewItems().find(
          (item) => item.settings.tag === tag,
        );
        if (item) {
          viewItems.push({ ...item });
        }
      });
      this.selectedViewItems.set(viewItems);
    } else {
      this.selectedViewItems.set([]);
    }
  }

  getPermissionFor(control: string, tag: string) {
    return this.permissions().find(
      (permission) =>
        (permission.views?.includes(this.selectedView() as string) ||
          !permission.views) &&
        permission.tags.includes(`${control}|${tag}`) &&
        permission.roles?.some((role) => this.auth.user()?.isInRole?.(role)),
    )?.permission;
  }

  downloadView() {
    console.log(this.selectedView());
  }

  public setupAnchors(anchorSettings: {
    host: Signal<ElementRef<any> | undefined>;
    elements: Signal<readonly ElementRef<any>[]>;
    selector?: string;
  }) {
    this.anchorHost.set(anchorSettings.host());
    this.anchorElements.set(anchorSettings.elements());
    this.anchorSelector = anchorSettings.selector ?? 'uitag';
  }

  public jumpToAnchor(anchorTag?: string) {
    let anchorHostEl = this.anchorHost?.()?.nativeElement;
    if (!anchorHostEl) return;

    if (!anchorTag) {
      anchorHostEl.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }

    let anchorEl = [...(this.anchorElements?.() ?? [])].find(
      (anchorEl) => anchorEl.nativeElement.getAttribute('uitag') === anchorTag,
    )?.nativeElement;
    if (anchorEl) {
      anchorHostEl.scrollTo({
        top: anchorEl.offsetTop - anchorHostEl.offsetTop,
        behavior: 'smooth',
      });
    }
  }

  //#endregion
}
