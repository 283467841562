import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  inject,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import { UiControlDirective } from '../../../directives/ui/ui-control.directive';
import { LAYOUT_SERVICE_TOKEN } from '@aksia/infrastructure';

@Component({
  selector: 'page-nav',
  imports: [CommonModule, RouterModule],
  templateUrl: './page-nav.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageNavigationComponent extends UiControlDirective {
  //#region Injections

  protected readonly layout = inject(LAYOUT_SERVICE_TOKEN);

  //#endregion

  //#region Host Bindings

  @HostBinding('attr.uipagenav') uipagenav = '';

  //#endregion
}
