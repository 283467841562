import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'page-loading',
    imports: [CommonModule],
    templateUrl: './loading.page.html',
    styleUrls: ['./loading.page.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingPage implements OnInit {
  @Input() fullPage: boolean = false;
  @Input() loadingMessage: string = '';
  @Input() size: string = 'sm';

  width: string = '16px';
  height: string = '16px';

  ngOnInit() {
    switch (this.size) {
      case 'sm':
        this.width = '16px';
        this.height = '16px';
        break;
      case 'md':
        this.width = '32px';
        this.height = '32px';
        break;
      case 'lg':
        this.width = '64px';
        this.height = '64px';
        break;
      default:
        this.width = '32px';
        this.height = '32px';
        break;
    }
  }
}
