export const STORAGE_KEYS = {
  AUTHENTICATION: {
    USER: 'authentication/user',
    USER_PREFERENCES: 'authentication/userPreferences',
  },
  TAXONOMY: {
    TAXONOMY_MAIN: 'taxonomy/sector/all',
    TAXONOMY_CROSS_STRATEGY_TAGS: 'taxonomy/crossstrategytag/all',
    TAXONOMY_ASSET_CLASS_SUBSTRATEGY: 'taxonomy/assetclasssubstrategy/all',
    UNIVERSAL_TAGS: 'taxonomy/universalTags',
    ASSET_CLASS_TAGS: 'taxonomy/assetClassTags',
  },
  LAYOUT: {
    DEFAULT_VIEW: 'layout/defaultView',
    VIEW: 'layout/views',
    SECTIONS: 'layout/sections',
  },
} as const;

export type CacheItem = {
  cacheInfo: string | null;
  data: unknown;
};
