import { UTILS } from '@aksia/infrastructure';

export enum AccountingMethodEnum {
  USGAAP = 1,
  IFRS,
  UKGAAP,
  LuxembourgGAAP,
  IrishGAAP,
  FrenchGAAP,
  JapaneseGAAP,
  IndianGAAP,
  Other,
}

export namespace AccountingMethodEnum {
  const replace = [{ key: /([a-z])([A-Z])/g, value: '$1 $2' }];
  export const toOptions = () =>
    UTILS.UI.enumToOptions(AccountingMethodEnum, replace);
}

export enum AcquisitionTypeEnum {
  ThirdPartyAcquisition = 1,
  Recapitalization,
}
export namespace AcquisitionTypeEnum {
  const replace = [{ key: /([a-z])([A-Z])/g, value: '$1 $2' }];
  export const toOptions = () =>
    UTILS.UI.enumToOptions(AcquisitionTypeEnum, replace);
}

export enum AksiaCoverageGPEnum {
  Diligence_on_this_fund = 1,
  Full_due_diligence_on_prior_fund_in_series,
  Fund_is_monitored_and_No_diligence,
  Full_due_diligence_on_fund_in_different_series,
  Met_with_manager_more_than_once__including_investment_team_onsite___,
  Other,
}
export namespace AksiaCoverageGPEnum {
  const replace = [
    { key: /_and_/g, value: ' & ' },
    { key: /___/g, value: ')' },
    { key: /__/g, value: ' (' },
    { key: /_/g, value: ' ' },
  ];
  export const toOptions = () =>
    UTILS.UI.enumToOptions(AksiaCoverageGPEnum, replace);
}

export enum AksiaStatusEnum {
  __0_Radar = 1,
  __1_PreliminaryReview,
  __2_FurtherReview,
  __3_DueDiligence,
  __4_Approved,
  __X4_NotApproved,
  __X_Halted,
}
export namespace AksiaStatusEnum {
  const replace = [
    { key: /__/g, value: '' },
    { key: /_/g, value: ' - ' },
    { key: /([a-z])([A-Z])/g, value: '$1 $2' },
  ];
  export const toOptions = () =>
    UTILS.UI.enumToOptions(AksiaStatusEnum, replace);
}

export enum CoInvFeeStructureEnum {
  No_Fee__No_Carry = 1,
  Fee_and__or_Carry,
}
export namespace CoInvFeeStructureEnum {
  const replace = [
    { key: /__/g, value: '/' },
    { key: /_/g, value: ' ' },
  ];
  export const toOptions = () =>
    UTILS.UI.enumToOptions(CoInvFeeStructureEnum, replace);
}

export enum InstrumentEnum {
  CommonEquity = 1,
  PreferredEquity,
  Warrant,
  SeniorSecured,
  Secondlien,
  Unitranche,
  Mezzanine,
  InvestmentGradeCorporateBonds,
  HighYieldCorporateBonds,
  TradeClaims,
  GovernmentBonds,
  ConvertibleBonds,
  InflationLinked,
  Mortgage_backed_securities,
  CLOs,
  CDOs,
  Options,
  Future__Forward_Contracts,
  ContractForwardDerivatives,
  Swaps,
  CDS,
  FX,
  OtherDerivatives,
}
export namespace InstrumentEnum {
  const replace = [
    { key: /__/g, value: '/' },
    { key: /_/g, value: ' ' },
    { key: /([a-z])([A-Z])/g, value: '$1 $2' },
  ];
  export const toOptions = () =>
    UTILS.UI.enumToOptions(InstrumentEnum, replace);
}

export enum PropertyTypeEnum {
  Office = 1,
  Multifamily,
  Office_and_Multifamily,
  Retail,
  Industrial,
  Hotels,
  Diversified,
  Life_Sciences__Lab,
  Other,
}
export namespace PropertyTypeEnum {
  const replace = [
    { key: /_and_/g, value: ' & ' },
    { key: /_/g, value: ' ' },
  ];
  export const toOptions = () =>
    UTILS.UI.enumToOptions(PropertyTypeEnum, replace);
}

export enum SingleAssetPortfolioEnum {
  SingleAsset = 1,
  Portfolio,
}
export namespace SingleAssetPortfolioEnum {
  const replace = [{ key: /([a-z])([A-Z])/g, value: '$1 $2' }];
  export const toOptions = () =>
    UTILS.UI.enumToOptions(SingleAssetPortfolioEnum, replace);
}

export enum FixedFloatingDebtEnum {
  Fixed = 1,
  Floating,
  Mixed,
}

export namespace FixedFloatingDebtEnum {
  const replace = [{ key: /([a-z])([A-Z])/g, value: '$1 $2' }];
  export const toOptions = () =>
    UTILS.UI.enumToOptions(FixedFloatingDebtEnum, replace);
}

export enum DeptSourcingEnum {
  CMBS = 1,
  Agency,
  DebtFund,
  Bank,
  LifeInsuranceCompany,
  Other,
}

export namespace DeptSourcingEnum {
  const replace = [{ key: /([a-z])([A-Z])/g, value: '$1 $2' }];
  export const toOptions = () =>
    UTILS.UI.enumToOptions(DeptSourcingEnum, replace);
}
