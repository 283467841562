import { UTILS } from '@aksia/infrastructure';

export enum TagCategoryEnum {
  AssetClass = 1,
  Sector,
  Strategy,
  SubStrategy,
  MAXType,
  MAXUniverse,
  CrossStrategyTags,
  MarketCapHF,
  PortfolioUtility,
  PrimaryRiskDriver,
  SecondaryRiskDriver,
  StressEquityBeta,
  HFPortfolioFocus,
  Origination,
  Seniority,
  IncomeSource,
  UnderlyingAssetClass,
  MarketCapPC,
  BorrowerStatus,
  Leverage,
  PCPortfolioFocus,
  MarketCapPE,
  DealStrategy,
  Ownership,
  PEPortfolioFocus,
  InfraRiskProfile,
  RAPortfolioFocus,
  PrimaryPropertyType,
  ManagerProfile,
  USRegionalFocus,
  InvestmentStrategyDetails,
  REPortfolioFocus,
  Geography,
  RegionFocus,
  CountryFocus,
  Industry,
  IndustrySector,
  IndustryGroup,
  ESG,
  WomanMinoriyDisabledOwnership,
  ESGRegulated,
  BuyoutFundStyle = 49,
  CoinvestmentActivity = 50,
  PriorityLevel = 51,
  GPLedTransactionTypes = 52,
  SecondaryInvestmentStage = 53,
  VentureCapitalAndGrowthEquityIndustryExposure = 54,
  GrowthEquityTargetedRevenueGrowth = 55,
  GrowthEquityTargetedRevenue = 56,
  GrowthEquityTargetedProfitability = 57,
  GPStakesTargetedAssetsUnderManagement = 58,
  NorthAmericaExposure = 59,
  EuropeExposure = 60,
  AsiaAndPacificExposure = 61,
  SouthOrLatinAmericaExposure = 62,
  AfricaExposure = 63,
  BuyoutExposureAerospaceAndDefense = 64,
  BuyoutExposureBusinessServices = 65,
  BuyoutExposureConsumer = 66,
  BuyoutExposureCommunicationsAndMedia = 67,
  BuyoutExposureEducation = 68,
  BuyoutExposureEnergy = 69,
  BuyoutExposureFinancials = 70,
  BuyoutExposureGovernmentServices = 71,
  BuyoutExposureHealthCare = 72,
  BuyoutExposureIndustrials = 73,
  BuyoutExposureMaterials = 74,
  BuyoutExposureSports = 75,
  BuyoutExposureTechnologyHardware = 76,
  BuyoutExposureTechnologySoftware = 77,
  BuyoutExposureTransportationAndLogistics = 78,
  BuyoutExposureRealEstate = 79,
  BuyoutExposureUtilities = 80,
  GPLedTransactionType = 81,
  LPLedTransactionType = 82,
  StrategicPrimariesTransactionType = 83,
  SecondaryTransactionType0To100Mn = 84,
  SecondaryTransactionType100To250Mn = 85,
  SecondaryTransactionType250To500Mn = 86,
  SecondaryTransactionType500To1000Mn = 87,
  SecondaryTransactionType1000PlusMn = 88,
  AssetClassSubstrategy = 42,
  HFSubstrategy = 43,
  PCSubstrategy = 44,
  PESubstrategy = 45,
  RASubstrategy = 46,
  RESubstrategy = 47,
  LongOnlySubstrategy = 48,
  InternalPriorityLevel = 89,
  DiversityCertifications = 90,
}

export namespace TagCategoryEnum {
  export const toOptions = () => UTILS.UI.enumToOptions(TagCategoryEnum, []);
}
