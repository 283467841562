import { inject, Injectable, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { ASSETS_TOKEN } from '@aksia/infrastructure';

@Injectable({
  providedIn: 'root',
})
export class AssetService {
  //#region Injections

  protected readonly http = inject(HttpClient);
  protected readonly assetToken = inject(ASSETS_TOKEN);

  //#endregion

  //#region Properties

  collection = signal<Map<string, unknown> | undefined>(undefined);

  //#endregion

  //#region Methods

  constructor() {
    this.initLoad();
  }

  initLoad() {
    if (this.assetToken?.size > 0) {
      let assetPathsHttpGet = Array.from(this.assetToken!.values()).map(
        (asset) => this.http.get(asset.assetPath),
      );

      forkJoin(assetPathsHttpGet).subscribe((data) => {
        let index = 0;
        let collection = new Map<string, unknown>();
        this.assetToken!.forEach((asset, key) => {
          collection.set(key, data[index]);
          index++;
        });
        this.collection.set(collection);
      });
    }
  }

  //#endregion
}
