<!-- Tools -->
<!-- <div uitoolStrip>
  <ng-template #uiToolsCodeRef></ng-template>
  <ng-container #uiToolsDefaultRef>
    <icon
      uidefaulttool
      [iconSize]="'normal'"
      [iconDefinition]="ICONS.sliders"
    ></icon>
    @if (settings().label) {
      <span uidefaulttool uilabeltool class="align-center">{{
        settings().label
      }}</span>
    }
    <div uitools>
      <ng-content #uiToolsMarkupRef select="[uitool]"></ng-content>
      <check
        [initialValue]="settings().numberFormat"
        [settings]="
          settings().numberFormat
            ? { label: '# t/b/m', isToggle: true }
            : { label: '#,###.##', isToggle: true }
        "
        (click)="toggleFormat()"
      ></check>
    </div>
  </ng-container>
</div> -->
@if (permDir.uipermission() !== PERMISSIONS.NONE) {
  <!-- Input -->
  <table uispreadsheet #uiSpreadSheet>
    <thead>
      <tr>
        <th uispreadsheetheader (click)="clickHeader($event, -1, -1)"></th>
        <th
          uispreadsheetheader
          class="align-center justify-center"
          [class.marked]="shouldBeMarked(iCol, -1)"
          *ngFor="
            let col of settings().columnTitles;
            first as firstCol;
            index as iCol
          "
          tabindex="0"
          (click)="clickHeader($event, iCol, -1)"
          (keydown.control.c)="copy($event)"
        >
          {{ col }}
        </th>
        @if (dataSummary()) {
          <th uispreadsheetsummary>{{ settings().summaryTitle }}</th>
        }
      </tr>
    </thead>
    <tbody #uiValueRef>
      @for (
        row of settings().rowTitles;
        track iRow;
        let iRow = $index;
        let firstRow = $first;
        let lastRow = $last
      ) {
        <tr>
          <td
            uispreadsheetheader
            class="align-center justify-center"
            [class.marked]="shouldBeMarked(-1, iRow)"
            (click)="clickHeader($event, -1, iRow)"
          >
            {{ row }}
          </td>

          @for (
            col of settings().columnTitles;
            track iCol;
            let iCol = $index;
            let firstCol = $first;
            let lastRow = $last
          ) {
            @let cell = (cells()! | getByRowCol: iCol : iRow)!;
            @if (cell) {
              <td
                uispreadsheetcell
                uivalidation
                #uiSpreadSheetCell
                [attr.uiTag]="cell.tag"
                [attr.uiState]="cell.state?.()"
                [attr.UiAnnotation]="cell.annotations"
                tabIndex="1"
                [attr.data-index]="indexFromColumnRow(iCol, iRow)"
                [attr.draggable]="true"
                [class.disabled]="cell.disabled"
                [class.selected]="cell.selected"
                [class.editing]="cell.editing"
                [class.marked]="cell.marked"
                [class.deleted]="
                  cell.dataItem?.[settings().deletedField!] && !cell.editing
                "
                [class]="
                  cell.classes() +
                  ' ' +
                  (cell.dataItem?.[settings().cellCssField!] ?? '')
                "
                [attr.title]="cell.annotations"
                [validation.id]="
                  dataValidationGroupId()
                    ? {
                        groupId: dataValidationGroupId(),
                        id: cell.dataItem?.[settings().idField!],
                      }
                    : cell.dataItem?.[settings().idField!]
                "
                [validation.tag]="cell.tag"
                [validators]="dataValidators()?.(cell.dataItem)!"
                (keydown)="keydown($event, cell)"
                (click)="click($event, cell)"
                (dblclick)="editCell(cell)"
                (dragstart)="startDrag($event, cell)"
                (dragenter)="enterDrag(cell)"
                (dragend)="endDrag()"
                (paste)="paste($event)"
              >
                @if (
                  cell.editing && permDir.uipermission() !== PERMISSIONS.VIEW
                ) {
                  <input
                    uiinput
                    uispreadsheetcellinput
                    #uiSpreadSheetCellinput
                    type="text"
                    [(value)]="cell.dataItem[settings().valueField!]"
                    (keydown)="keydown($event, cell); $event.stopPropagation()"
                    (click)="$event.stopPropagation()"
                    (focusout)="updateValue(uiSpreadSheetCellinput.value, cell)"
                  />
                }

                {{ !cell.editing ? cell.formattedValue() : null }}
              </td>
            }
          }

          @if (dataSummary()) {
            @let summaryCell = summaryCells()![iRow];
            <td
              uispreadsheetsummary
              [class]="summaryCell?.dataItem?.[settings().cellCssField!] ?? ''"
            >
              {{ summaryCell?.formattedValue() }}
            </td>
          }
        </tr>
      }
    </tbody>
  </table>
}
