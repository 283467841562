import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  inject,
  input,
  Input,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import {
  IPageLayoutSettings,
  PAGE_LAYOUT,
  PageLayoutSettings,
  PageLayoutType,
  PropExtend,
  UTILS,
} from '@aksia/infrastructure';
import { UiControlDirective } from '../../../directives/ui/ui-control.directive';

@Component({
    selector: 'page-layout',
    imports: [CommonModule, RouterModule],
    templateUrl: './page-layout.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageLayoutComponent extends UiControlDirective {
  //#region Injections

  private host = inject(ElementRef);

  //#endregion

  //#region Inputs

  override settings = input<PageLayoutSettings, PageLayoutSettings>(
    new PageLayoutSettings(),
    {
      transform: (settings: IPageLayoutSettings) => this.initSettings(settings),
    },
  );

  //#endregion

  //#region Host Bindings

  @HostBinding('attr.uipagelayout') uipagelayout = '';

  @HostBinding('class')
  @Input()
  layoutType: PageLayoutType = PAGE_LAYOUT.Blank;

  @HostBinding('style.--center-area-height')
  @Input()
  centerheight?: string = 'auto';

  @HostBinding('style.--center-area-width')
  @Input()
  centerwidth?: string = 'auto';

  @HostBinding('style.--top-area-height')
  @Input()
  topheight?: string = 'auto';

  @HostBinding('style.--top-area-width')
  @Input()
  topwidth?: string = 'auto';

  @HostBinding('style.--bottom-area-height')
  @Input()
  bottomheight?: string = 'auto';

  @HostBinding('style.--bottom-area-width')
  @Input()
  bottomwidth?: string = 'auto';

  @HostBinding('style.--left-area-height')
  @Input()
  leftheight?: string = 'auto';

  @HostBinding('style.--left-area-width')
  @Input()
  leftwidth?: string = 'auto';

  @HostBinding('style.--right-area-height')
  @Input()
  rightheight?: string = 'auto';

  @HostBinding('style.--right-area-width')
  @Input()
  rightwidth?: string = 'auto';

  @Input()
  freeze: Array<'top' | 'bottom' | 'left' | 'right'> = [];

  //#endregion

  protected readonly layouts: PropExtend<PageLayoutType> = PAGE_LAYOUT;
  protected expandedAreas: Array<'top' | 'bottom' | 'left' | 'right'> = [];
  protected cssVariableResize!: string;
  protected propertyResize!: string;

  //#region Functions

  protected override initSettings(
    settings: IPageLayoutSettings,
  ): PageLayoutSettings {
    let pageLayoutSettings = UTILS.OBJECT.assign(
      new PageLayoutSettings(),
      settings,
    ) as PageLayoutSettings;

    this.topheight = this.resolveLength(pageLayoutSettings.topArea?.height);
    this.topwidth = this.resolveLength(pageLayoutSettings.topArea?.width);
    this.bottomheight = this.resolveLength(
      pageLayoutSettings.bottomArea?.height,
    );
    this.bottomwidth = this.resolveLength(pageLayoutSettings.bottomArea?.width);
    this.leftheight = this.resolveLength(pageLayoutSettings.leftArea?.height);
    this.leftwidth = this.resolveLength(pageLayoutSettings.leftArea?.width);
    this.rightheight = this.resolveLength(pageLayoutSettings.rightArea?.height);
    this.rightwidth = this.resolveLength(pageLayoutSettings.rightArea?.width);
    this.centerheight = this.resolveLength(
      pageLayoutSettings.centerArea?.height,
    );

    return pageLayoutSettings;
  }

  private resolveLength(length?: number | string) {
    return !length
      ? 'auto'
      : typeof length === 'string'
        ? length?.toString()
        : `${length}px`;
  }

  protected startDrag(e: DragEvent, cssVariable: string) {
    this.cssVariableResize = cssVariable;
    this.propertyResize = cssVariable.replace(/-/g, '');
    /* const proxyEl = (e.target as HTMLElement)?.cloneNode(true) as HTMLElement;
      e.dataTransfer!.setDragImage(proxyEl, 0, 0); */
  }

  protected drag(e: DragEvent) {
    /* this.zone.runOutsideAngular(() => {
      (this as any)[this.propertyResize] = this.calculatePosition(e);
    }); */
    e.preventDefault();
  }

  protected endDrag(e: DragEvent) {
    (this as any)[this.propertyResize] = this.calculatePosition(e);
    e.preventDefault();
  }

  protected toggle(area: 'top' | 'bottom' | 'left' | 'right') {
    if (this.expandedAreas.includes(area)) {
      this.expandedAreas = this.expandedAreas.filter((a) => a !== area);
      this[`${area}height`] = this.resolveLength(
        this.settings()[`${area}Area`]?.height,
      );
      this[`${area}width`] = this.resolveLength(
        this.settings()[`${area}Area`]?.width,
      );
    } else {
      this.expandedAreas.push(area);
      this[`${area}height`] = 'fit-content(100%)';
      this[`${area}width`] = 'fit-content(100%)';
    }
  }

  private calculatePosition(e: DragEvent) {
    const position: PropExtend<{}> = {
      topheight: `${e.clientY}px`,
      bottomheight: `${document.body.clientHeight - e.clientY}px`,
      leftwidth: `${e.clientX}px`,
      rightwidth: `${this.host.nativeElement.clientWidth - e.clientX}px`,
    };
    return position[this.propertyResize];
  }

  //#endregion
}
