import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  inject,
} from '@angular/core';
import { ChipComponent } from '../../../controls/presentation/chip/chip.component';
import {
  AUTHENTICATION_SERVICE_TOKEN,
  PropExtend,
} from '@aksia/infrastructure';
import { DropdownComponent } from '../../../controls/input/dropdown/dropdown.component';
import { NavigationService, SearchService } from '@aksia/services';
import { EntityTypeEnum } from '@aksia/enums';
import { IconComponent } from '../../../controls/presentation/icon/icon.component';
import { UiControlDirective } from '../../../directives/ui/ui-control.directive';
import { ButtonComponent } from '../../input/button/button.component';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'page-toolbar',
  imports: [
    CommonModule,
    IconComponent,
    ChipComponent,
    DropdownComponent,
    ButtonComponent,
    ModalComponent,
  ],
  templateUrl: './page-toolbar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageToolbarComponent extends UiControlDirective {
  //#region Injections

  protected readonly search = inject(SearchService);
  protected readonly nav = inject(NavigationService);
  protected readonly auth = inject(AUTHENTICATION_SERVICE_TOKEN);
  protected readonly window = window;

  //#endregion

  //#region Host Bindings

  @HostBinding('attr.uipagetoolbar') uipagetoolbar = '';

  //#endregion

  protected readonly entityTypes = EntityTypeEnum;

  visitEntity(option?: PropExtend<unknown>) {
    const entityUrl =
      option.entityTypeId === this.entityTypes.ManagementCompany
        ? 'managementcompany'
        : 'investmentvehicle';
    this.nav.goToPage(`${entityUrl}/${option.entityId}`);
  }
}
