export enum AuthRequestEnum {
  AUTH = 'authenticate',
  AUTH_2FA = 'authorizelocation',
  REQUEST_2FA = 'requestNewLocationCode',
  SIGN_OUT = 'signout',
}

export enum AuthenticationStepEnum {
  NotAuthenticated = 'Not Authenticated',
  VerifyingCredentials = 'Verifying Credentials',
  Requested2FA = 'Two Factor Code is Required',
  Verifying2FA = 'Verifying Two Factor Code',
  IsAuthenticated = 'Authenticated',
  RejectedAuthentication = 'Authentication is rejected',
}

export enum AuthenticationResponseCode {
  UsernameInvalid,
  UserNotFound,
  PasswordIncorrect,
  NeedVerificationCode,
  VerificationCodeExpired,
  VerificationCodeIncorrect,
  VerificationCodeMaxAttempts,
  SessionExpired,
  LocationForbidden,
  Success,
  NeedVerificationCodeAddMobile,
}
