import { Type } from '@angular/core';

//#region Common

export const UI_POPUP_HIDE_STRATEGY = {
  AlwaysShow: 'always_show',
  ClickAnywhere: 'click_anywhere',
  ClickOutside: 'click_outside',
  ClickTrigger: 'click_trigger',
  ClickOutsideOrOption: 'click_outside_or_option',
  ClickOutsideOrTrigger: 'click_outside_or_trigger',
} as const;
export type UiPopupHideStrategyType =
  (typeof UI_POPUP_HIDE_STRATEGY)[keyof typeof UI_POPUP_HIDE_STRATEGY];

//#endregion

//#region Input

export const BUTTON_ICON_POSITION = {
  Left: 'left',
  Right: 'right',
} as const;
export type ButtonIconPositionType =
  (typeof BUTTON_ICON_POSITION)[keyof typeof BUTTON_ICON_POSITION];

export const ICON_SIZE = {
  Smaller: 'smaller',
  Small: 'small',
  Normal: 'normal',
  Large: 'large',
  XLarge: 'x-large',
  XXLarge: '2x-large',
  XXXLarge: '3x-large',
} as const;
export type IconSizeType = (typeof ICON_SIZE)[keyof typeof ICON_SIZE];

export const TEXT_INPUT_TYPE = {
  Text: 'text',
  Password: 'password',
} as const;
export type TextInputType =
  (typeof TEXT_INPUT_TYPE)[keyof typeof TEXT_INPUT_TYPE];

export const DATE_SELECTION = {
  Day: 'Day',
  Month: 'Month',
  Year: 'Year',
} as const;
export type DateSelectionType =
  (typeof DATE_SELECTION)[keyof typeof DATE_SELECTION];

export const DROPDOWN_SELECTION_MODE = {
  Single: 'single',
  Multi: 'multi',
  Menu: 'menu',
} as const;
export type DropdownSelectionModeType =
  (typeof DROPDOWN_SELECTION_MODE)[keyof typeof DROPDOWN_SELECTION_MODE];

export const DROPDOWN_SELECTED_OPTION_STRATEGY = {
  None: 'none',
  Marked: 'marked',
  Checked: 'checked',
  Removed: 'removed',
} as const;
export type DropdownSelectedOptionStrategyType =
  (typeof DROPDOWN_SELECTED_OPTION_STRATEGY)[keyof typeof DROPDOWN_SELECTED_OPTION_STRATEGY];

export const FILE_UPLOAD_UI_TYPE = {
  Field: 'field',
  Button: 'button',
} as const;
export type FileUploadUiType =
  (typeof FILE_UPLOAD_UI_TYPE)[keyof typeof FILE_UPLOAD_UI_TYPE];

export const SPREADSHEET_ALLOWED_VALUES = {
  All: 'all',
  Alpha: 'alpha',
  Numeric: 'numeric',
} as const;
export type SpreadsheetAllowedValuesType =
  (typeof SPREADSHEET_ALLOWED_VALUES)[keyof typeof SPREADSHEET_ALLOWED_VALUES];

//#endregion

//#region Layout

export const PAGE_LAYOUT = {
  Blank: 'blank',
  Center: 'center',
  Top: 'top',
  Bottom: 'bottom',
  Left: 'left',
  Right: 'right',
  TopBottom: 'top-bottom',
  LeftRight: 'left-right',
  TopLeft: 'top-left',
  TopRight: 'top-right',
  TopLeftRight: 'top-left-right',
  BottomLeft: 'bottom-left',
  BottomRight: 'bottom-right',
  BottomLeftRight: 'bottom-left-right',
  All: 'all',
} as const;
export type PageLayoutType = (typeof PAGE_LAYOUT)[keyof typeof PAGE_LAYOUT];

//#endregion

//#region Other

export type InjectInput = {
  name: string;
  type?: any;
  value?: any;
};

export type InjectItem = {
  component: Type<any>;
  inputs?: Array<InjectInput>;
  outputs?: Array<string>;
  functions?: Array<string>;
  attributes?: Array<string>;
};

export type Location = {
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
  latitude?: number;
  longitude?: number;
};

export type Cell = {
  row?: number;
  col?: number;
};

export type CellRange = {
  startRow?: number;
  endRow?: number;
  startCol?: number;
  endCol?: number;
};

//#endregion
