import { ILoadingService } from '@aksia/infrastructure';
import {
  computed,
  Injectable,
  Signal,
  signal,
  WritableSignal,
} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoadingService implements ILoadingService {
  //#region Injections

  //#endregion

  //#region Properties

  //Loader

  loadRequests: WritableSignal<Map<string, boolean>> = signal(new Map());
  isLoading: WritableSignal<boolean> = signal(false);

  loadingPercentage: Signal<string | undefined> = computed(() => {
    let hasRespondedCount = [...this.loadRequests()?.values()]?.filter(
      (responded: boolean) => responded,
    ).length;
    let requestCount = [...this.loadRequests()?.values()]?.length;
    return `${((hasRespondedCount / requestCount) * 100).toFixed(0)} %`;
  });

  //#region Functions

  constructor() {
    this.initLoad();
  }

  initLoad() {}

  //#region Loader

  public addLoadRequest(key: string) {
    let loadRequests = this.loadRequests();
    loadRequests.set(key, false);
    this.loadRequests.set(new Map([...loadRequests]));
    this.isLoading.set(true);

    console.log(
      `%c Loading ${key}...`,
      'background: #222; color: rgba(251, 191, 36, 1);',
    );
  }

  public setLoadResponse(key: string) {
    let loadRequests = this.loadRequests();
    loadRequests.set(key, true);
    this.loadRequests.set(new Map([...loadRequests]));

    if ([...loadRequests.values()].every((responded) => responded)) {
      this.isLoading.set(false);
    }

    console.log(
      `%c ${key} successfully loaded`,
      'background: #222; color: #bada55;',
    );
  }

  public removeLoadRequest(key: string) {
    let loadRequests = this.loadRequests();
    loadRequests.delete(key);
  }

  public resetLoadRequests() {
    this.loadRequests.set(new Map());
  }

  //#endregion
}
