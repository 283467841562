import {
  AksiaValidatorFn,
  AksiaValidators,
  IValidationRegisterRequest,
  IValidationState,
  IValidationValidateRequest,
} from '@aksia/infrastructure';
import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ValidationService {
  validationEntities = signal<Array<IValidationState>>([]);

  validate(
    request: IValidationValidateRequest | Array<IValidationValidateRequest>,
  ) {
    /* let uidsArray = Array.isArray(uids) ? uids : [uids];
    let tagsArray = Array.isArray(tags) ? tags : [tags];

    console.warn('Validating: ', uidsArray.join(', '), tagsArray.join(', '));

    let validations = this.validationEntities()?.filter(
      (validation) =>
        (uidsArray.includes(validation.uid) || uidsArray.length === 0) &&
        (tagsArray.includes(validation.tag()) || tagsArray.length === 0),
    );

    validations?.forEach((validation) => {
      validation.errors.set([]);
      validation.warnings.set([]);
      let validators =
        validation.validators instanceof Function
          ? validation.validators()
          : validation.validators;
      validators?.forEach((validator: AksiaValidatorFn) => {
        let { errors, warnings } = validator(validation.value?.());
        validation.errors.set([...validation.errors(), ...errors]);
        validation.warnings.set([...validation.warnings(), ...warnings]);
      });
    }); */
  }

  registerValidation(validation: IValidationRegisterRequest) {
    /* let registeredValidation = this.validationEntities()?.find(
      (v) => v.uid === validation.uid && v.tag() === validation.tag(),
    );
    if (!registeredValidation) {
      const errors = signal(
        AksiaValidators.validateForErrors(
          validation.value?.(),
          validation.validators,
        ),
      );
      const warnings = signal(
        AksiaValidators.validateForWarnings(
          validation.value?.(),
          validation.validators,
        ),
      );
      registeredValidation = {
        ...validation,
        errors,
        warnings,
      };
    } else {
      this.validate(validation.uid, validation.tag());
    }
    return registeredValidation; */
  }

  removeValidation(
    uids: number | Array<number>,
    tags?: string | Array<string>,
  ) {
    /* let removeCollection = this.validationEntities()?.filter(
      (validation) =>
        (Array.isArray(uids)
          ? uids?.includes(validation.uid)
          : uids === validation.uid) &&
        (Array.isArray(tags)
          ? tags?.includes(validation.tag()) || tags?.length === 0
          : tags === validation.tag() || !tags),
    );

    removeCollection.forEach((validation) => {
      this.validationEntities().splice(
        this.validationEntities().indexOf(validation),
        1,
      );
    }); */
  }

  debugValidations() {
    /* console.table(
      //JSON.stringify(
      this.validationEntities().map((v) => ({
        uid: v.uid,
        tag: v.tag(),
        errors: v.errors?.()?.join(', '),
        warnings: v.warnings?.()?.join(', '),
        value: v.value?.(),
      })),
      //),
    ); */
  }
}
