<div
  [class]="headerCssClass()"
  [attr.uisectiongroupheader]="
    settings().isMinimized ? 'collapsed' : 'expanded'
  "
  [style.display]="settings().hideLabel || !settings().label ? 'none' : 'flex'"
  (click)="toggle()"
>
  <icon
    uisectiongrouptool
    [iconSize]="'smaller'"
    [iconDefinition]="
      settings().isMinimized ? ICONS.chevronUp : ICONS.chevronUp
    "
  ></icon>
  <span uisectiongrouptitle [UiCopy]="settings().label!">{{
    settings().label
  }}</span>
</div>
@if (!settings().isMinimized) {
  <div uisectiongroupcontent [class]="contentCssClass()">
    <ng-content></ng-content>
    <ng-template #hostRefs></ng-template>
  </div>
}
